// prettier-ignore
export const TRANSLATION_KEY_INDEX = [
  {
    "fileName": "src/new-url-popup.tsx",
    "ns": "newUrlPopup",
    "keys": [
      "Website address has been changed",
      "You are using an old address please use the NEW address"
    ]
  },
  {
    "fileName": "src/utils/domain-status.tsx",
    "ns": "domain-status",
    "keys": [
      "Online",
      "Offline",
      "Failure",
      "Not in operation",
      "Unbekannt",
      "Verfügbar",
      "Laden",
      "Besetzt",
      "Fehlerhaft",
      "Nicht verfügbar",
      "Unbekannt"
    ]
  },
  {
    "fileName": "src/pages/login-page.tsx",
    "ns": "login-page",
    "keys": [
      "Sie besitzen keinen Zugriff auf diese Anwendung",
      "Abmelden",
      "Es ist leider ein Fehler aufgetreten",
      "Fehlercode"
    ]
  },
  {
    "fileName": "src/pages/system/affiliates/affiliate-list-page/affiliate-list-page.tsx",
    "ns": "affiliate-list-page",
    "keys": [
      "Affiliates",
      "Affiliates",
      "Administrators",
      "Affiliates suchen",
      "Affiliate erstellen",
      "Name",
      "Löschen",
      "Editieren",
      "Affiliate Löschen",
      "Löschen",
      "Sind Sie sicher, dass Sie Affiliates {{title}} löschen möchten?",
      "Bei der Löschung des Affiliate werden alle Daten des Affiliate gelöscht."
    ]
  },
  {
    "fileName": "src/pages/system/affiliates/affiliate-create-popup/affiliate-create-popup.tsx",
    "ns": "client-create-popup",
    "keys": [
      "Name",
      "Name hinzufügen",
      "Dieses Feld darf nicht leer sein.",
      "Dieses Feld darf nicht leer sein.",
      "Name darf nicht länger als 100 Zeichen sein.",
      "Name ist bereits vergeben.",
      "Code",
      "Der Code wird als eindeutige Kennung im System verwendet.",
      "Code",
      "Dieses Feld darf nicht leer sein.",
      "Code darf nicht länger als 6 Zeichen sein.",
      "Code ist bereits vergeben.",
      "Code ist bereits vergeben.",
      "Organisation Group",
      "Organisation Group",
      "Dieses Feld darf nicht leer sein.",
      "Organisation Group ist bereits vergeben.",
      "Organisation Group ist nicht in EVP vorhanden.",
      "English",
      "German",
      "French",
      "Language",
      "Affiliate Editieren",
      "Affiliate Erstellen",
      "Speichern",
      "Erstellen",
      "Affiliate Berechtigung",
      "Wählen Sie die Berechtigungen aus, das diese Affiliate haben soll.",
      "Um ein Affiliate zu erstellen, muss sie mindestens eine Berechtigung haben.",
      "Diese Berechtigung wird allen Rollen entzogen, die diese Berechtigung besitzen."
    ]
  },
  {
    "fileName": "src/pages/system/admin/admin-list-page/admin-list-page.tsx",
    "ns": "system-admin-list-page",
    "keys": [
      "Administration",
      "Affiliates",
      "Administrators",
      "Administrators suchen",
      "Administrator erstellen",
      "Name",
      "E-Mail",
      "Löschen",
      "Editieren"
    ]
  },
  {
    "fileName": "src/pages/system/admin/admin-delete-popup/admin-delete-popup.tsx",
    "ns": "admin-delete-popup",
    "keys": [
      "Administrator Löschen",
      "Löschen",
      "Möchten Sie den Administrator {{userMail}} wirklich löschen?"
    ]
  },
  {
    "fileName": "src/pages/system/admin/admin-create-popup/admin-create-popup.tsx",
    "ns": "admin-create-popup",
    "keys": [
      "Vorname",
      "Dieses Feld darf nicht leer sein.",
      "Dieses Feld darf nicht leer sein.",
      "Vorname darf nicht länger als 128 Zeichen sein.",
      "Nachname",
      "Dieses Feld darf nicht leer sein.",
      "Dieses Feld darf nicht leer sein.",
      "Nachname darf nicht länger als 128 Zeichen sein.",
      "English",
      "German",
      "French",
      "Language",
      "E-Mail",
      "Dieses Feld darf nicht leer sein.",
      "E-Mail darf nicht länger als 254 Zeichen sein.",
      "Ungültige E-Mail-Adresse.",
      "E-Mail ist bereits vergeben.",
      "Administrator Editieren",
      "Administrator Erstellen",
      "Speichern",
      "Erstellen",
      "Der Benutzer existiert bereits und erhält Administratorrechte. Um den Namen des Benutzers zu ändern, nutzen Sie die Editierfunktion.",
      "Nach dem Erstellen, erhält der Benutzer eine automatisierte E-mail zur Passwortvergabe."
    ]
  },
  {
    "fileName": "src/pages/support/create-ticket.page.tsx",
    "ns": "create-ticket-action",
    "keys": [
      "Create Service Ticket"
    ]
  },
  {
    "fileName": "src/pages/status-log/status-log-page.tsx",
    "ns": "status-log-page",
    "keys": [
      "Ladestationen",
      "Online",
      "Offline",
      "Fehlerhaft",
      "Nicht in betrieb",
      "Ladepunkte",
      "Verfügbar",
      "Laden",
      "Besetzt",
      "Fehlerhaft",
      "Nicht verfügbar",
      "Reserviert",
      "Nicht verfügbar",
      "Gesperrt",
      "Nicht verfügbar",
      "Sonstige",
      "Unbekannt",
      "Status Log",
      "Station",
      "Ladepunkt",
      "AC",
      "DC",
      "AC/DC",
      "Public Service",
      "Online",
      "Offline",
      "Defective",
      "Not in operation",
      "Available",
      "Charging",
      "Occupied",
      "Defective",
      "N/A Reserved",
      "N/A Set unavailable",
      "N/A Other",
      "Unknown",
      "EVSEID",
      "Standort",
      "Level",
      "Hersteller",
      "Modell",
      "Stromart",
      "Start",
      "Status"
    ]
  },
  {
    "fileName": "src/pages/stations/stations-list-page.tsx",
    "ns": "stations-list-page",
    "keys": [
      "Infrastructure",
      "Aktuelle Ladeleistung",
      "Ladestationen",
      "Ladepunkte",
      "Search",
      "AC",
      "DC",
      "AC/DC",
      "Public Service",
      "Online",
      "Offline",
      "Defective",
      "Not in operation"
    ]
  },
  {
    "fileName": "src/pages/stations/station-remote-actions.tsx",
    "ns": "station-remote-actions",
    "keys": [
      "Station",
      "neu starten",
      "Station",
      "neu starten",
      "Alle Ladepunkte ent-/sperren",
      "Station neu starten",
      "Ladepunkte ent-/sperren",
      "Change Availability",
      "Ein Fehler wurde von Konnektor(en) {{connectors}} zurückgegeben",
      "Station {{station}}: {{status}} ",
      "Erfolgreich",
      "Fehlgeschlagen",
      "Ein Fehler wurde von Konnektor(en) {{connectors}} zurückgegeben",
      "Station {{station}}: {{status}}",
      "Erfolgreich",
      "Fehlgeschlagen",
      "Station {{station}}: {{status}}",
      "Erfolgreich",
      "Fehlgeschlagen",
      "Station {{station}}: {{status}}",
      "Erfolgreich",
      "Fehlgeschlagen",
      "Station {{station}}: {{status}}",
      "Erfolgreich",
      "Fehlgeschlagen",
      "Station {{station}}: {{status}}",
      "Erfolgreich",
      "Fehlgeschlagen",
      "Ein Fehler wurde von Konektor(en) {{connectors}} zurückgegeben",
      "Confirm",
      "Sind Sie sicher, dass Sie den/die Ladepunkt(e) ent-/sperren wollen?",
      "entsperren",
      "sperren",
      "Aktive Ladevorgänge, werden durch diese Aktion abgebrochen.",
      "Bitte warten",
      "Close",
      "Ladepunkt(e) wurde(n) teilweise gesperrt",
      "Ladepunkt(e) wurde(n) teilweise entsperrt",
      "Close",
      "Ladepunkt(e) wurde(n) gesperrt",
      "Ladepunkt(e) wurde(n) entsperrt",
      "Close",
      "Aktion konnte nicht durchgeführt werden",
      "Station neu starten",
      "Confirm",
      "Sind Sie sicher, dass Sie die Station neu starten wollen?",
      "An dieser Station ist ein Ladevorgang aktiv, der durch den Neustart abgebrochen wird.",
      "Station neu starten",
      "Bitte warten",
      "Station neu starten",
      "Close",
      "Der Befehl wurde erfolgreich an die Station gesendet",
      "Station neu starten",
      "Close",
      "Neustart konnte nicht durchgeführt werden"
    ]
  },
  {
    "fileName": "src/pages/stations/custom-name-popup.tsx",
    "ns": "custom-name-popup",
    "keys": [
      "Type here the custom name to help identify this device",
      "Maximum length is 50",
      "chargepoint",
      "station",
      "card",
      "Edit",
      "Create",
      "custom name",
      "The custom label must be appropriate, relevant and not excessive. Labels must therefore remain objective, not be insulting or undermine a person's dignity.",
      "Save"
    ]
  },
  {
    "fileName": "src/pages/stations/chargepoints-list-page.tsx",
    "ns": "chargepoints-list-page",
    "keys": [
      "Infrastructure",
      "Aktuelle Ladeleistung",
      "Ladestationen",
      "Ladepunkte",
      "Search",
      "Steckertyp",
      "AC",
      "DC",
      "Public Service",
      "Available",
      "Charging",
      "Occupied",
      "Defective",
      "Not available",
      "Unknown"
    ]
  },
  {
    "fileName": "src/pages/stations/chargepoint-remote-actions.tsx",
    "ns": "chargepoint-remote-actions",
    "keys": [
      "Ladevorgang starten",
      "Ladevorgang stoppen",
      "Konnektor freigeben",
      "Ladepunkt reservieren",
      "Reservierung stornieren",
      "Ladepunkt ent-/sperren",
      "Ladevorgang stoppen",
      "Confirm",
      "Sind Sie sicher, dass Sie den Ladevorgang stoppen wollen?",
      "Bitte warten",
      "Close",
      "Ladevorgang wurde gestoppt",
      "Close",
      "Aktion konnte nicht durchgeführt werden",
      "Konnektor freigeben",
      "Confirm",
      "Sind Sie sicher, dass Sie den Konnektor freigeben wollen?",
      "Aktive Ladevorgänge, werden durch diese Aktion abgebrochen.",
      "Bitte warten",
      "Close",
      "Der Befehl wurde erfolgreich an die Station gesendet.",
      "Close",
      "Aktion konnte nicht durchgeführt werden",
      "Reservierung stornieren",
      "Confirm",
      "Wollen Sie die Reservierung dieses Ladepunkts stornieren?",
      "Bitte warten",
      "Close",
      "Reservierung wurde storniert",
      "Close",
      "Aktion konnte nicht durchgeführt werden",
      "Change Availability",
      "Confirm",
      "Sind Sie sicher, dass Sie den Ladepunkt ent-/sperren wollen?",
      "entsperren",
      "sperren",
      "Aktive Ladevorgänge, werden durch diese Aktion abgebrochen.",
      "Bitte warten",
      "Close",
      "Ladepunkt wurde) gesperrt",
      "Ladepunkt wurde entsperrt",
      "Close",
      "Aktion konnte nicht durchgeführt werden",
      "Ladevorgang Starten",
      "Confirm",
      "Authentifizierungsmedium suchen",
      "Ladevorgang mit diesem Authentifikationsmedium starten",
      "Bitte warten",
      "Close",
      "Ladevorgang wurde gestartet",
      "Close",
      "Aktion konnte nicht durchgeführt werden",
      "Ladepunkt reservieren",
      "Confirm",
      "Authentifizierungsmedium suchen",
      "Ladepunkt ab jetzt für den gewählten Zeitraum reservieren?",
      "Bitte warten",
      "Close",
      "Ladepunkt wurde reserviert",
      "Close",
      "Aktion konnte nicht durchgeführt werden"
    ]
  },
  {
    "fileName": "src/pages/organisation/users/users-list-page/org-users-list-page.tsx",
    "ns": "org-user-list-popup",
    "keys": [
      "Organisation Users",
      "Edit Organisation Users",
      "Switch client organisation",
      "Structure",
      "Stations",
      "Users",
      "Search",
      "Create new user",
      "Name",
      "E-Mail",
      "Roles",
      "Add role",
      "Edit user",
      "Delete user"
    ]
  },
  {
    "fileName": "src/pages/organisation/users/user-hierarchy-assignor/org-user-hierarchy-assignor.tsx",
    "ns": "org-hierarchy-assignor",
    "keys": [
      "Knoten Berechtigung",
      "Ausgewählt",
      "Vererbt",
      "Nicht auswählbar"
    ]
  },
  {
    "fileName": "src/pages/organisation/users/user-edit-role-popup/org-user-edit-role-popup.tsx",
    "ns": "org-user-edit-popup",
    "keys": [
      "Es muss mindestens ein Knoten ausgewählt werden.",
      "Ein unerwarteter Fehler ist aufgetreten.",
      "Rollenberechtigung editieren",
      "Rollenberechtigung anzeigen",
      "Speichern",
      "Schließen",
      "Rollenberechtigung löschen"
    ]
  },
  {
    "fileName": "src/pages/organisation/users/user-edit-info-popup/user-edit-info-popup.tsx",
    "ns": "org-user-edit-info-popup",
    "keys": [
      "Vorname",
      "Dieses Feld darf nicht leer sein.",
      "Dieses Feld darf nicht leer sein.",
      "Vorname darf nicht länger als 128 Zeichen sein.",
      "Nachname",
      "Dieses Feld darf nicht leer sein.",
      "Dieses Feld darf nicht leer sein.",
      "Nachname darf nicht länger als 128 Zeichen sein.",
      "E-Mail",
      "Dieses Feld darf nicht leer sein.",
      "E-Mail darf nicht länger als 254 Zeichen sein.",
      "Ungültige E-Mail-Adresse.",
      "E-Mail ist bereits der Organisation zugeordnet.",
      "English",
      "German",
      "French",
      "Language",
      "Benutzer Editieren",
      "BENUTZER ERSTELLEN",
      "Speichern",
      "Berechtigung hinzufügen",
      "Der Benutzer existiert bereits und erhält Zugriff auf diesen Kunden. Um den Namen des Benutzers zu ändern, nutzen Sie die Editierfunktion.",
      "Nach dem Erstellen, erhält der Benutzer eine automatisierte E-mail zur Passwortvergabe."
    ]
  },
  {
    "fileName": "src/pages/organisation/users/user-delete-user-popup/org-user-delete-popup.tsx",
    "ns": "org-user-delete-popup",
    "keys": [
      "Ein unerwarteter Fehler ist aufgetreten.",
      "Benutzer löschen",
      "Löschen",
      "Sind Sie sicher, dass Sie den Benutzer löschen wollen?",
      "Dieser Benutzer und alle seine Zugänge werden unwiederbringlich gelöscht."
    ]
  },
  {
    "fileName": "src/pages/organisation/users/user-delete-role-popup/org-user-delete-role-popup.tsx",
    "ns": "org-user-delete-role-popup",
    "keys": [
      "Ein unerwarteter Fehler ist aufgetreten.",
      "Rollenberechtigung löschen",
      "Löschen",
      "Sind Sie sicher, dass Sie dem Benutzer die einzige Rolle entziehen wollen?",
      "Der Benutzer wird gelöscht, wenn dieser über keine Rollen verfügt.",
      "Sind Sie sicher, dass Sie dem Benutzer diese Rolle und den entsprechenden Zugang zu den Knoten entziehen wollen?"
    ]
  },
  {
    "fileName": "src/pages/organisation/users/user-add-roles-popup/org-user-add-roles-popup.tsx",
    "ns": "org-user-add-roles-popup",
    "keys": [
      "Es muss mindestens ein Knoten ausgewählt werden.",
      "Ein unerwarteter Fehler ist aufgetreten.",
      "Rollenberechtigung hinzufügen",
      "Speichern",
      "Rollen Berechtigung",
      "Es muss mindestens ein Rolle ausgewählt werden.",
      "Alle ausgewählten Rollen werden auf die unten gewählten Knoten angewendet!"
    ]
  },
  {
    "fileName": "src/pages/organisation/structure/structure-overview-page/structure-overview-page.tsx",
    "ns": "structure-overview-page",
    "keys": [
      "Organisation",
      "Edit client organisation",
      "Switch client organisation",
      "Structure",
      "Stations",
      "Users",
      "Edit organisation chart",
      "Quit editing mode"
    ]
  },
  {
    "fileName": "src/pages/organisation/structure/structure-edit-node-popup/structure-edit-node-popup.tsx",
    "ns": "structure-edit-node-popup",
    "keys": [
      "Gebiet",
      "Standort",
      "Bereich",
      "das Gebiet",
      "den Standort",
      "den Bereich",
      "Namen eintippen",
      "Bitte geben Sie eine Bezeichnung für {{nodeTypeTitleWithArticle}} ein.",
      "Bitte geben Sie eine Bezeichnung für {{nodeTypeTitleWithArticle}} ein.",
      "Die Bereichsbezeichnung darf maximal 30 Zeichen lang sein.",
      "Diese Bezeichnung ist in diesem Knoten bereits vergeben.",
      "Entity-ID",
      "Es muss eine Entity-ID angegeben werden",
      "Es muss eine Entity-ID angegeben werden",
      "Diese Entity-ID ist bereits vergeben.",
      "{{nodeTypeTitle}} bearbeiten",
      "{{nodeTypeTitle}} erstellen",
      "Knoten löschen"
    ]
  },
  {
    "fileName": "src/pages/organisation/structure/structure-edit-hierarchy-popup/structure-edit-hierarchy-popup.tsx",
    "ns": "structure-edit-popup",
    "keys": [
      "Bereich hinzufügen",
      "Standort hinzufügen",
      "Bereich hinzufügen",
      "Gebiet hinzufügen",
      "Standort hinzufügen",
      "Bereich hinzufügen"
    ]
  },
  {
    "fileName": "src/pages/organisation/structure/structure-delete-node-popup/structure-delete-node-popup.tsx",
    "ns": "structure-delete-node-popup",
    "keys": [
      "Gebiet",
      "Standort",
      "Bereich",
      "{{nodeTypeTitle}} löschen",
      "Sind Sie sicher, dass Sie den Bereich “{{title}}” löschen wollen?",
      "Löschen",
      "{{usersCount}} Benutzer sind keinem weiteren Knoten zugeordnet.",
      "Benutzer die keinem Knoten zugeordnet sind werden unwiederbringlich gelöscht.",
      "Löschen",
      "Prozess fehlgeschlagen",
      "Prozess abgeschlossen"
    ]
  },
  {
    "fileName": "src/pages/organisation/stations/org-stations-list-page.tsx",
    "ns": "org-stations-list-page",
    "keys": [
      "Organisation Users",
      "Edit Organisation Stations",
      "Switch client organisation",
      "Structure",
      "Stations",
      "Users",
      "Search",
      "AC",
      "DC",
      "AC/DC",
      "Public Service",
      "Unselect all",
      "Stations selected",
      "Revoke Assignment",
      "Assign selected stations",
      "Ladepunkt",
      "Ladepunkte",
      "EVSE-ID",
      "Charging points",
      "Ladepunkt",
      "Ladepunkte",
      "Subsidiary",
      "Location - Area",
      "Manufacturer model",
      "Serial number",
      "Stationen zuordnen",
      "Bestätigen",
      "Zuordnung aufheben",
      "Sind Sie sicher, dass die Zuordnung von {{cpCount}} gewählten Stationen aufgehoben werden soll?",
      "Diese werden dem Knoten \"Standardbereich\" zugewiesen. Stationen, die diesem Knoten bereits zugeordnet waren, werden nicht geändert.",
      "Auswahl verwerfen",
      "Bestätigen",
      "Sind Sie sicher, dass Sie Ihre Auswahl von {{cpCount}} Stationen verwerfen wollen?"
    ]
  },
  {
    "fileName": "src/pages/organisation/shared/org-tab-panel.tsx",
    "ns": "org-tab-panel",
    "keys": [
      "Struktur",
      "Stationen",
      "Benutzer"
    ]
  },
  {
    "fileName": "src/pages/operations/operations-page.tsx",
    "ns": "operations-page",
    "keys": [
      "This field should not be empty.",
      "Operations",
      "Send OCPP message to a station",
      "Select OCPP message template",
      "Send OCPP message"
    ]
  },
  {
    "fileName": "src/pages/operations/shared/operation-tab-panel.tsx",
    "ns": "operation-tab-panel",
    "keys": [
      "Send OCPP message",
      "Move Stations to EVP",
      "Interactions logs"
    ]
  },
  {
    "fileName": "src/pages/operations/interactions-logs/interactions-logs-page.tsx",
    "ns": "ev-interactions-logs-page",
    "keys": [
      "Administration",
      "Organisation",
      "Charging Infrastructure",
      "Load Managment",
      "Charging Badges",
      "Unknown",
      "Success",
      "Failed",
      "Partial Success",
      "Search",
      "Search User Mail",
      "Pages",
      "Actions",
      "Search Client...",
      "Search affiliate",
      "Search client",
      "Created At",
      "Client",
      "User",
      "Page",
      "Action",
      "Data",
      "Status"
    ]
  },
  {
    "fileName": "src/pages/operations/ev-migration/ev-migration-page.tsx",
    "ns": "ev-migration-page",
    "keys": [
      "In Progress",
      "Success",
      "Failed",
      "Partial Success",
      "Move HTP Stations to EV",
      "ChargeBoxId",
      "Start New",
      "Created By",
      "Created At",
      "Stations",
      "Status",
      "Finished At",
      "Updated At"
    ]
  },
  {
    "fileName": "src/pages/operations/ev-migration/ev-migration-new-job-popup.tsx",
    "ns": "ev-migration-new-job-popup",
    "keys": [
      "An unexpected error occurred.",
      "New EV Migration Job",
      "Migrate",
      "Search ChargeBoxId"
    ]
  },
  {
    "fileName": "src/pages/operations/ev-migration/ev-migration-job-details-page.tsx",
    "ns": "ev-migration-job-page",
    "keys": [
      "In Progress",
      "Success",
      "Failed",
      "Partial Success",
      "Not Started",
      "In Progress",
      "Failed",
      "Success",
      "Move HTP Stations to EV",
      "Created By",
      "Created At",
      "Status",
      "Updated At",
      "Finished At",
      "Back To Ev Migration",
      "ChargeBoxId",
      "Status",
      "Steps",
      "Failure Reason"
    ]
  },
  {
    "fileName": "src/pages/dev/dev-component-gallery/component-gallery-page.tsx",
    "ns": "component-gallery",
    "keys": [
      "This is a page title",
      "This is the page description"
    ]
  },
  {
    "fileName": "src/pages/clusters/clusters-list-page.tsx",
    "ns": "clusters-list-page",
    "keys": [
      "aktiviert",
      "deaktiviert",
      "dynamisch",
      "statisch",
      "Suchen",
      "Strategie",
      "Status",
      "medium",
      "low",
      "high",
      "aktiviert",
      "deaktiviert",
      "dynamisch",
      "statisch",
      "Standort",
      "Gesellschaft",
      "PLZ",
      "Strategie",
      "dynamisch",
      "statisch",
      "Status",
      "aktiviert",
      "deaktiviert",
      "Auslastung",
      "Anzahl Ladepunkte",
      "Details"
    ]
  },
  {
    "fileName": "src/pages/clusters/clusters-detail-stations-page.tsx",
    "ns": "clusters-detail-stations-page",
    "keys": [
      "Zur Cluster Übersicht",
      "Lastmanagement",
      "Ladestationen",
      "Ladepunkte"
    ]
  },
  {
    "fileName": "src/pages/clusters/clusters-detail-chargepoints-page.tsx",
    "ns": "clusters-detail-stations-page",
    "keys": [
      "Zur Cluster Übersicht",
      "Lastmanagement",
      "Ladestationen",
      "Ladepunkte"
    ]
  },
  {
    "fileName": "src/pages/charging-stations-overview/charging-stations-overview-page.tsx",
    "ns": "charging-stations-overview-page",
    "keys": [
      "Location",
      "Status",
      "Energie",
      "Aktuelle Ladeleistung",
      "Alle Bereiche öffnen",
      "Alle Bereiche schließen",
      "Aktuelle Ladeleistung"
    ]
  },
  {
    "fileName": "src/pages/charging-stations-overview/charging-stations-energy-page.tsx",
    "ns": "charging-stations-energy-page",
    "keys": [
      "Location",
      "Status",
      "Energie",
      "Alle Bereiche öffnen",
      "Alle Bereiche schließen",
      "Geladene Energie"
    ]
  },
  {
    "fileName": "src/pages/charging-sessions/lmfm-charging-sessions-list-page.tsx",
    "ns": "lmfm-charging-sessions-list-page",
    "keys": [
      "EVSE-ID",
      "Location",
      "Area",
      "Badge Number",
      "Badge Label",
      "Cost Center",
      "Start",
      "End",
      "Duration",
      "Used",
      "Service",
      "Charged",
      "Energy",
      "(kWh)",
      "Refund",
      "(€)",
      "Cost",
      "(€)",
      "Status"
    ]
  },
  {
    "fileName": "src/pages/charging-sessions/lm-charging-sessions-list-page.tsx",
    "ns": "lm-charging-sessions-list-page",
    "keys": [
      "EVSE-ID",
      "Location",
      "Area",
      "Start",
      "End",
      "Duration",
      "Used",
      "Service",
      "Charged",
      "Energy",
      "(kWh)",
      "Refund",
      "(€)",
      "Cost",
      "(€)",
      "Status"
    ]
  },
  {
    "fileName": "src/pages/charging-sessions/fm-charging-sessions-list-page.tsx",
    "ns": "fm-charging-sessions-list-page",
    "keys": [
      "Badge Number",
      "Badge Label",
      "Cost center",
      "Start",
      "End",
      "Duration",
      "Used",
      "Service",
      "Charged",
      "Energy",
      "(kWh)",
      "Cost",
      "(€)",
      "Status"
    ]
  },
  {
    "fileName": "src/pages/charging-sessions/charging-sessions-list-page.tsx",
    "ns": "session-status-column-value",
    "keys": [
      "Laden",
      "Besetzt",
      "Beendet"
    ]
  },
  {
    "fileName": "src/pages/charging-sessions/charging-sessions-list-page.tsx",
    "ns": "charging-sessions-list-page",
    "keys": [
      "Beendet",
      "Besetzt",
      "Laden",
      "Ladevorgänge",
      "Total",
      "Used services",
      "Total",
      "Used services",
      "Geladene Energie",
      "Ergebnisse anzeigen",
      "Ladeerstattung",
      "Ergebnisse anzeigen",
      "Ladekosten",
      "Ergebnisse anzeigen",
      "Public Service",
      "Exportieren"
    ]
  },
  {
    "fileName": "src/pages/charging-sessions/charging-sessions-export-popup.tsx",
    "ns": "charging-sessions-export-popup",
    "keys": [
      "Es muss mindestens ein Element für den Export ausgewählt werden.",
      "Dateityp",
      "Stationen",
      "Badges",
      "Stationen",
      "Badges",
      "EMPLOYEE",
      "HOME",
      "PRIVATE",
      "PUBLIC",
      "UNDEFINED",
      "WORK",
      "Kartennummer",
      "Anzahl Ladevorgänge",
      "Ladezeit gesamt",
      "Startdatum",
      "Enddatum",
      "Datum des Exports",
      "Uhrzeit des Exports",
      "Ein unerwarteter Fehler ist aufgetreten.",
      "Too many records to be exported!",
      "Geladene Energie auf Standorte aggregieren:",
      "Stationen",
      "Badges",
      "Geladene Energie auf Ressourcen aggregieren:",
      "Stationen",
      "Badges",
      "Es werden {{sessionsCount}} Ladevorgänge exportiert",
      "Exportieren",
      "EXPORT - Ladevorgänge",
      "Allgemeine Daten",
      "EVSE-ID",
      "Eindeutige Identifikationsnummer der Ladestation.",
      "Getätigt an (Gebiet/Gesellschaft)",
      "Beschreibt welcher Regions oder Geschellschaft die Ladestation zugeordnet ist.",
      "Getätigt an (Standort)",
      "Beschreibt welchem Standort die Ladestation zugeordnet ist.",
      "Getätigt von (Gebiet/Gesellschaft)",
      "Beschreibt welchem Standort die Ladekarte zugeordnet ist.",
      "Getätigt von (Standort)",
      "Beschreibt welchem Standort die Ladekarte zugeordnet ist.",
      "Kostenstelle der Karte",
      "Ladestart (UTC)",
      "Ladeende (UTC)",
      "Ladestart (Lokal)",
      "Ladeende (Lokal)",
      "Dauer",
      "Geladene Energie",
      "Genutzter Service",
      "Daten zu Ladestationen",
      "Hersteller",
      "Modell",
      "Steckeart und max. Ladeleistung",
      "Ladeart",
      "Angabe ob der Ladepunkt mit Wechsel- oder Gleichstrom lädt.",
      "Bezeichnung des Ladepunkts",
      "Ladeerstattung",
      "Die zu erwartende Erstattung für den Ladevorgang (netto).",
      "Straße",
      "Postleitzahl",
      "Ort",
      "External Hardware",
      "Public Service",
      "Daten zu Ladebadges",
      "Kartennummer",
      "Identifikationsnummer der Ladekarte (meist aufgedruckt).",
      "Kartenbezeichnung",
      "Im System hinterlegte Bezeichnung der Karte.",
      "Karteninhaber",
      "Beschreibt die Gesellschaft, der die Ladekarte zugeordnet ist.",
      "Leasingvertragsnummer",
      "Vom Leasinggeber hinterlegte Vertragsnummer.",
      "Standard Service",
      "Beschreibt den Service, der standardmäßig mit dieser Karte verwendet wird.",
      "RFID Tag",
      "Auslesbare Kennung des RFID-Chips der Ladekarte.",
      "Ladekosten",
      "Die zu erwartenden Kosten für den Ladevorgang (netto)."
    ]
  },
  {
    "fileName": "src/pages/charging-cards/comment-popup.tsx",
    "ns": "custom-name-popup",
    "keys": [
      "Type here the cost center to help identify this device",
      "Maximum length is 50",
      "chargepoint",
      "station",
      "card",
      "Edit",
      "Create",
      "cost center",
      "The cost center must be appropriate, relevant and not excessive. Labels must therefore remain objective, not be insulting or undermine a person's dignity.",
      "Save"
    ]
  },
  {
    "fileName": "src/pages/charging-cards/charging-cards-list-page.tsx",
    "ns": "charging-cards-list-page",
    "keys": [
      "Charging Badges",
      "Number of badges",
      "Total",
      "Used services",
      "Total",
      "Used services",
      "Active",
      "Inactive",
      "Charging",
      "Badge",
      "State",
      "Edit",
      "Active",
      "Inactive",
      "Only states from badges with the service type WORK can be edited. Badges provided by leasing partners cannot be edited.",
      "Active",
      "Inactive",
      "Badge Number",
      "Badge",
      "Owner",
      "Cost",
      "Center",
      "Only cost centers from badges with the service type WORK can be edited. Badges provided by leasing partners cannot be edited.",
      "Badge Label",
      "Only labels from badges with the service type WORK can be edited. Badges provided by leasing partners cannot be edited.",
      "Standard Service",
      "Used Service",
      "+ Energy (kWh)",
      "Used Service",
      "+ Energy (kWh)",
      "Status",
      "Charging",
      "Finished"
    ]
  },
  {
    "fileName": "src/pages/administration/users/user-list-page/user-list-page.tsx",
    "ns": "user-list-popup",
    "keys": [
      "Administration",
      "Benutzer suchen",
      "Rollen",
      "Kunde",
      "Benutzer erstellen",
      "Name",
      "E-Mail",
      "Download email addresses",
      "Rollen",
      "Weniger anzeigen",
      "Mehr anzeigen ({{hiddenCount}} weitere)",
      "Zugang",
      "Kunde",
      "Weniger anzeigen",
      "Mehr anzeigen ({{hiddenCount}} weitere)",
      "Löschen",
      "Editieren"
    ]
  },
  {
    "fileName": "src/pages/administration/users/user-delete-popup/user-delete-popup.tsx",
    "ns": "user-delete-popup",
    "keys": [
      "Benutzer Löschen",
      "Löschen",
      "Möchten Sie den Benutzer {{userMail}} wirklich löschen?"
    ]
  },
  {
    "fileName": "src/pages/administration/users/user-create-popup/user-create-popup.tsx",
    "ns": "user-create-popup",
    "keys": [
      "Vorname",
      "Dieses Feld darf nicht leer sein.",
      "Dieses Feld darf nicht leer sein.",
      "Vorname darf nicht länger als 128 Zeichen sein.",
      "Nachname",
      "Dieses Feld darf nicht leer sein.",
      "Dieses Feld darf nicht leer sein.",
      "Nachname darf nicht länger als 128 Zeichen sein.",
      "English",
      "German",
      "French",
      "Language",
      "E-Mail",
      "Dieses Feld darf nicht leer sein.",
      "E-Mail darf nicht länger als 254 Zeichen sein.",
      "Ungültige E-Mail-Adresse.",
      "E-Mail ist bereits vergeben.",
      "Rollen",
      "Nur Rollen vom Typ Root werden in der Liste angezeigt.",
      "Um einen Benutzer zu erstellen, wählen Sie bitte mindestens eine Rolle aus.",
      "Benutzer Editieren",
      "Benutzer Erstellen",
      "Speichern",
      "Erstellen",
      "Nach dem Erstellen, erhält der Benutzer eine automatisierte E-mail zur Passwortvergabe.",
      "Der Benutzer existiert bereits und erhält Zugriff auf dieses Affiliate. Um den Namen des Benutzers zu ändern, nutzen Sie die Editierfunktion."
    ]
  },
  {
    "fileName": "src/pages/administration/shared/affiliate-picker.tsx",
    "ns": "affiliate-picker",
    "keys": [
      "Affiliate wechseln"
    ]
  },
  {
    "fileName": "src/pages/administration/shared/affiliate-picker.tsx",
    "ns": "affiliate-select-popup",
    "keys": [
      "Übernehmen",
      "Wählen Sie ein Affiliate"
    ]
  },
  {
    "fileName": "src/pages/administration/shared/admin-tab-panel.tsx",
    "ns": "admin-tab-panel",
    "keys": [
      "Kunden",
      "Rollen",
      "Benutzer"
    ]
  },
  {
    "fileName": "src/pages/administration/roles/role-list-page/role-list-page.tsx",
    "ns": "role-list-page",
    "keys": [
      "Root",
      "Standard",
      "Individual",
      "Name",
      "Rollentyp",
      "Löschen",
      "Editieren",
      "Administration",
      "Rollen suchen",
      "Rolle erstellen",
      "Rolle löschen",
      "Löschen",
      "Sind Sie sicher, dass Sie die Rolle '{{roleName}}' löschen möchten?",
      "Diese Rolle ist mit {{usersCount}} Benutzer(n) verknüpft. Wenn Sie diese Rolle löschen, können diese Benutzer nicht mehr auf den aktuellen Service zugreifen.",
      "{{usersCount}} Benutzern ist keine weitere Rolle zugeordnet.",
      "Benutzer ohne zugeordnete Rollen werden unwiederbringlich gelöscht.",
      "Rolle löschen",
      "Schließen",
      "Der angemeldete Benutzer kann diese Rolle nicht löschen, da diese dem Benutzer selbst zugewiesen ist."
    ]
  },
  {
    "fileName": "src/pages/administration/roles/role-create-popup/role-create-popup.tsx",
    "ns": "role-create-page",
    "keys": [
      "Rollenname",
      "Rollenname hinzufügen",
      "Dieses Feld darf nicht leer sein.",
      "Rollenname muss mindestens 4 Zeichen enthalten.",
      "Rollenname ist länger als 40 Zeichen.",
      "Rollenname wird bereits von einer anderen Rolle verwendet.",
      "Rollenname wird bereits von einer anderen Rolle verwendet.",
      "Rollentyp",
      "Root",
      "Standard",
      "Individual",
      "Rolle Editieren",
      "Individuelle Rolle Erstellen",
      "Rolle Erstellen",
      "Speichern",
      "Erstellen",
      "Rollentyp",
      "Standard",
      "Dieser Rollentyp kann an Clients vergeben werden.",
      "Root",
      "Ist für die Administration von Clients bestimmt und kann nur an Mitarbeiter und Service-Partner von TotalEnergies vergeben werden.",
      "Rollenberechtigung",
      "Wählen Sie die Berechtigungen, die diese Rolle haben soll.",
      "Um eine Rolle zu erstellen, wählen Sie bitte mindestens eine Berechtigung aus.",
      "Diese Berechtigung wird allen Benutzern entzogen, die diese Rolle besitzen."
    ]
  },
  {
    "fileName": "src/pages/administration/roles/role-create-popup/permissions-groups.tsx",
    "ns": "permissions-group",
    "keys": [
      "Ladestationen",
      "Zugang zu den Seiten \"Standort\" und \"Ladevorgänge\"",
      "Ermöglicht das Einsehen der Menüpunkte \"Standort\" und \"Ladevorgänge\".",
      "Zugang zu der Seite \"Ladeinfrastruktur\"",
      "Ermöglicht das Einsehen des Menüpunkts \"Ladeinfrastruktur\".",
      "Erstellen von Service-Tickets bezüglich Ladeinfrastruktur.",
      "Ermöglicht es Service-Tickets über die Seite \"Ladeinfrastruktur\" zu erstellen.",
      " Neustart einer Ladestation",
      "Ermöglicht es auf der Seite \"Ladeinfrastruktur\" eine Station neu zu starten.",
      "Remote-Start eines Ladevorganges",
      "Ermöglicht auf der Seite \"Ladeinfrastruktur\" das Starten eines Ladevorgangs.",
      "Remote-Stop eines Ladevorganges",
      "Ermöglicht auf der Seite \"Ladeinfrastruktur\" das Stoppen eines Ladevorgangs.",
      "Entsperren eines Ladekabels",
      "Ermöglicht es auf der Seite \"Ladeinfrastruktur\" das Ladekabel an einem Ladepunkt zu entsperren.",
      "Reservierung eines Ladepunktes",
      "Ermöglicht auf der Seite \"Ladeinfrastruktur\" einen Ladepunkt für eine bestimmte Karte für einen gewählten Zeitraum zu reservieren.",
      "Ent-/sperren einer Ladepunkten",
      "Ermöglicht es auf der Seite \"Ladeinfrastruktur\" die Ladepunkte einer Station zu sperren/entsperren.",
      "Change label of charging stations/points",
      "Allows to change the custom label of charging stations and charging points on the page \"Charging Infrastructure\".",
      "Zugang zur Seite \"Status Log\"",
      "Ermöglicht das Einsehen des Menüpunkts \"Status Log\".",
      "Ladekarten",
      "Ladekarten mit dem Service HOME anzeigen",
      "Ladekarten mit dem Service HOME sind im Menü \"Ladekarten\" sichtbar und können eingesehen werden. Die Ladevorgänge dieser Karten sind im Menü \"Ladevorgänge\" einsehbar.",
      "Ladekarten mit dem Service EMPLOYEE anzeigen",
      "Ladekarten mit dem Service EMPLOYEE sind im Menü \"Ladekarten\" sichtbar und können eingesehen werden. Die Ladevorgänge dieser Karten sind im Menü \"Ladevorgänge\" einsehbar.",
      "Ladekarten mit dem Service WORK anzeigen",
      "Ladekarten mit dem Service WORK sind im Menü \"Ladekarten\" sichtbar und können eingesehen werden. Die Ladevorgänge dieser Karten sind im Menü \"Ladevorgänge\" einsehbar.",
      "Ladekarten mit dem Service WORK editieren",
      "Ermöglicht das Editieren von Ladekarten mit dem Service WORK.",
      "Ladekarten mit unbekanntem Service anzeigen",
      "Ladekarten mit unbekanntem Service sind im Menü \"Ladekarten\" sichtbar und können eingesehen werden. Die Ladevorgänge dieser Karten sind im Menü \"Ladevorgänge\" einsehbar.",
      "Ladevorgänge",
      "Energiemenge von Ladevorgängen aggregieren",
      "Ermöglicht es beim Export von Ladevorgängen die Energiemenge auf Stationen, Karten und Standorte zu aggregieren.",
      "Lastmanagement",
      "Zugang zur Seite \"Lastmanagement\"",
      "Ermöglicht das Einsehen des Menüpunkts \"Lastmanagement\".",
      "Zugang zur Registerkarte \"Rollen\"",
      "Ermöglicht das Einsehen des Menüpunkts \"Administration\" und des Tabs \"Rollen\" in diesem Menü.",
      "Root-Rolle erstellen und editieren",
      "Ermöglicht das Erstellen und Bearbeiten von Rollen des Typs \"Root\".",
      "Root-Rolle löschen",
      "Ermöglicht das Löschen von Rollen des Typs \"Root\".",
      "Standard-Rolle erstellen und editieren",
      "Ermöglicht das Erstellen und Bearbeiten von Rollen des Typs \"Standard\". Benutzer mit Standard-Rollen werden auf der Seite \"Organisation\" des jeweiligen Kunden gepflegt.",
      "Standard-Rolle löschen",
      "Ermöglicht das Löschen von Rollen des Typs \"Standard\".",
      "Individual-Rolle erstellen und editieren",
      "Ermöglicht das Erstellen und Bearbeiten von Rollen des Typs \"Individual\". Individual-Rollen werden auf der Seite \"Organisation\" des jeweiligen Kunden angelegt und können nur an Benutzer dieses Kunden vergeben werden.",
      "Individual-Rolle löschen",
      "Ermöglicht das Löschen von Rollen des Typs \"Individual\".",
      "Zugang zur Registerkarte \"Kunden\"",
      "Ermöglicht das Einsehen des Menüpunkts \"Administration\" und des Tabs \"Kunden\" in diesem Menü.",
      "Kunden erstellen und editieren",
      "Ermöglicht das Erstellen und Bearbeiten von Kunden.",
      "Kunden löschen",
      "Ermöglicht das Löschen von Kunden.",
      "Zugang zur Registerkarte \"Benutzer\"",
      "Ermöglicht das Einsehen des Menüpunkts \"Administration\" und des Tabs \"Benutzer\" in diesem Menü.",
      "Benutzer erstellen, editieren und löschen",
      "Ermöglicht das Erstellen, Bearbeiten und Löschen von Benutzern im Menü \"Administration\".",
      "Organisation",
      "Die Struktur des Kunden ist auf der Seite \"Organisation\" einsehbar",
      "Ermöglicht das Einsehen der Registerkarte \"Struktur\" auf der \"Organisation\"-Seite.",
      "Bereichs-Knoten unter Standort-Knoten erstellen und editieren",
      "Ermöglicht das Erstellen, Bearbeiten und Löschen von Bereichs-Knoten direkt unter Standort-Knoten.",
      "Gebiets-/Gesellschafts-und Standort-Knoten erstellen, editieren und löschen sowie direkt angebundene Bereichs-Knoten",
      "Ermöglicht das Erstellen, Bearbeiten und Löschen von Gebiets-/Gesellschafts-und Standort-Knoten sowie von Bereichs-Knoten, die direkt an diese Knoten angebunden sind. ",
      "Kunden-Benutzer erstellen und editieren",
      "Ermöglicht das Erstellen, Bearbeiten und Löschen von Benutzern im Menü \"Organisation\".",
      "Ladekarten zuordnen",
      "Ermöglicht das Zuordnen von Ladekarten zu Hierarchie-Knoten.",
      "Ladestationen zuordnen",
      "Ermöglicht das Zuordnen von Ladestationen zu Hierarchie-Knoten.",
      "Operations",
      "Zugang zur Seite \"Operations\"",
      "Ermöglicht das Einsehen des Menüpunkts \"Operations\"."
    ]
  },
  {
    "fileName": "src/pages/administration/clients/manage-client-pageset/process-client-import-ressources.page.tsx",
    "ns": "process-client-import-ressources",
    "keys": [
      "Assign resources from CPMS",
      "The resources from the CPMS get assigned to the client.",
      "Importing Resources",
      "Assignment of resources successful.",
      "stations assigned.",
      "stations already assigned.",
      "stations unassigned.",
      "stations found in the CPMS but not in ECS.",
      "stations failed.",
      "area created.",
      "area deleted.",
      "badges assigned.",
      "badges unassigned.",
      "badges failed."
    ]
  },
  {
    "fileName": "src/pages/administration/clients/manage-client-pageset/process-client-create.page.tsx",
    "ns": "process-client-create-page",
    "keys": [
      "Create Client {{clientTitle}}",
      "The client was successfully created in the affiliate {{affiliateCode}}.",
      "In the next step the resources get assigned to the client."
    ]
  },
  {
    "fileName": "src/pages/administration/clients/manage-client-pageset/preview-client-structure.page.tsx",
    "ns": "preview-client-structure-page",
    "keys": [
      "Import client hierarchy",
      "Please validate the imported hierarchy.",
      "Cancel",
      "Continue"
    ]
  },
  {
    "fileName": "src/pages/administration/clients/manage-client-pageset/manage-client.page.tsx",
    "ns": "manage-client-page",
    "keys": [
      "Kundenname",
      "Firmennamen hinzufügen",
      "Dieses Feld darf nicht leer sein.",
      "Kundenname darf nicht länger als 100 Zeichen sein.",
      "Kundenname ist bereits vergeben.",
      "Code",
      "Der Code wird als eindeutige Kennung im System verwendet.",
      "Code",
      "Dieses Feld darf nicht leer sein.",
      "Code darf nicht länger als 10 Zeichen sein.",
      "Code ist bereits vergeben.",
      "Code ist bereits vergeben.",
      "Unternehmen-ID",
      "Unternehmen-ID muss die korrekte Firmen-ID von HTB oder die Org-Id von EVP sein, um die richtige Hierarchie zu übertragen.",
      "Unternehmen-ID",
      "Dieses Feld darf nicht leer sein.",
      "Unternehmen-ID ist bereits vergeben.",
      "Unternehmen-ID ist weder in HTB noch in EVP vorhanden.",
      "Rollen",
      "Wählen Sie die Rollen, die Sie diesem Unternehmen hinzufügen möchten.",
      "Um die Änderung am Unternehmen zu speichern, müssen Sie mindestens eine Rolle auswählen.",
      "Um das Unternehmen zu erstellen, müssen Sie mindestens eine Rolle auswählen.",
      "Create Client",
      "Edit Client",
      "Create client",
      "Save Client",
      "1 / Client information",
      "2 / Roles & Permissions",
      "Selected"
    ]
  },
  {
    "fileName": "src/pages/administration/clients/client-list-page/client-list-page.tsx",
    "ns": "client-list-page",
    "keys": [
      "Clients",
      "Clients",
      "Roles",
      "Users",
      "Search",
      "Create new client",
      "Name",
      "Actions",
      "Delete",
      "Assign resources",
      "Edit",
      "Kunden Löschen",
      "Löschen",
      "Sind Sie sicher, dass Sie Kunden {{title}} löschen möchten?",
      "Bei der Löschung des Kunden werden alle Daten des Kunden gelöscht.",
      "Sie besitzen nicht das Recht Nutzer zu löschen.",
      "Nutzer die dem Kunden zugeordnet sind bleiben erhalten.",
      "Resource Assignment",
      "Cancel",
      "Confirm",
      "This action will trigger the automatic update of client hierarchy."
    ]
  },
  {
    "fileName": "src/pages/administration/clients/client-create-popup/client-create-popup.tsx",
    "ns": "client-create-popup",
    "keys": [
      "Kundenname",
      "Firmennamen hinzufügen",
      "Dieses Feld darf nicht leer sein.",
      "Dieses Feld darf nicht leer sein.",
      "Kundenname darf nicht länger als 100 Zeichen sein.",
      "Kundenname ist bereits vergeben.",
      "Code",
      "Der Code wird als eindeutige Kennung im System verwendet.",
      "Code",
      "Dieses Feld darf nicht leer sein.",
      "Code darf nicht länger als 10 Zeichen sein.",
      "Code ist bereits vergeben.",
      "Code ist bereits vergeben.",
      "Unternehmen-ID",
      "Unternehmen-ID muss die korrekte Firmen-ID von HTB oder die Org-Id von EVP sein, um die richtige Hierarchie zu übertragen.",
      "Unternehmen-ID",
      "Unternehmen-ID ist bereits vergeben.",
      "Unternehmen-ID ist weder in HTB noch in EVP vorhanden.",
      "Rollen",
      "Wählen Sie die Rollen, die Sie diesem Unternehmen hinzufügen möchten.",
      "Um die Änderung am Unternehmen zu speichern, müssen Sie mindestens eine Rolle auswählen.",
      "Um das Unternehmen zu erstellen, müssen Sie mindestens eine Rolle auswählen.",
      "Kunde Editieren",
      "Kunde Erstellen",
      "Speichern",
      "Erstellen",
      "Die Rolle wird allen Usern entzogen, die diese Rolle besitzen.",
      "Individuelle Rollen",
      "Individuelle Rolle für den Kunden erstellen"
    ]
  },
  {
    "fileName": "src/components-v2/filters.tsx",
    "ns": "filters",
    "keys": [
      "available",
      "charging",
      "occupied",
      "defective",
      "notavailable",
      "unknown",
      "success",
      "alert",
      "undef"
    ]
  },
  {
    "fileName": "src/components-v2/date-range.tsx",
    "ns": "date-range",
    "keys": [
      "From",
      "To",
      "Reset",
      "Apply"
    ]
  },
  {
    "fileName": "src/components-v2/button.tsx",
    "ns": "modal",
    "keys": [
      "Cancel"
    ]
  },
  {
    "fileName": "src/components-v2/breadcrumb.tsx",
    "ns": "breadcrumb",
    "keys": [
      "Back"
    ]
  },
  {
    "fileName": "src/components-v2/tag-suggestion/tag-suggestion.tsx",
    "ns": "tag-suggestions",
    "keys": [
      "Search",
      "Reset"
    ]
  },
  {
    "fileName": "src/components-v2/select/single-select.tsx",
    "ns": "single-select",
    "keys": [
      "Bitte geben Sie ein Suchkriterium ein",
      "Keine Einträge für \"{{searchValue}}\" gefunden"
    ]
  },
  {
    "fileName": "src/components-v2/pagination/pagination.tsx",
    "ns": "pagination",
    "keys": [
      "Go directly to page",
      "Previous",
      "Next",
      "Lines per page",
      "of"
    ]
  },
  {
    "fileName": "src/components-v2/page/page.tsx",
    "ns": "page-layout",
    "keys": [
      "loading"
    ]
  },
  {
    "fileName": "src/components-v2/page/page.tsx",
    "ns": "page",
    "keys": [
      "You dont have permissions to access this page",
      "This page could not be found",
      "An unexpected error occoured"
    ]
  },
  {
    "fileName": "src/components-v2/number-range/number-range.tsx",
    "ns": "number-range",
    "keys": [
      "ab",
      "bis",
      "ungültiger wertebereich",
      "ungültiger wert",
      "ungültiger wert",
      "maximal",
      "maximal",
      "From",
      "To",
      "Apply",
      "Reset",
      "Select"
    ]
  },
  {
    "fileName": "src/components-v2/modal/modal.tsx",
    "ns": "modal",
    "keys": [
      "Accept",
      "Cancel"
    ]
  },
  {
    "fileName": "src/components-v2/main-nav/main-nav.tsx",
    "ns": "main-nav",
    "keys": [
      "Standort",
      "Ladeinfrastruktur",
      "Ladekarten",
      "Ladevorgänge",
      "Lastmanagement",
      "Lastmanagement",
      "Status Log",
      "Organisation",
      "Organisation",
      "Administration",
      "Administration",
      "Administration",
      "Super Administration",
      "Super Administration",
      "Operations",
      "Development",
      "Language settings",
      "Show user manual",
      "Abmelden",
      "Select preferred language to set up",
      "Interface is currently set to English",
      "Set language to"
    ]
  },
  {
    "fileName": "src/components-v2/filter-toggle/filter-toggle.tsx",
    "ns": "filter-toggle",
    "keys": [
      "Clear all"
    ]
  },
  {
    "fileName": "src/components-v2/business-components/domain-mappings.tsx",
    "ns": "common-translations",
    "keys": [
      "Home",
      "Employee",
      "Public",
      "Work",
      "Unbekannt",
      "Unbekannt",
      "English",
      "French",
      "German",
      "Anchor",
      "Standard",
      "Root",
      "Individual",
      "System",
      "Unknown",
      "Online",
      "Offline",
      "Fehlerhaft",
      "Verfügbar",
      "Laden",
      "Besetzt",
      "Nicht verfügbar",
      "Nicht verfügbar (Reserviert)",
      "Nicht verfügbar (Gesperrt)",
      "Fehlerhaft",
      "Unbekannt",
      "Charging",
      "Closed",
      "Occupied"
    ]
  },
  {
    "fileName": "src/components-v2/business-components/suggestions/suggestions.tsx",
    "ns": "suggestions",
    "keys": [
      "Station",
      "Charge Point",
      "Badge",
      "Cost Center"
    ]
  },
  {
    "fileName": "src/components-v2/business-components/station-status-summary-panel/station-status-summary-panel.tsx",
    "ns": "station-status-summary-panel",
    "keys": [
      "Ladestationen",
      "Total",
      "Gesamtanzahl der Stationen.",
      "Online",
      "Die Station überträgt Daten und funktioniert ordnungsgemäß.",
      "Offline",
      "Die Station sendet kein Signal. Es ist nicht bekannt, ob die Station ordnungsgemäß funktioniert.",
      "Fehlerhaft",
      "Die Station hat eine Fehlernachricht gesendet und funktioniert nicht ordnungsgemäß.",
      "Nicht in Betrieb",
      "Die Station wurde im System bereits angelegt aber noch nicht in Betrieb genommen oder wurde außer Betrieb gesetzt. Die Ladepunkte dieser Stationen werden im System nicht angezeigt.",
      "Ladepunkte",
      "Total",
      "Gesamtanzahl der Ladepunkte.",
      "Verfügbar",
      "Ladepunkte sind bereit zur Verwendung.",
      "Laden",
      "Ladepunkte werden derzeit verwendet und es findet ein Ladevorgang statt.",
      "Besetzt",
      "Ladepunkte sind mit einem Fahrzeug verbunden aber es findet kein Ladevorgang statt.",
      "Fehlerhaft",
      "Ladepunkte haben eine Fehlernachricht gesendet und funktionieren nicht ordnungsgemäß.",
      "Nicht Verfügbar",
      "Ladepunkte sind reserviert oder gesperrt.",
      "Unbekannt",
      "Ladestation ist offline und es wurde kein Fehler für den Ladepunkt gesendet. Es ist unbekannt, ob der Ladepunkt verwendet werden kann."
    ]
  },
  {
    "fileName": "src/components-v2/business-components/station-list-item/station-list-item.tsx",
    "ns": "station-list-item",
    "keys": [
      "ID",
      "S/N",
      "Modell",
      "S/N",
      "Lastmanagement",
      "Aktiviert",
      "Deaktiviert",
      "Ladepunkt",
      "Ladepunkte",
      "Charging Records",
      "Fernsteuerung nicht möglich: Die Ladestation ist entweder offline oder nicht in Betrieb oder Sie verfügen nicht über die notwendige Berechtigung."
    ]
  },
  {
    "fileName": "src/components-v2/business-components/common-charts/common-charts.tsx",
    "ns": "common-charts",
    "keys": [
      "Gesamt",
      "Total",
      "Work",
      "Employee",
      "Home",
      "Public",
      "Unknown",
      "Charged Energy",
      "Total",
      "Work",
      "Employee",
      "Home",
      "Public",
      "Unbekannt",
      "Charging Sessions"
    ]
  },
  {
    "fileName": "src/components-v2/business-components/client-choose-popup/client-choose-popup.tsx",
    "ns": "client-choose-popup",
    "keys": [
      "Choose Organisation",
      "Select an organisation",
      "Cancel and return",
      "Apply Organisation"
    ]
  },
  {
    "fileName": "src/components-v2/business-components/chargepoint-list-item/chargepoint-list-item.tsx",
    "ns": "chargepoints-list-item",
    "keys": [
      "EVSE-ID",
      "EVSE ID unbekannt",
      "Aktuelle Ladeleistung reduced",
      "Aktuelle Ladeleistung not reduced",
      "kW",
      "of max.",
      "Lastmanagement",
      "Aktiviert",
      "Deaktiviert",
      "Unbekannt",
      "Ladepunkt ist reserviert",
      "Ladepunkt ist gesperrt",
      "Charging Records",
      "Fernsteuerung nicht möglich: Die Ladestation ist entweder offline oder nicht in Betrieb oder Sie verfügen nicht über die notwendige Berechtigung."
    ]
  },
  {
    "fileName": "src/components-v2/affiliate-chooser/affiliate-chooser.tsx",
    "ns": "affiliate-chooser",
    "keys": [
      "Change affiliate",
      "Select an affiliate",
      "Cancel and return",
      "Apply affiliate",
      "Change affiliate"
    ]
  },
  {
    "fileName": "src/components/tag.tsx",
    "ns": "tag",
    "keys": [
      "Home",
      "Employee",
      "Public",
      "Work",
      "Unbekannt",
      "Unbekannt",
      "Dienstwagen an Heimladestation",
      "Privates Fahrzeug von Mitarbeitenden",
      "Öffentlicher Ladevorgang (Roaming)",
      "Dienstwagen am Standort",
      "Ladevorgang konnte keinem Service zugeordnet werden"
    ]
  },
  {
    "fileName": "src/components/tag-selector.tsx",
    "ns": "tag-selector",
    "keys": [
      "Bitte geben Sie ein Suchkriterium ein"
    ]
  },
  {
    "fileName": "src/components/tag-selector.tsx",
    "ns": "card-list-entry",
    "keys": [
      "Kartennummer",
      "Kartenbezeichnung"
    ]
  },
  {
    "fileName": "src/components/role-permissions-popup.tsx",
    "ns": "role-permissions-popup",
    "keys": [
      "Root",
      "Standard",
      "Individual",
      "Granted permissions with this role"
    ]
  },
  {
    "fileName": "src/components/paging.tsx",
    "ns": "paging",
    "keys": [
      "Zeilen pro Seite",
      "von",
      "Seite",
      "von"
    ]
  },
  {
    "fileName": "src/components/page.tsx",
    "ns": "page",
    "keys": [
      "Sie haben kein Zugriff auf diese Seite",
      "Diese Seite konnte nicht gefunden werden",
      "Es ist ein unerwarteter Fehler aufgetreten",
      "Diese Seite konnte nicht gefunden werden",
      "Diese Seite konnte nicht gefunden werden"
    ]
  },
  {
    "fileName": "src/components/page-layout.tsx",
    "ns": "page-layout",
    "keys": [
      "loading"
    ]
  },
  {
    "fileName": "src/components/page-layout.tsx",
    "ns": "page",
    "keys": [
      "You dont have permissions to access this page",
      "This page could not be found",
      "An unexpected error occoured"
    ]
  },
  {
    "fileName": "src/components/outlet-type-icon.tsx",
    "ns": "outlet-type-icon",
    "keys": [
      "Typ 1",
      "Typ 2",
      "Combo 1",
      "Combo 2",
      "Unbekannt"
    ]
  },
  {
    "fileName": "src/components/main-menu.tsx",
    "ns": "main-menu",
    "keys": [
      "Standort",
      "Ladeinfrastruktur",
      "Ladekarten",
      "Ladevorgänge",
      "Lastmanagement",
      "Lastmanagement",
      "Status Log",
      "Organisation",
      "Organisation",
      "Administration",
      "Administration",
      "Administration",
      "Super Administration",
      "Super Administration",
      "Operations",
      "Development",
      "Switch Application"
    ]
  },
  {
    "fileName": "src/components/main-menu-desktop.tsx",
    "ns": "main-menu-desktop",
    "keys": [
      "Select the module you want to switch to",
      "The targeted module will open in a new tab",
      "Mehr",
      "Mehr",
      "Show user manual",
      "Abmelden",
      "Show user manual",
      "Abmelden"
    ]
  },
  {
    "fileName": "src/components/icon-explanation-section.tsx",
    "ns": "icon-explanation-section",
    "keys": [
      "Externe Hardware die nicht von TotalEnergies vertrieben wird mit eingeschränktem Funktionsumfang! Wenden Sie sich für technischen Support direkt an den Hersteller der Hardware.",
      "Diese Station ist öffentlich gestellt und kann mit Karten von Mobilitätsanbietern im Roaming-Netzwerk genutzt werden.",
      "Bitte beachten Sie diese Spezifikationen der Hardware:"
    ]
  },
  {
    "fileName": "src/components/form-card.tsx",
    "ns": "form-card",
    "keys": [
      "Bearbeiten",
      "Löschen",
      "Hinzufügen"
    ]
  },
  {
    "fileName": "src/components/edit-popup.tsx",
    "ns": "edit-popup",
    "keys": [
      "Speichern",
      "Abbrechen",
      "Abbrechen",
      "Schließen",
      "Bitte warten...",
      "Schließen",
      "Erfolgreich abgeschlossen",
      "Schließen",
      "Teilweise erfolgreich abgeschlossen",
      "Schließen",
      "Fehler bei der Ausführung",
      "Abbrechen",
      "Bestätigen",
      "Mit diesen einstellungen weiter machen??",
      "Abbrechen",
      "Bestätigen",
      "Sind sie sich sicher dass diese Aktion ausgeführt werden soll?"
    ]
  },
  {
    "fileName": "src/components/delete-popup.tsx",
    "ns": "delete-popup",
    "keys": [
      "Löschen",
      "Abbrechen"
    ]
  },
  {
    "fileName": "src/components/date-range-picker.tsx",
    "ns": "date-range-picker",
    "keys": [
      "Datum wählen",
      "Datum wählen",
      "von",
      "bis",
      "Datum wählen",
      "Datum wählen",
      "separator"
    ]
  },
  {
    "fileName": "src/components/chart-filter.tsx",
    "ns": "chart-filter",
    "keys": [
      "Letzten Monat",
      "Letzte 14 Tage",
      "Letzte 7 Tage",
      "Letzte 24 Stunden",
      "Letzte 12 Stunden",
      "Letzte 6 Stunden",
      "Letzte Stunde",
      "Datum wählen",
      "Datum wählen",
      "von",
      "bis"
    ]
  },
  {
    "fileName": "src/components/station-status-summary-panel/station-status-summary-panel.tsx",
    "ns": "station-status-summary-panel",
    "keys": [
      "Ladestationen",
      "Online",
      "Die Station überträgt Daten und funktioniert ordnungsgemäß.",
      "Offline",
      "Die Station sendet kein Signal. Es ist nicht bekannt, ob die Station ordnungsgemäß funktioniert.",
      "Fehlerhaft",
      "Die Station hat eine Fehlernachricht gesendet und funktioniert nicht ordnungsgemäß.",
      "Nicht in Betrieb",
      "Die Station wurde im System bereits angelegt aber noch nicht in Betrieb genommen oder wurde außer Betrieb gesetzt. Die Ladepunkte dieser Stationen werden im System nicht angezeigt.",
      "Ladepunkte",
      "Verfügbar",
      "Ladepunkte sind bereit zur Verwendung.",
      "Laden",
      "Ladepunkte werden derzeit verwendet und es findet ein Ladevorgang statt.",
      "Besetzt",
      "Ladepunkte sind mit einem Fahrzeug verbunden aber es findet kein Ladevorgang statt.",
      "Fehlerhaft",
      "Ladepunkte haben eine Fehlernachricht gesendet und funktionieren nicht ordnungsgemäß.",
      "Nicht Verfügbar",
      "Ladepunkte sind reserviert oder gesperrt.",
      "Unbekannt",
      "Ladestation ist offline und es wurde kein Fehler für den Ladepunkt gesendet. Es ist unbekannt, ob der Ladepunkt verwendet werden kann."
    ]
  },
  {
    "fileName": "src/components/sessions-energy-chart/sessions-energy-chart.tsx",
    "ns": "sessions-energy-chart",
    "keys": [
      "Anzahl Karten",
      "Anzahl Ladevorgänge",
      "Gesamt",
      "Genutzte Sevices",
      "Geladene Energie",
      "Gesamt",
      "Genutzte Sevices",
      "Es handelt sich um gerundete Werte. Diese können sich deshalb von der angezeigten Summe in der Tabelle unterscheiden.",
      "Gesamt"
    ]
  },
  {
    "fileName": "src/components/select/single-select.tsx",
    "ns": "single-select",
    "keys": [
      "Bitte geben Sie ein Suchkriterium ein",
      "Keine Einträge für \"{{searchValue}}\" gefunden"
    ]
  },
  {
    "fileName": "src/components/org-hierarchy/select-client-popup.tsx",
    "ns": "org-select-client-popup",
    "keys": [
      "Übernehmen",
      "Wählen Sie eine Organisation"
    ]
  },
  {
    "fileName": "src/components/org-hierarchy/org-hierarchy-picker.tsx",
    "ns": "org-hierarchy-picker",
    "keys": [
      "teilweise",
      "ausgewählt",
      "Organisation wechseln",
      "Übernehmen"
    ]
  },
  {
    "fileName": "src/components/org-hierarchy/org-hierarchy-diagramm.tsx",
    "ns": "org-hierarchy-diagramm",
    "keys": [
      "Organisation wechseln",
      "Standardbereich",
      "Standardbereich"
    ]
  },
  {
    "fileName": "src/components/org-hierarchy/node-tooltip.tsx",
    "ns": "node-tooltip",
    "keys": [
      "Keine Ressourcen oder Benutzer zugeordnet"
    ]
  },
  {
    "fileName": "src/components/org-hierarchy/node-label.tsx",
    "ns": "node-hierarchy-label",
    "keys": [
      "Standardbereich"
    ]
  },
  {
    "fileName": "src/components/org-hierarchy/node-hierarchy-label.tsx",
    "ns": "node-hierarchy-label",
    "keys": [
      "Standardbereich"
    ]
  },
  {
    "fileName": "src/components/number-range/number-range.tsx",
    "ns": "number-range",
    "keys": [
      "ab",
      "bis",
      "ungültiger wertebereich",
      "ungültiger wert",
      "ungültiger wert",
      "maximal",
      "maximal",
      "von",
      "bis"
    ]
  },
  {
    "fileName": "src/components/infrastructure/station-list-item.tsx",
    "ns": "station-list-item",
    "keys": [
      "Modell",
      "S/N",
      "Lastmanagement",
      "Aktiviert",
      "Deaktiviert",
      "Ladepunkt",
      "Ladepunkte",
      "Ladevorgänge",
      "Fernsteuerung nicht möglich: Die Ladestation ist entweder offline oder nicht in Betrieb oder Sie verfügen nicht über die notwendige Berechtigung."
    ]
  },
  {
    "fileName": "src/components/infrastructure/station-filter-area.tsx",
    "ns": "stations-filter-area",
    "keys": [
      "Suchen",
      "Stromart (AC/DC)",
      "Public Service",
      "Online",
      "Offline",
      "Fehlerhaft",
      "Nicht in Betrieb"
    ]
  },
  {
    "fileName": "src/components/infrastructure/chargepoint-list-item.tsx",
    "ns": "chargepoints-list-item",
    "keys": [
      "EVSE ID unbekannt",
      "EVSE ID unbekannt",
      "Unbekannt",
      "Aktuelle Ladeleistung",
      "reduziert",
      "Aktuelle Ladeleistung",
      "P",
      "kW",
      "Ladepunkt ist reserviert",
      "Ladepunkt ist gesperrt",
      "Lastmanagement",
      "Aktiviert",
      "Deaktiviert",
      "Ladevorgänge",
      "Fernsteuerung nicht möglich: Die Ladestation ist entweder offline oder nicht in Betrieb oder Sie verfügen nicht über die notwendige Berechtigung."
    ]
  },
  {
    "fileName": "src/components/infrastructure/chargepoint-filter-area.tsx",
    "ns": "chargepoint-filter-area",
    "keys": [
      "Suchen",
      "Steckertyp",
      "Stromart (AC/DC)",
      "Public Service"
    ]
  },
  {
    "fileName": "src/components/cluster/cluster-workload-box.tsx",
    "ns": "cluster-workload-box",
    "keys": [
      "Low: Keine Einschränkung der Ladeleistung",
      "Medium: Aktuelle Ladeleistung nahe am gesetzten Limit",
      "High: Ladeleistung von Ladevorgängen reduziert"
    ]
  },
  {
    "fileName": "src/components/cluster/cluster-diagrams-panel.tsx",
    "ns": "cluster-diagrams-panel",
    "keys": [
      "Konfiguration und Diagramme",
      "AKTIVIERT",
      "DEAKTIVIERT",
      "Lastmanagement Status",
      "Cluster Limit",
      "STATISCH",
      "DYNAMISCH",
      "Lastmanagement Strategie",
      "Lastspitze",
      "Standort Limit",
      "Leistungsverlauf Ladeinfrastruktur",
      "Cluster Auslastung",
      "Cluster Limit",
      "Keine Werte im ausgewählten Zeitraum vorhanden",
      "Leistungsverlauf Standort",
      "Wechsel zur Ansicht pro Phase",
      "Wechsel zur Ansicht der Summe aller Phasen",
      "Standort Auslastung Summe aller Phasen",
      "Standort Limit Summe aller Phasen",
      "Standort Auslastung L1",
      "Standort Auslastung L2",
      "Standort Auslastung L3",
      "Standort Limit"
    ]
  }
]

import classNames from 'classnames';
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { ReactComponent as ChevronLeftIco } from '../../assets/chevron-left.svg';
import { useAuth } from '../../utils/AuthProvider';
import ifTrue from '../../utils/class-name';
import { formatter, parser } from '../../utils/localized-types';
import useBetterTranslate from '../../utils/translation-utils';
import ButtonV2 from '../button';
import Ico from '../ico';
import InputV2, { FormContainerV2 } from '../input';
import Box, { Divider } from '../utils';
import styles from './number-range.module.scss';

export default function NumberRangeInput(props: {
  className?: string;
  placeHolder?: string;
  unit?: string;
  popoverClass?: string;
  from?: number;
  to?: number;
  commitBtnTxt?: string;
  maxFrom?: number;
  maxTo?: number;
  dataCy?: string;
  placeHolderMaxFactionalDigits?: number;
  valueMaxFactionalDigits?: number;
  // validate?:(arg: { from?: nu, to?: string }) => {fromErr?: string, toErr?: string},
  onChanged?: (arg: { from?: number; to?: number }) => void;
}) {
  const { _t } = useBetterTranslate('number-range');
  const onClear = (e: MouseEvent<Element>) => {
    e.stopPropagation();
    e.preventDefault();
    props.onChanged?.({ from: undefined, to: undefined });
  };

  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode || 'en';
  const displayRef = useRef<HTMLDivElement>(null);

  const saveNum = useMemo(() => {
    return (val?: number, factDigits?: number) => {
      const parsed = parser.parseNumber(val as any, 'en');
      if (!Number.isFinite(parsed)) return '';
      const formatted = formatter.formatNumber(parsed, languageCode, 0, factDigits);
      return formatted;
    };
  }, [languageCode]);

  const [from, setFrom] = useState(saveNum(props.from));
  const [to, setTo] = useState(saveNum(props.to));

  const [fromErr, setFromErr] = useState('');
  const [toErr, setToErr] = useState('');

  const [popoverOpen, setPopoverOpen] = useState(false);

  const [placeholder, setPlaceholder] = useState('');

  useEffect(() => {
    if (popoverOpen) {
      setFrom(saveNum(props.from));
      setTo(saveNum(props.to));
    } else {
      setFrom('');
      setTo('');
    }

    let tmp = '';
    const dispFrom = saveNum(props.from, props.placeHolderMaxFactionalDigits);
    const dispTo = saveNum(props.to, props.placeHolderMaxFactionalDigits);

    if (dispFrom && dispTo) {
      const from = `${dispFrom} ${props.unit || ''}`.trim();
      const to = `${dispTo} ${props.unit || ''}`.trim();
      tmp = `${from} - ${to}`.trim();
    } else if (dispFrom) {
      tmp = `${_t('ab')} ${dispFrom} ${props.unit || ''}`.trim();
    } else if (dispTo) {
      tmp = `${_t('bis')} ${dispTo} ${props.unit || ''}`.trim();
    } else {
      tmp = props.placeHolder || '';
    }

    setPlaceholder(tmp);
  }, [popoverOpen, props.from, props.to, props.unit, props.placeHolder, saveNum, props.placeHolderMaxFactionalDigits, _t]);

  const onSubmitClicked = () => {
    let maxFractDigits = 2;
    if (props.valueMaxFactionalDigits !== undefined) {
      maxFractDigits = props.valueMaxFactionalDigits;
    }

    let fromResult = parser.parseNumber(from, languageCode);
    let toResult = parser.parseNumber(to, languageCode);
    if (isFinite(fromResult)) {
      fromResult = Number(fromResult.toFixed(maxFractDigits));
    }
    if (isFinite(toResult)) {
      toResult = Number(toResult.toFixed(maxFractDigits));
    }

    let hasErrors = false;
    setFromErr('');
    setToErr('');
    if (isFinite(fromResult) && isFinite(toResult) && fromResult > toResult) {
      setFromErr(`${_t('ungültiger wertebereich')} ${formatter.formatNumber(fromResult)} - ${formatter.formatNumber(toResult)})`);
      setToErr('');
      hasErrors = true;
    }
    if (from.trim() !== '' && !isFinite(fromResult)) {
      setFromErr(_t('ungültiger wert'));
      hasErrors = true;
    }
    if (to.trim() !== '' && !isFinite(toResult)) {
      setToErr(_t('ungültiger wert'));
      hasErrors = true;
    }
    if (props.maxFrom !== undefined && fromResult > props.maxFrom) {
      setFromErr(`${_t('maximal')} ${formatter.formatNumber(props.maxFrom, languageCode)}`);
      hasErrors = true;
    }
    if (props.maxTo !== undefined && toResult > props.maxTo) {
      setToErr(`${_t('maximal')} ${formatter.formatNumber(props.maxTo, languageCode)}`);
      hasErrors = true;
    }

    if (hasErrors) return;

    props.onChanged?.({
      from: isFinite(fromResult) ? fromResult : undefined,
      to: isFinite(toResult) ? toResult : undefined,
    });

    setPopoverOpen(false);
  };

  return (
    <div className={classNames(styles.root, ifTrue(styles.expanded, popoverOpen))} data-cy={props.dataCy || 'numberRange'} ref={displayRef}>
      <Popover
        isOpen={popoverOpen}
        onClickOutside={() => setPopoverOpen(false)}
        containerStyle={{ zIndex: '1000' }}
        align={'center'}
        parentElement={displayRef.current || undefined}
        positions={['bottom', 'top', 'left', 'right']} // preferred positions by priority
        content={() => {
          return (
            <div className={classNames(styles.inputsWrapper, props.popoverClass)}>
              <Box kind={'vflex'} gap='l'>
                <Box kind={'vflex'} gap='m'>
                  <FormContainerV2 validationErr={fromErr} label={`${_t('From')}:`}>
                    <InputV2 type='text' onChange={setFrom} value={from} placeholder={`-- ${props.unit || ''}`} dataCy='number_range_from' />
                  </FormContainerV2>
                  <FormContainerV2 validationErr={toErr} label={`${_t('To')}:`}>
                    <InputV2 type='text' onChange={setTo} value={to} placeholder={`-- ${props.unit || ''}`} dataCy='number_range_to' />
                  </FormContainerV2>
                </Box>
                <Divider kind='h' />
                <Box kind={'vflex'} gap='m'>
                  <ButtonV2 size='s' block onClick={onSubmitClicked} apperance='primary' dataCy='number_range_submit'>
                    <Box>{props.commitBtnTxt || _t('Apply')}</Box>
                  </ButtonV2>
                  <ButtonV2 size='s' block onClick={onClear} apperance='secondary' dataCy='number_range_reset'>
                    {_t('Reset')}
                  </ButtonV2>
                </Box>
              </Box>
            </div>
          );
        }}
      >
        <div className={classNames(styles.displayWrapper, ifTrue(styles.hasValue, props.from || props.to))}>
          <div
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.code === 'Space' || e.code === 'Enter') {
                setPopoverOpen(!popoverOpen);
              }
            }}
            key={'trigger-bt'}
            onClick={(e) => setPopoverOpen(!popoverOpen)}
            className={styles.display}
          >
            <span>{popoverOpen ? _t('Select') : placeholder}</span>
            <Ico fill='primary-500' size='18px' file={<ChevronLeftIco />} />
          </div>
        </div>
      </Popover>
    </div>
  );
}

import { ReactComponent as ChargerIco } from '../../../assets/charging-station-blue.svg';
import { ReactComponent as CheckIco } from '../../../assets/check.svg';
import { ReactComponent as MagnifyingGlassIco } from '../../../assets/magnifying-glass.svg';
import { ReactComponent as UsersIco } from '../../../assets/users.svg';

import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { ClientChoosePopup, useClientChooser } from '../../../components-v2/business-components/client-choose-popup/client-choose-popup';
import ButtonV2, { ButtonSegment, ButtonSegments, ButtonSwitch } from '../../../components-v2/button';
import DataTableV2, { sortChecker, sortHandler } from '../../../components-v2/data-table/data-table';
import Ico from '../../../components-v2/ico';
import InputV2, { FormContainerV2 } from '../../../components-v2/input';
import { ApiBasedContent, Page, PageContent, PageHeader, PageTitle } from '../../../components-v2/page/page';
import Pagingation from '../../../components-v2/pagination/pagination';
import TabPanel, { Tab, TabContent, Tabs } from '../../../components-v2/tab-panel';
import Box, { Divider } from '../../../components-v2/utils';
import DeletePopup, { DeletePopupRow } from '../../../components/delete-popup';
import EditPoup from '../../../components/edit-popup';
import CheckBox from '../../../components/input-check-box';
import NodeHierarchyLabel from '../../../components/org-hierarchy/node-hierarchy-label';
import { NodeSelectionState, OrgDiagrammNodeProp } from '../../../components/org-hierarchy/org-diagramm-utils';
import { OrgHierarchyDiagrammContext } from '../../../components/org-hierarchy/org-hierarchy-diagramm';
import { useApi } from '../../../hooks/useApi';
import { useBetterNavigate } from '../../../hooks/useBetterNavigate';
import { usePageParams } from '../../../hooks/usePageParams';
import api from '../../../services/api';
import { OrganisationViewDto, OrgStationsListItemDto, Permission } from '../../../services/api-client/csp-api';
import { pagingValidator } from '../../../utils/queryParamValidators';
import useBetterTranslate from '../../../utils/translation-utils';
import uniqueLineIdentifier from '../../../utils/unique-line-ident';
import OrganisationHierarchyView from '../shared/organisation-hierarchy-view';
import styles from './org-stations-list-page.module.scss';

export default function OrgStationsListPage() {
  type FilterParameters = {
    skip?: number | null;
    limit?: number | null;
    search?: string | undefined;
    ac?: string;
    dc?: string;
    acdc?: string;
    clientCode?: string;
    public?: string;
    sortBy?: string;
    sortDesc?: string;
  };
  const { _t } = useBetterTranslate('org-stations-list-page');
  const navigate = useBetterNavigate<FilterParameters>();
  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator });
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };
  const toggleFilter = (key: keyof FilterParameters) => {
    return () => {
      setFilterParams({ [key]: filterParams[key] ? undefined : '1' });
    };
  };

  const setNewFilterParams = (filter: FilterParameters) => {
    // Set all filter params to undefined except passed ones
    let property: keyof typeof filterParams;
    const undefinedFilterParams: FilterParameters = {};
    for (property in filterParams) {
      undefinedFilterParams[property] = undefined;
    }
    _setInnerFilterParams({
      ...undefinedFilterParams,
      ...filter,
    });
  };
  const [showChangeClientPopup, changeClientPopupProps] = useClientChooser();
  const allPermissions = Object.values(Permission).map((k) => k as Permission);
  const MAX_SELECTED_STATIONS = 50;
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const [selectedNodes, setSelectedNodes] = useState<OrgDiagrammNodeProp[]>([]);
  const [showStationsAssignPopup, setShowStationsAssignPopup] = useState(false);
  const [showStationsUnassignPopup, setShowStationsUnassignPopup] = useState(false);
  const [changeParentData, setChangeParentData] = useState<{ ctx: OrgHierarchyDiagrammContext; node: OrgDiagrammNodeProp; others: OrgDiagrammNodeProp[] } | undefined>(undefined);
  const [selectedStations, setSelectedStations] = useState<OrgStationsListItemDto[]>([]);
  const [selectedNodeInPopup, setSelectedNodeInPopup] = useState<OrgDiagrammNodeProp | undefined>(undefined);
  const [submitError, setSubmitError] = useState(filterParams.search);
  const [showRemoveSelectionPopup, setShowRemoveSelectionPopup] = useState(false);
  const [orgView, setOrgView] = useState<OrganisationViewDto>();

  const [stationsResp, stationsFetching, stationsApiErr] = useApi(
    {
      call: async (
        clientCode?: string,
        limit?: number | null,
        skip?: number | null,
        query?: string | null,
        ac?: string,
        dc?: string,
        acdc?: string,
        selectedNodes?: OrgDiagrammNodeProp[],
        sortBy?: any,
        sortDesc?: string,
        selectedStations?: OrgStationsListItemDto[],
        isPublic?: string
      ) => {
        if (!clientCode) return undefined;
        const res = await api.orgStations.list(
          {
            clientCode: clientCode,
            limit: Number(limit || 20),
            skip: Number(skip || 0),
            search: query || undefined,
            ac: ac === '1',
            dc: dc === '1',
            acdc: acdc === '1',
            hierarchyNodeCodes: selectedNodes?.map((n) => n.code) || [],
            sortBy,
            sortDesc: sortDesc === '1',
            chargeBoxIdsToExclude: selectedStations?.map((station) => station.chargeBoxId) || [],
            isPublic: isPublic === '1' ? true : undefined,
          },
          { cancelToken: uniqueLineIdentifier() }
        );
        setOrgView(res.data.organization);
        // setHasExternalHardware(!!res.data.stations.find((s) => s.isExternalHardware));
        // setHasPublicStations(!!res.data.stations.find((s) => s.isPublic));
        return res;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.limit,
    filterParams.skip,
    filterParams.search,
    filterParams.ac,
    filterParams.dc,
    filterParams.acdc,
    selectedNodes,
    filterParams.sortBy,
    filterParams.sortDesc,
    selectedStations,
    filterParams.public
  );
  const hierarchy = [...(stationsResp?.clientContext.accessableNodes || []), ...(stationsResp?.clientContext?.forbiddenParents || [])];
  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const hasParentNodeSwitched = (node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]): boolean => {
    return node.selected === NodeSelectionState.None && others.filter((o) => o.selected === NodeSelectionState.Full && node.parentCode !== o.parentCode).length > 0;
  };

  const haveAllNodesBeenDeselected = (node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]): boolean => {
    return node.selected === NodeSelectionState.Full && others.filter((other) => other.selected === NodeSelectionState.Full).length === 1;
  };

  const deselectNodes = (nodes: OrgDiagrammNodeProp[]) => {
    for (const node of nodes) {
      node.selected = NodeSelectionState.None;
    }
  };

  const resetSelectionStates = (node: OrgDiagrammNodeProp | undefined, others: OrgDiagrammNodeProp[] | undefined) => {
    // The next state updates are not batched by read and lead to two requests to the backend api
    // When this happens, the first request is cancelled automatically by the system, but it would
    // be better if only one request would be triggered.
    // unstable_batch does not have an effect in this case (why?)
    // react upgrade to v18 may fix this
    setChangeParentData(undefined);
    setSearchTerm(undefined);
    setNewFilterParams({});
    setSelectedStations([]);
  };

  const changeParentNodeIfNecessary = (ctx: OrgHierarchyDiagrammContext | undefined, node: OrgDiagrammNodeProp | undefined, others: OrgDiagrammNodeProp[] | undefined) => {
    if (!node || !others || !ctx) {
      return;
    }
    if (hasParentNodeSwitched(node, others) || haveAllNodesBeenDeselected(node, others)) {
      actionsAfterParentNodeChange(ctx, node, others);
    }
  };

  const actionsAfterParentNodeChange = (ctx: OrgHierarchyDiagrammContext, node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => {
    resetSelectionStates(node, others);
    if (node.selected === NodeSelectionState.None) {
      deselectNodes(others);
      setSelectedNodes([node]);
    } else {
      setSelectedNodes([]);
    }
    ctx.refresh();
  };

  const onItemClick = (ctx: OrgHierarchyDiagrammContext, node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => {
    if (hasParentNodeSwitched(node, others) || haveAllNodesBeenDeselected(node, others)) {
      if (selectedStations.length > 0) {
        setChangeParentData({ ctx, node, others });
        return;
      } else {
        changeParentNodeIfNecessary(ctx, node, others);
      }
    }
    if (node.selected === NodeSelectionState.Full) {
      node.selected = NodeSelectionState.None;
    } else {
      node.selected = NodeSelectionState.Full;
    }
    setSelectedNodes(others.filter((n) => n.selected === NodeSelectionState.Full));
    ctx.refresh();
  };

  const onItemClickPopup = (ctx: OrgHierarchyDiagrammContext, node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => {
    if (node.selected === NodeSelectionState.Full) {
      node.selected = NodeSelectionState.None;
    } else {
      others.map((nd) => (nd.selected = NodeSelectionState.None));
      node.selected = NodeSelectionState.Full;
    }
    const selectedNodesInPopup = others.filter((other) => other.selected === NodeSelectionState.Full);
    if (selectedNodesInPopup.length > 1) {
      throw new Error('Too many selected nodes');
    } else if (selectedNodesInPopup.length === 1) {
      setSelectedNodeInPopup(selectedNodesInPopup[0]);
    }
    ctx.refresh();
  };

  const toggleSelectedStation = (stationToToggle: OrgStationsListItemDto): void => {
    const newSelectedStations = selectedStations.map((station) => station.chargeBoxId).includes(stationToToggle.chargeBoxId)
      ? selectedStations.filter((station) => stationToToggle.chargeBoxId !== station.chargeBoxId)
      : [...selectedStations, stationToToggle];
    setSelectedStations(newSelectedStations);
  };

  const compareStationByChargeBoxId = (station1: OrgStationsListItemDto, station2: OrgStationsListItemDto) => {
    return filterParams.sortDesc ? (station1.chargeBoxId < station2.chargeBoxId ? 1 : -1) : station2.chargeBoxId < station1.chargeBoxId ? 1 : -1;
  };

  const compareStationByManufacturer = (station1: OrgStationsListItemDto, station2: OrgStationsListItemDto) => {
    return filterParams.sortDesc ? (station1.manufacturer < station2.manufacturer ? 1 : -1) : station2.manufacturer < station1.manufacturer ? 1 : -1;
  };

  const orderedSelectedStations = filterParams.sortBy === 'manufacturer' ? selectedStations.sort(compareStationByManufacturer) : selectedStations.sort(compareStationByChargeBoxId);

  return (
    <Page className={styles.root}>
      <PageHeader>
        <PageTitle>{_t('Organisation Users')}</PageTitle>
      </PageHeader>

      <PageContent>
        <Box kind={'hflex'} gap='l' align='center'>
          <Box fw='500' fs={'l'}>
            {_t(`Edit Organisation Stations`)}
          </Box>
          <Divider kind='v' />
          <Box>{stationsResp?.organization?.title}</Box>
          <Box kind={'hflex'} justify='flex-end' flexGrow='1'>
            {stationsResp && (
              <ButtonV2
                apperance='primary'
                onClick={async () => {
                  const resp = await showChangeClientPopup({
                    searchClients: (search) => api.profile.accessibleClientNodes({ search: search, permissions: allPermissions }),
                    clientContext: stationsResp.clientContext,
                  });

                  if (resp) {
                    navigate(`/organisation/stations/${resp}`);
                    window.location.reload();
                  }
                }}
              >
                {_t('Switch client organisation')}
              </ButtonV2>
            )}
          </Box>
        </Box>

        <TabPanel>
          <Tabs>
            <Tab fillIco dataCy='tab-structure' txt={_t('Structure')} ico={<CheckIco />} onClick={() => navigate(`/organisation/structure/${stationsResp?.organization?.code}`)} />
            <Tab fillIco dataCy='tab-stations' active={true} txt={_t('Stations')} ico={<ChargerIco />} />
            <Tab strokeIco dataCy='tab-users' txt={_t('Users')} ico={<UsersIco />} onClick={() => navigate(`/organisation/users/${stationsResp?.organization?.code}`)} />
          </Tabs>
          <TabContent active className={styles.tabContent}>
            <Box kind={'vflex'} gap='l' h='100%' className={styles.diagramContainer}>
              <Box>
                <OrganisationHierarchyView
                  className={styles.diagram}
                  classNameDiagramm={styles.diagram}
                  fetching={!orgView}
                  organisation={orgView}
                  nodesSelectable={true}
                  onlyAreasAccessable={true}
                  onItemClick={onItemClick}
                  selectedNodes={selectedNodes.map((n) => n.code)}
                />
              </Box>
              <ApiBasedContent err={stationsApiErr} fetching={stationsFetching} resp={stationsResp}>
                {(stationsResp) => {
                  return (
                    <>
                      <Box>
                        <Box kind={'vflex'} gap='s'>
                          <Divider kind='h' />
                          <Box kind={'hflex'} gap='l' align='center' pad={'400'}>
                            <FormContainerV2>
                              <InputV2
                                placeholder={_t('Search')}
                                icoSuffix={<Ico fill='primary-500' size='16px' file={<MagnifyingGlassIco />} />}
                                value={searchTerm || ''}
                                onChange={setSearchTerm}
                                type='text'
                              />
                            </FormContainerV2>

                            <ButtonSegments size='s'>
                              <ButtonSegment onClick={toggleFilter('ac')} toggled={!!filterParams.ac}>
                                {_t('AC')}
                              </ButtonSegment>
                              <ButtonSegment onClick={toggleFilter('dc')} toggled={!!filterParams.dc}>
                                {_t('DC')}
                              </ButtonSegment>
                              <ButtonSegment onClick={toggleFilter('acdc')} toggled={!!filterParams.acdc}>
                                {_t('AC/DC')}
                              </ButtonSegment>
                            </ButtonSegments>

                            <ButtonSwitch size='s' onClick={toggleFilter('public')} toggled={!!filterParams.public}>
                              {_t('Public Service')}
                            </ButtonSwitch>
                          </Box>

                          <DataTableV2
                            records={stationsResp.stations.filter((station) => !selectedStations.includes(station))}
                            subHeaderClass={styles.subHeader}
                            layout={[
                              {
                                width: '60px',

                                subHeader:
                                  selectedStations.length > 0
                                    ? {
                                        ellipsis: false,
                                        padding: '0',

                                        node: () => {
                                          return (
                                            <Box kind={'vflex'} gap='m' className={styles.selStationsArea} pad={['400', '200', '200', '200']}>
                                              <Box kind='hflex' justify='space-between' w='100%' align='center'>
                                                <Box kind='hflex' align='center' gap='m'>
                                                  <ButtonV2 apperance='secondary' size='s' onClick={() => setShowRemoveSelectionPopup(true)}>
                                                    {_t('Unselect all')}
                                                  </ButtonV2>
                                                  <Divider kind='v' />
                                                  <Box kind='hflex' gap='xxs' align='center'>
                                                    {_t('Stations selected')}:
                                                    <Box fw='700'>
                                                      {selectedStations.length}/{MAX_SELECTED_STATIONS}
                                                    </Box>
                                                  </Box>
                                                </Box>
                                                <Box kind={'hflex'} gap='m'>
                                                  <ButtonV2 apperance='secondary' size='s' onClick={() => setShowStationsUnassignPopup(true)}>
                                                    {_t('Revoke Assignment')}
                                                  </ButtonV2>
                                                  <Divider kind='v' />
                                                  <ButtonV2 apperance='primary' size='s' onClick={() => setShowStationsAssignPopup(true)}>
                                                    {_t('Assign selected stations')}
                                                  </ButtonV2>
                                                </Box>
                                              </Box>

                                              <Box kind={'vflex'} gap='m'>
                                                {orderedSelectedStations.map((record, i) => {
                                                  return (
                                                    <Box key={i} kind={'hgrid'} className={styles.selStationRow} w='100%'>
                                                      <Box pad={'200'} kind={'vflex'} justify='center'>
                                                        <CheckBox key={record.chargeBoxId} label='' isSelected={true} onChange={() => toggleSelectedStation(record)} />
                                                      </Box>

                                                      <Box kind={'vflex'} gap='xxs' data-cy={'evse-cell'} cellSize='1' pad={'200'}>
                                                        <Box fw='700'>{record.chargeBoxId}</Box>
                                                        <Box fw='400'>
                                                          {record.connectorsTotalCount} {record.connectorsTotalCount === 1 ? _t('Ladepunkt') : _t('Ladepunkte')}
                                                          {record.connectorsTotalCount > 0 && ` |  ${record.mergedChargePointType || ''}`}
                                                        </Box>
                                                      </Box>

                                                      <Box kind={'vflex'} gap='xxs' data-cy={'node-cell'} cellSize='1' pad={'200'}>
                                                        <Box fw='700'>
                                                          <NodeHierarchyLabel
                                                            allNodes={hierarchy}
                                                            code={record.nodeCode}
                                                            hideClientRoot={true}
                                                            hideLocation={true}
                                                            hideArea={true}
                                                          />
                                                        </Box>
                                                        <NodeHierarchyLabel allNodes={hierarchy} code={record.nodeCode} hideClientRoot={true} hideRegion={true} />
                                                      </Box>

                                                      <Box kind={'vflex'} gap='xxs' data-cy={'manufacturer-cell'} cellSize='1' pad={'200'}>
                                                        <Box fw='700'>{record.manufacturer}</Box>
                                                        <Box fw='400'>{record.serialNumber}</Box>
                                                      </Box>
                                                    </Box>
                                                  );
                                                })}
                                              </Box>
                                            </Box>
                                          );
                                        },
                                        span: 4,
                                      }
                                    : undefined,
                                header: {
                                  node: () => {
                                    // return <>{_t('Select')}</>;
                                    if (selectedStations.length <= 0) return <></>;
                                    return <Box fs={'s'}></Box>;
                                  },
                                },
                                cell: {
                                  node: (record) => (
                                    <Box kind={'vflex'} justify='center' h='100%'>
                                      <CheckBox
                                        key={record.chargeBoxId}
                                        label=''
                                        disabled={
                                          selectedStations.length >= MAX_SELECTED_STATIONS && !selectedStations.map((station) => station.chargeBoxId).includes(record.chargeBoxId)
                                        }
                                        isSelected={selectedStations.map((station) => station.chargeBoxId).includes(record.chargeBoxId) || false}
                                        onChange={() => toggleSelectedStation(record)}
                                      />
                                    </Box>
                                  ),
                                },
                              },
                              {
                                width: '1fr',
                                header: {
                                  node: () => (
                                    <Box kind={'vflex'} gap='xxs' data-cy={'evse-header'}>
                                      <Box fw='700'>{_t('EVSE-ID')}</Box>
                                      <Box fw='400'>{_t('Charging points')}</Box>
                                    </Box>
                                  ),
                                  // <Box fw='800'>{_t('EVSE-ID')}</Box>,
                                  onSort: sortHandler('chargeBoxId', setFilterParams),
                                  sorting: sortChecker('chargeBoxId', filterParams),
                                },
                                cell: {
                                  node: (record) => (
                                    <Box kind={'vflex'} gap='xxs' data-cy={'evse-cell'}>
                                      <Box fw='700'>{record.chargeBoxId}</Box>
                                      <Box fw='400'>
                                        {record.connectorsTotalCount} {record.connectorsTotalCount === 1 ? _t('Ladepunkt') : _t('Ladepunkte')}
                                        {record.connectorsTotalCount > 0 && ` |  ${record.mergedChargePointType || ''}`}
                                      </Box>
                                    </Box>
                                  ),
                                },
                              },
                              {
                                width: '1fr',
                                header: {
                                  node: () => (
                                    <Box kind={'vflex'} gap='xxs' data-cy={'node-header'}>
                                      <Box fw='700'>{_t('Subsidiary')}</Box>
                                      <Box fw='400'>{_t('Location - Area')}</Box>
                                    </Box>
                                  ),
                                },
                                cell: {
                                  node: (record) => (
                                    <Box kind={'vflex'} gap='xxs' data-cy={'node-cell'}>
                                      <Box fw='700'>
                                        <NodeHierarchyLabel allNodes={hierarchy} code={record.nodeCode} hideClientRoot={true} hideLocation={true} hideArea={true} />
                                      </Box>
                                      <NodeHierarchyLabel allNodes={hierarchy} code={record.nodeCode} hideClientRoot={true} hideRegion={true} />
                                    </Box>
                                  ),
                                },
                              },
                              {
                                width: '1fr',
                                header: {
                                  node: () => (
                                    <Box kind={'vflex'} gap='xxs' data-cy={'manufacturer-header'}>
                                      <Box fw='700'>{_t('Manufacturer model')}</Box>
                                      <Box fw='400'>{_t('Serial number')}</Box>
                                    </Box>
                                  ),
                                  onSort: sortHandler('manufacturer', setFilterParams),
                                  sorting: sortChecker('manufacturer', filterParams),
                                },
                                cell: {
                                  node: (record) => (
                                    <Box kind={'vflex'} gap='xxs' data-cy={'manufacturer-cell'}>
                                      <Box fw='700'>{record.manufacturer}</Box>
                                      <Box fw='400'>{record.serialNumber}</Box>
                                    </Box>
                                  ),
                                },
                              },
                            ]}
                          />
                        </Box>

                        <Pagingation
                          limit={stationsResp.limit}
                          skip={stationsResp.skip}
                          total={stationsResp.total}
                          onChange={(arg) => {
                            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
                          }}
                        />
                      </Box>
                    </>
                  );
                }}
              </ApiBasedContent>
            </Box>
          </TabContent>
        </TabPanel>

        <EditPoup
          open={showStationsAssignPopup}
          bodyClassName={styles.stationsAssignPopup}
          actionsClassName={styles.nodePickerFooter}
          onClose={() => {
            setSelectedNodeInPopup(undefined);
            setShowStationsAssignPopup(false);
            setSubmitError(undefined);
          }}
          onSave={async () => {
            try {
              if (!selectedNodeInPopup) {
                throw new Error('Selected node not found');
              }
              const path = hierarchy.find((n) => n.code === selectedNodeInPopup.code)?.path;
              if (!path) {
                throw new Error('Path not found');
              }
              const res = await api.orgStations.assignStationsToNode({
                clientCode: filterParams.clientCode!,
                chargeBoxIds: selectedStations.map((s) => s.chargeBoxId),
                clientHierarchy: path,
                targetNodeCode: selectedNodeInPopup.code,
                sourceNodeCodes: selectedNodes.map((n) => n.code),
              });
              setOrgView(res.data);
              setSelectedNodeInPopup(undefined);
              setSelectedNodes([]);
              setSelectedStations([]);
              setShowStationsAssignPopup(false);
            } catch (err: any) {
              setSubmitError('Ein unerwarteter Fehler ist aufgetreten');
              throw err;
            }
          }}
          showCancel={true}
          saveText={`(${selectedStations.length}) ${_t('Stationen zuordnen')}`}
          saveButtonDisabled={selectedStations.length <= 0 || !selectedNodeInPopup}
          title={' '}
          additionalFooterContent={submitError}
          additionalFooterContentClassName={styles.submitError}
        >
          <OrganisationHierarchyView
            fetching={!orgView}
            organisation={orgView}
            nodesSelectable={true}
            onlyAreasAccessable={true}
            onItemClick={onItemClickPopup}
            accessableNodes={selectedNodes ? selectedNodes.map((n) => n.parentCode || '') : ['']}
            selectedNodes={selectedNodeInPopup ? [selectedNodeInPopup.code] : []}
          />
        </EditPoup>
        <DeletePopup
          open={showStationsUnassignPopup}
          bodyClassName={styles.stationsAssignPopup}
          actionsClassName={styles.nodePickerFooter}
          onClose={() => {
            setShowStationsUnassignPopup(false);
            setSubmitError(undefined);
          }}
          onDelete={async () => {
            try {
              await api.orgStations.unassignStationsFromNode({
                clientCode: filterParams.clientCode!,
                chargeBoxIds: selectedStations.map((s) => s.chargeBoxId),
                nodeCodes: selectedNodes.map((n) => n.code),
                parentCode: selectedNodes[0]?.parentCode!,
              });
              setShowStationsUnassignPopup(false);
              setSelectedNodes([]);
              setSelectedStations([]);
            } catch (err: any) {
              setSubmitError('Ein unerwarteter Fehler ist aufgetreten');
              throw err;
            }
          }}
          deleteText={_t('Bestätigen')}
          title={_t('Zuordnung aufheben')}
          additionalFooterContent={submitError}
          additionalFooterContentClassName={styles.submitError}
        >
          <DeletePopupRow>
            {_t(`Sind Sie sicher, dass die Zuordnung von {{cpCount}} gewählten Stationen aufgehoben werden soll?`, { cpCount: selectedStations.length })}
          </DeletePopupRow>
          <DeletePopupRow>
            {_t(`Diese werden dem Knoten "Standardbereich" zugewiesen. Stationen, die diesem Knoten bereits zugeordnet waren, werden nicht geändert.`)}
          </DeletePopupRow>
        </DeletePopup>
        <DeletePopup
          open={changeParentData !== undefined || showRemoveSelectionPopup}
          className={styles.popup}
          onDelete={() => {
            resetSelectionStates(changeParentData?.node, changeParentData?.others);
            changeParentNodeIfNecessary(changeParentData?.ctx, changeParentData?.node, changeParentData?.others);
            setShowRemoveSelectionPopup(false);
          }}
          onClose={() => {
            setChangeParentData(undefined);
            setShowRemoveSelectionPopup(false);
          }}
          title={_t('Auswahl verwerfen')}
          deleteText={_t('Bestätigen')}
        >
          <div className={styles.infoText}>{_t(`Sind Sie sicher, dass Sie Ihre Auswahl von {{cpCount}} Stationen verwerfen wollen?`, { cpCount: selectedStations.length })}</div>
        </DeletePopup>
        <ClientChoosePopup {...changeClientPopupProps} />
      </PageContent>
    </Page>
  );
}

import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import styles from './ico.module.scss';
import { Colors, coloToVar } from './utils';

export interface IcoProps {
  className?: string;
  file: ReactNode;
  stroke?: Colors;
  fill?: Colors;
  size?: string;
  rotate?: number;
  testId?: string;
}

export default function Ico(props: IcoProps) {
  let customStyles: CSSProperties = {};

  if (props.fill) {
    customStyles.color = coloToVar(props.fill);
  }
  if (props.stroke) {
    customStyles.color = coloToVar(props.stroke);
  }
  if (props.size) {
    customStyles.minWidth = props.size;
    customStyles.maxWidth = props.size;
    customStyles.minHeight = props.size;
    customStyles.maxHeight = props.size;
  }
  if (props.rotate) {
    customStyles.transform = `rotate(${props.rotate}deg)`;
  }

  return (
    <div data-cy={props.testId} className={classNames(styles.root, props.className, props.stroke ? styles.stroke : '', props.fill ? styles.fill : '')} style={customStyles}>
      {props.file}
    </div>
  );
}

export interface CircleProps {
  className?: string;
  size?: 's' | 'm' | 'l' | string;
  color?: Colors;
  borderColor?: Colors;
}
export function Circle(props: CircleProps) {
  let customStyles: CSSProperties = {};
  if (props.size === 's') {
    customStyles.minWidth = '13px';
    customStyles.maxWidth = '13px';
    customStyles.minHeight = '13px';
    customStyles.maxHeight = '13px';
  } else if (props.size === 'm') {
    customStyles.minWidth = '22px';
    customStyles.maxWidth = '22px';
    customStyles.minHeight = '22px';
    customStyles.maxHeight = '22px';
  } else if (props.size === 'l') {
    customStyles.minWidth = '36px';
    customStyles.maxWidth = '36px';
    customStyles.minHeight = '36px';
    customStyles.maxHeight = '36px';
  } else if (props.size) {
    customStyles.minWidth = props.size;
    customStyles.maxWidth = props.size;
    customStyles.minHeight = props.size;
    customStyles.maxHeight = props.size;
  }

  if (props.borderColor) {
    customStyles.borderColor = coloToVar(props.borderColor);
    customStyles.borderStyle = 'solid';
    customStyles.borderWidth = '1px';
  }
  if (props.color) {
    customStyles.backgroundColor = coloToVar(props.color);
  }

  return <div className={classNames(styles.circle, props.className)} style={customStyles}></div>;
}

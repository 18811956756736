import { useState } from 'react';
import { ReactComponent as BoltIco } from '../../assets/bolt.svg';
import { ReactComponent as CheckIco } from '../../assets/check.svg';

// import { ReactComponent as DotsThreeIco } from '../../assets/dots-three.svg';
import StationStatusSummaryPanel from '../../components-v2/business-components/station-status-summary-panel/station-status-summary-panel';
import TabPanel, { Tab, TabContent, Tabs } from '../../components-v2/tab-panel';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { formatter } from '../../utils/localized-types';
import useBetterTranslate from '../../utils/translation-utils';

import Accordion, { AccordionContent, AccordionExpandBtn, AccordionHead } from '../../components-v2/accordion';
import { STATION_DOMAIN_STATUS_COLORS } from '../../components-v2/business-components/domain-mappings';
import { Page, PageContent, PageHeader, PageTitle } from '../../components-v2/page/page';
import Pill from '../../components-v2/pill';
import { SwitchToggle } from '../../components-v2/switch-toggle';
import Box, { AccordionSkeleton, ChartSkeleton, Divider, StackedSkeleton } from '../../components-v2/utils';
import { NodeHierarchyLabelInverted } from '../../components/org-hierarchy/node-hierarchy-label';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import { ApiBasedContent } from '../../components/page-layout';
import { percentageSave } from '../../utils/math';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import styles from './charging-stations-overview-page.module.scss';
import { ButtonSwitch } from '../../components-v2/button';

interface PanelModelItem {
  nodeCode: string;
  expanded: boolean;
  payload?: {
    nodeCode: string;
    assumedEnergy: number;
    can: {
      goToList: boolean;
    };
    stationsSeries: {
      total: number;
      failures: number;
      offline: number;
      online: number;
      notInOperation: number;
    };
    connectorsSeries: {
      total: number;
      available: number;
      charging: number;
      failure: number;
      occupied: number;
      unavailable: number;
      unknown: number;
    };
  };
}

type FilterParameters = {
  clientCode?: string;
  nodes?: string[];
};

export default function ChargingStationsOverviewPage() {
  const { _t } = useBetterTranslate('charging-stations-overview-page');

  const [panelModels, setPanelModels] = useState<PanelModelItem[]>([]);

  const navigate = useBetterNavigate<FilterParameters>();

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, {});

  const setFilterParams = (filter: FilterParameters) => {
    const { ...params } = filter;
    _setInnerFilterParams({ ...params });
  };

  const fetchPanelModels = async (code: string, areaCodes: string[]) => {
    const promises = areaCodes.map((c) => {
      return api.stationsSummary.getSummary({ client: code, hierarchyNodeCodes: [c] });
    });

    const responses = await Promise.all(promises);
    const results = responses.map((r) => r.data);

    const panels = results.map((r): PanelModelItem => {
      return {
        nodeCode: r.clientContext.selectedNodes[0].code,
        expanded: false,
        payload: {
          nodeCode: r.clientContext.selectedNodes[0].code,
          assumedEnergy: r.summary.stationsAssumedEnergy,
          can: {
            goToList: r.summary.can.goToList,
          },
          connectorsSeries: {
            total:
              r.summary.connectorsAvailable +
              r.summary.connectorsCharging +
              r.summary.connectorsFailure +
              r.summary.connectorsUnavailable +
              r.summary.connectorsUnknown +
              r.summary.connectorsOccupied,
            available: r.summary.connectorsAvailable,
            charging: r.summary.connectorsCharging,
            occupied: r.summary.connectorsOccupied,
            failure: r.summary.connectorsFailure,
            unavailable: r.summary.connectorsUnavailable,
            unknown: r.summary.connectorsUnknown,
          },
          stationsSeries: {
            total: r.summary.stationsFailure + r.summary.stationsOffline + r.summary.stationsOnline + r.summary.stationsNotInOperation,
            failures: r.summary.stationsFailure,
            offline: r.summary.stationsOffline,
            online: r.summary.stationsOnline,
            notInOperation: r.summary.stationsNotInOperation,
          },
        },
      };
    });
    setPanelModels(panels);
  };

  const onToggleExpand = (panelModel: PanelModelItem) => {
    panelModel.expanded = !panelModel.expanded;
    setPanelModels([...panelModels]);
  };

  const onToggleFoldAll = () => {
    const expandAll = panelModels.filter((p) => p.expanded === false).length > 0;
    for (let model of panelModels) {
      model.expanded = expandAll;
    }
    setPanelModels([...panelModels]);
  };

  const navigateToEnergyPage = () => {
    navigate(`/locations/energy/${filterParams.clientCode}`, { nodes: filterParams.nodes });
  };

  const [mainModel, fetchingMainModel, errMainModel] = useApi(
    {
      call: async (clientCode?: string, hierarchyNodeCodes?: string[]) => {
        if (!clientCode) return undefined;
        return await api.stationsSummary.getSummary({
          client: clientCode,
          hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
        });
      },
      map: (data) => {
        if (!data) return data;
        const nodes2Fetch = data.clientContext.selectedHierarchy.filter((c) => c.type === 'area').map((item) => item.code);
        const panelItems = nodes2Fetch.map((item): PanelModelItem => ({ nodeCode: item, expanded: true, payload: undefined }));
        setPanelModels(panelItems);
        fetchPanelModels(data.clientContext.code, nodes2Fetch);
        // fetch sub areas />

        const stationsTotal = data.summary.stationsOnline + data.summary.stationsOffline + data.summary.stationsFailure + data.summary.stationsNotInOperation;
        const connectorsTotal =
          data.summary.connectorsAvailable +
          data.summary.connectorsCharging +
          data.summary.connectorsUnavailable +
          data.summary.connectorsUnknown +
          data.summary.connectorsFailure +
          data.summary.connectorsOccupied;
        const totalEnergyConsumption = data.summary.stationsAssumedEnergy;
        return {
          can: {
            goToList: data.summary.can.goToList,
          },
          clientContext: data.clientContext,
          totalEnergyConsumption: totalEnergyConsumption,
          stationsSeries: {
            online: data.summary.stationsOnline,
            offline: data.summary.stationsOffline,
            failures: data.summary.stationsFailure,
            notInOperation: data.summary.stationsNotInOperation,
            total: stationsTotal,
          },
          connectorsSeries: {
            available: data.summary.connectorsAvailable,
            charging: data.summary.connectorsCharging,
            unavailable: data.summary.connectorsUnavailable,
            unknown: data.summary.connectorsUnknown,
            occupied: data.summary.connectorsOccupied,
            failure: data.summary.connectorsFailure,
            total: connectorsTotal,
          },
        };
      },
    },

    filterParams.clientCode,
    filterParams.nodes
  );

  const navigateToStationListPage = (filter: { online?: number; offline?: number; failure?: number; notInOperation?: number; nodes?: string[] }) => {
    const link = `/infrastructure/stations/${filterParams.clientCode}`;
    navigate(link, filter);
  };
  const navigateToChargepointListPage = (filter: {
    available?: number;
    charging?: number;
    occupied?: number;
    failure?: number;
    unavailable?: number;
    unknown?: number;
    nodes?: string[];
  }) => {
    const link = `/infrastructure/chargepoints/${filterParams.clientCode}`;
    navigate(link, filter);
  };

  const anyPanelCollapsed = panelModels.filter((p) => p.expanded === false).length > 0;

  return (
    <Page className={styles.root}>
      <PageHeader>
        <PageTitle>{_t('Location')}</PageTitle>
      </PageHeader>
      <PageContent>
        <OrgHierarchyPicker
          selectMode='node'
          clientContext={mainModel?.clientContext}
          onNodeSelected={(clientCode, selectedCodes) => {
            if (clientCode !== mainModel?.clientContext?.code) {
              navigate(`/locations/status/${clientCode}`, { nodes: selectedCodes });
              window.location.reload();
            } else {
              setFilterParams({ nodes: selectedCodes });
            }
          }}
          {...createClientSearchProps(mainModel?.clientContext)}
          selectedNodes={filterParams.nodes}
        />

        <Box kind='vflex' gap='l'>
          <TabPanel>
            <Tabs>
              <Tab dataCy='status_tab' strokeIco active={true} txt={_t('Status')} ico={<CheckIco />}></Tab>
              <Tab dataCy='energy_tab' fillIco txt={_t('Energie')} ico={<BoltIco />} onClick={navigateToEnergyPage}></Tab>
            </Tabs>
            <TabContent active={true}>
              <ApiBasedContent
                resp={mainModel}
                err={errMainModel}
                fetching={fetchingMainModel}
                placeholder={() => (
                  <Box kind='hflex' gap='m'>
                    <ChartSkeleton />
                    <ChartSkeleton />
                  </Box>
                )}
              >
                {(mainModel) => {
                  return (
                    <Box kind='vflex' gap='m'>
                      <StationStatusSummaryPanel
                        connectors={{
                          overallTotal: mainModel.connectorsSeries.total,
                          total: mainModel.connectorsSeries.total,
                          available: mainModel.connectorsSeries.available,
                          charging: mainModel.connectorsSeries.charging,
                          occupied: mainModel.connectorsSeries.occupied,
                          failure: mainModel.connectorsSeries.failure,
                          unavailable: mainModel.connectorsSeries.unavailable,
                          unknown: mainModel.connectorsSeries.unknown,

                          onAvailableClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ available: 1, nodes: filterParams.nodes }) : undefined,
                          onChargingClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ charging: 1, nodes: filterParams.nodes }) : undefined,
                          onFailureClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ failure: 1, nodes: filterParams.nodes }) : undefined,
                          onOccupiedClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ occupied: 1, nodes: filterParams.nodes }) : undefined,
                          onUnavailableClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ unavailable: 1, nodes: filterParams.nodes }) : undefined,
                          onUnknownClicked: mainModel.can.goToList ? () => navigateToChargepointListPage({ unknown: 1, nodes: filterParams.nodes }) : undefined,
                        }}
                        stations={{
                          overallTotal: mainModel.stationsSeries.total,
                          total: mainModel.stationsSeries.total,
                          failures: mainModel.stationsSeries.failures,
                          offline: mainModel.stationsSeries.offline,
                          online: mainModel.stationsSeries.online,
                          notInOperation: mainModel.stationsSeries.notInOperation,

                          onOnlineClicked: mainModel.can.goToList ? () => navigateToStationListPage({ online: 1 }) : undefined,
                          onOfflineClicked: mainModel.can.goToList ? () => navigateToStationListPage({ offline: 1 }) : undefined,
                          onFailureClicked: mainModel.can.goToList ? () => navigateToStationListPage({ failure: 1 }) : undefined,
                          onNotInOperationClicked: mainModel.can.goToList ? () => navigateToStationListPage({ notInOperation: 1 }) : undefined,
                        }}
                      />
                      <div className={styles.totalEnergyConsumption}>
                        {_t('Aktuelle Ladeleistung')}: {formatter.formatNumber(Math.round(mainModel.totalEnergyConsumption))} kW
                      </div>
                    </Box>
                  );
                }}
              </ApiBasedContent>
            </TabContent>

            <TabContent>...</TabContent>
          </TabPanel>

          <Divider kind='h' />

          <Box kind='hflex' justify='flex-end'>
            <ButtonSwitch apperance='secondary' toggled={!anyPanelCollapsed} onClick={onToggleFoldAll}>
              {anyPanelCollapsed ? _t('Alle Bereiche öffnen') : _t('Alle Bereiche schließen')}
            </ButtonSwitch>
          </Box>

          <ApiBasedContent resp={mainModel} err={errMainModel} fetching={fetchingMainModel} placeholder={() => <StackedSkeleton skeleton={() => <AccordionSkeleton />} />}>
            {(mainModel) => {
              return (
                <Box kind='vflex' gap='l'>
                  {panelModels.map((panelModel, idx) => {
                    return (
                      <ApiBasedContent resp={panelModel.payload} key={idx} fetching={!panelModel.payload} placeholder={() => <AccordionSkeleton />}>
                        {(payload) => {
                          const stationSeries = payload.stationsSeries;
                          const nodeCode = panelModel.nodeCode;
                          return (
                            <Accordion collapsed={!panelModel.expanded}>
                              <AccordionHead>
                                <Box kind='hgrid' gap='s' flexGrow='1' align='center'>
                                  <Box cellSize='3' kind='hflex' align='center'>
                                    <NodeHierarchyLabelInverted
                                      hideArea={false}
                                      hideClientRoot={true}
                                      allNodes={[...mainModel.clientContext.accessableNodes, ...mainModel.clientContext.forbiddenParents]}
                                      code={payload.nodeCode}
                                    />
                                  </Box>
                                  <Box kind='hgrid' cellSize='3' fs='s'>
                                    <Box kind='vflex'>
                                      <Pill apperance={STATION_DOMAIN_STATUS_COLORS.online} />
                                      <span>
                                        {stationSeries.online} ({percentageSave(stationSeries.online, stationSeries.total).toFixed(0)}%)
                                      </span>
                                    </Box>
                                    <Box kind='vflex'>
                                      <Pill apperance={STATION_DOMAIN_STATUS_COLORS.offline} />
                                      <span>
                                        {stationSeries.offline} ({percentageSave(stationSeries.offline, stationSeries.total).toFixed(0)}%)
                                      </span>
                                    </Box>

                                    <Box kind='vflex'>
                                      <Pill apperance={STATION_DOMAIN_STATUS_COLORS.failure} />
                                      <span>
                                        {stationSeries.failures} ({percentageSave(stationSeries.failures, stationSeries.total).toFixed(0)}%)
                                      </span>
                                    </Box>

                                    <Box kind='vflex'>
                                      <Pill apperance={STATION_DOMAIN_STATUS_COLORS.notInOperation} />
                                      <span>
                                        {stationSeries.notInOperation} ({percentageSave(stationSeries.notInOperation, stationSeries.total).toFixed(0)}%)
                                      </span>
                                    </Box>
                                  </Box>
                                  <Box cellSize='2' kind='hflex' h='100%' align='center'>
                                    <Divider kind='v' />
                                    <Box pad={['0', '0', '0', '400']}>
                                      {_t('Aktuelle Ladeleistung')} {formatter.formatNumber(Math.round(payload.assumedEnergy))} kW
                                    </Box>
                                  </Box>
                                </Box>

                                {/* <Divider kind='v' />
                              <ButtonV2 className={classNames(styles.contextActionsBtn, styles.panelBtn)} apperance='icon'>
                                <DotsThreeIco />
                              </ButtonV2>
                               */}
                                <Divider kind='v' />
                                <AccordionExpandBtn className={styles.panelBtn} onClick={() => onToggleExpand(panelModel)} />
                              </AccordionHead>

                              <AccordionContent>
                                <StationStatusSummaryPanel
                                  connectors={{
                                    overallTotal: mainModel.connectorsSeries.total,
                                    total: payload.connectorsSeries.total,
                                    available: payload.connectorsSeries.available,
                                    charging: payload.connectorsSeries.charging,
                                    occupied: payload.connectorsSeries.occupied,
                                    failure: payload.connectorsSeries.failure,
                                    unavailable: payload.connectorsSeries.unavailable,
                                    unknown: payload.connectorsSeries.unknown,

                                    onAvailableClicked: payload.can.goToList ? () => navigateToChargepointListPage({ available: 1, nodes: [nodeCode] }) : undefined,
                                    onChargingClicked: payload.can.goToList ? () => navigateToChargepointListPage({ charging: 1, nodes: [nodeCode] }) : undefined,
                                    onFailureClicked: payload.can.goToList ? () => navigateToChargepointListPage({ failure: 1, nodes: [nodeCode] }) : undefined,
                                    onOccupiedClicked: payload.can.goToList ? () => navigateToChargepointListPage({ occupied: 1, nodes: [nodeCode] }) : undefined,
                                    onUnavailableClicked: payload.can.goToList ? () => navigateToChargepointListPage({ unavailable: 1, nodes: [nodeCode] }) : undefined,
                                    onUnknownClicked: payload.can.goToList ? () => navigateToChargepointListPage({ unknown: 1, nodes: [nodeCode] }) : undefined,
                                  }}
                                  stations={{
                                    overallTotal: mainModel.stationsSeries.total,
                                    total: payload.stationsSeries.total,
                                    failures: payload.stationsSeries.failures,
                                    offline: payload.stationsSeries.offline,
                                    online: payload.stationsSeries.online,
                                    notInOperation: payload.stationsSeries.notInOperation,

                                    onOnlineClicked: payload.can.goToList ? () => navigateToStationListPage({ online: 1, nodes: [nodeCode] }) : undefined,
                                    onOfflineClicked: payload.can.goToList ? () => navigateToStationListPage({ offline: 1, nodes: [nodeCode] }) : undefined,
                                    onFailureClicked: payload.can.goToList ? () => navigateToStationListPage({ failure: 1, nodes: [nodeCode] }) : undefined,
                                    onNotInOperationClicked: payload.can.goToList ? () => navigateToStationListPage({ notInOperation: 1, nodes: [nodeCode] }) : undefined,
                                  }}
                                />
                              </AccordionContent>
                            </Accordion>
                          );
                        }}
                      </ApiBasedContent>
                    );
                  })}
                </Box>
              );
            }}
          </ApiBasedContent>
        </Box>
      </PageContent>
    </Page>
  );
}

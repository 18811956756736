import { useState } from 'react';
import { ReactComponent as ChargerIco } from '../../../../assets/charging-station-blue.svg';
import { ReactComponent as CheckIco } from '../../../../assets/check.svg';
import { ReactComponent as UsersIco } from '../../../../assets/users.svg';
import { ApiBasedContent, Page, PageContent, PageHeader, PageTitle } from '../../../../components-v2/page/page';
import Box, { Divider } from '../../../../components-v2/utils';
import { useApi } from '../../../../hooks/useApi';
import { useBetterNavigate } from '../../../../hooks/useBetterNavigate';
import { usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { ClientStructureDto, Permission } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import OrganisationHierarchyView from '../../shared/organisation-hierarchy-view';
import { NodeInfo, StructureEditHierarchyPanel } from '../structure-edit-hierarchy-popup/structure-edit-hierarchy-popup';
import styles from './structure-overview-page.module.scss';

import { ClientChoosePopup, useClientChooser } from '../../../../components-v2/business-components/client-choose-popup/client-choose-popup';
import ButtonV2 from '../../../../components-v2/button';
import TabPanel, { Tab, TabContent, Tabs } from '../../../../components-v2/tab-panel';

export interface StructureOverviewPageProps {
  className?: string;
}

type FilterParameters = {
  clientCode?: string;
};
export default function StructureOverviewPage(props: StructureOverviewPageProps) {
  const { _t } = useBetterTranslate('structure-overview-page');
  const allPermissions = Object.values(Permission).map((k) => k as Permission);
  const navigate = useBetterNavigate<FilterParameters>();
  const [filterParams] = usePageParams<FilterParameters>({}, {});

  const [editOrg, setEditOrg] = useState<ClientStructureDto>();
  // const []

  const [showChangeClientPopup, changeClientPopupProps] = useClientChooser();

  const [orgRes, orgFetching, orgErr, refresh] = useApi(
    {
      call: async (clientCode?: string) => {
        if (!clientCode) return undefined;
        const res = await api.orgStructure.getStructure(clientCode);
        return res;
      },
      map: (data) => {
        if (!data) return undefined;
        return data;
      },
    },
    filterParams.clientCode
  );

  const onEditClick = async () => {
    if (!filterParams.clientCode) return;
    const resp = await api.orgStructure.getClientStructure(filterParams.clientCode);
    setEditOrg(resp.data);
  };

  const onQuitEditClick = async () => {
    setEditOrg(undefined);
    refresh();
  };

  return (
    <Page className={styles.root}>
      <PageHeader>
        <PageTitle>{_t('Organisation')}</PageTitle>
      </PageHeader>
      <PageContent>
        <Box kind={'hflex'} gap='l' align='center'>
          <Box fw='500' fs={'l'}>
            {_t(`Edit client organisation`)}
          </Box>
          <Divider kind='v' />
          <Box>{orgRes?.organization?.title}</Box>
          <Box kind={'hflex'} justify='flex-end' flexGrow='1'>
            {orgRes && (
              <ButtonV2
                disabled={!!editOrg}
                apperance='primary'
                onClick={async () => {
                  const resp = await showChangeClientPopup({
                    searchClients: (search) => api.profile.accessibleClientNodes({ search: search, permissions: allPermissions }),
                    clientContext: orgRes.clientContext,
                  });

                  if (resp) navigate(`/organisation/structure/${resp}`);
                }}
              >
                {_t('Switch client organisation')}
              </ButtonV2>
            )}
          </Box>
        </Box>
        <>
          <TabPanel>
            <Tabs>
              <Tab fillIco dataCy='tab-structure' active={true} txt={_t('Structure')} ico={<CheckIco />}></Tab>
              <Tab fillIco dataCy='tab-stations' txt={_t('Stations')} ico={<ChargerIco />} onClick={() => navigate(`/organisation/stations/${orgRes?.organization?.code}`)} />
              <Tab strokeIco dataCy='tab-users' txt={_t('Users')} ico={<UsersIco />} onClick={() => navigate(`/organisation/users/${orgRes?.organization?.code}`)} />
            </Tabs>
            <TabContent active>
              <Box kind={'vflex'} gap='l' h='100%'>
                <ApiBasedContent err={orgErr} fetching={orgFetching} resp={orgRes}>
                  {(orgRes) => {
                    return (
                      <>
                        {orgRes.canManageArea && !editOrg && (
                          <Box kind={'hflex'} justify='flex-end'>
                            <ButtonV2 onClick={onEditClick} apperance='secondary'>
                              {_t('Edit organisation chart')}
                            </ButtonV2>
                          </Box>
                        )}
                        {orgRes.canManageArea && editOrg && (
                          <Box kind={'hflex'} justify='flex-end'>
                            <ButtonV2 onClick={onQuitEditClick} apperance='secondary'>
                              {_t('Quit editing mode')}
                            </ButtonV2>
                          </Box>
                        )}

                        {orgRes.canManageArea && editOrg && (
                          <StructureEditHierarchyPanel
                            org={editOrg}
                            onAddNode={async (node: NodeInfo) => {
                              if (!filterParams.clientCode || !node.parentCode) return;
                              const res = await api.orgStructure.createNode({
                                clientCode: filterParams.clientCode,
                                parentCode: node.parentCode,
                                title: node.title,
                                type: node.type,
                                cpmsId: node.cpmsId || '',
                              });
                              setEditOrg(res.data);
                            }}
                            onEditNode={async (node: NodeInfo) => {
                              if (!filterParams.clientCode || !node.code) return;
                              const res = await api.orgStructure.updateNode({ clientCode: filterParams.clientCode, code: node.code, title: node.title, cpmsId: node.cpmsId || '' });
                              setEditOrg(res.data);
                            }}
                            onDeleteNode={async (node: NodeInfo) => {
                              if (!filterParams.clientCode || !node.code) return;
                              const res = await api.orgStructure.deleteNode({ clientCode: filterParams.clientCode, code: node.code });
                              setEditOrg(res.data);
                            }}
                            getUsersCount={async (node: NodeInfo) => {
                              if (!filterParams.clientCode || !node?.code) return 0;
                              const res = await api.orgStructure.getNodeUsersCount(filterParams.clientCode, node.code);
                              return res.data.count;
                            }}
                          />
                        )}

                        {!editOrg && (
                          <OrganisationHierarchyView fetching={orgFetching} organisation={orgRes?.organization} className={styles.diagram} classNameDiagramm={styles.diagram} />
                        )}
                      </>
                    );
                  }}
                </ApiBasedContent>
              </Box>
            </TabContent>
          </TabPanel>
        </>

        {/* {orgRes && <OrgTabPanel organisation={orgRes?.organization} selectedTab='structure' />} */}
        {/* {orgRes && orgRes.canManageArea && (
          <ButtonPrimary ralign={true} onClick={onEditClick}>
            {_t('Bearbeiten')}
          </ButtonPrimary>
        )} */}
        {/* <OrganisationHierarchyView fetching={orgFetching} organisation={orgRes?.organization} /> */}

        <ClientChoosePopup {...changeClientPopupProps} />
      </PageContent>
    </Page>
  );
}

import classNames from 'classnames';
import { ReactComponent as ChevronLeftIco } from '../../assets/chevron-left.svg';
import { ReactComponent as ChevronRightIco } from '../../assets/chevron-right.svg';
import ifTrue from '../../utils/class-name';
import useBetterTranslate from '../../utils/translation-utils';
import ButtonV2 from '../button';
import Ico from '../ico';
import Box from '../utils';
import styles from './pagination.module.scss';

export default function Pagingation(props: {
  limits?: [];
  className?: string;
  skip: number;
  total: number;
  limit: number;
  onChange: (args: { skip: number; limit: number }) => void;
}) {
  const { _t } = useBetterTranslate('pagination');
  let pages = Math.ceil(props.total / props.limit);
  const SIZES = props.limits || [10, 20, 50, 100];
  const actualSkip = props.skip;
  const currentPageIdx = actualSkip / props.limit;
  const PAGES_LEFT = 2;
  const PAGES_RIGHT = 3; // includes the current page
  const pageArray: { num: number; lbl: string; cls: string }[] = [];

  for (let idx = 0; idx < PAGES_RIGHT; idx++) {
    const pageNum = idx + 1 + currentPageIdx;
    if (pageNum > pages) continue;
    pageArray.push({ num: pageNum, lbl: pageNum.toFixed(0), cls: classNames(styles.pageBtn, ifTrue(styles.active, currentPageIdx === pageNum - 1)) });
  }

  for (let idx = 0; idx < PAGES_LEFT; idx++) {
    const pageNum = currentPageIdx - idx;
    if (pageNum <= 0) continue;
    pageArray.unshift({ num: pageNum, lbl: pageNum.toFixed(0), cls: classNames(styles.pageBtn, ifTrue(styles.active, currentPageIdx === pageNum - 1)) });
  }

  if (pageArray.findIndex((p) => p.num === pages) < 0 && currentPageIdx < pages) {
    if (pageArray.findIndex((p) => p.num === pages - 1) < 0 && currentPageIdx < pages - 1) {
      pageArray.push({ num: -1, lbl: '...', cls: classNames(styles.pageBtn, styles.ellipsis) });
    }

    pageArray.push({ num: pages, lbl: pages.toFixed(0), cls: classNames(styles.pageBtn) });
  }
  if (pageArray.findIndex((p) => p.num === 1) < 0 && currentPageIdx > 0) {
    if (pageArray.findIndex((p) => p.num === 2) < 0 && currentPageIdx > 0) {
      pageArray.unshift({ num: -1, lbl: '...', cls: classNames(styles.pageBtn, styles.ellipsis) });
    }

    pageArray.unshift({ num: 1, lbl: (1).toFixed(0), cls: classNames(styles.pageBtn) });
  }

  let fromRecord = props.limit * currentPageIdx + 1;
  let toRecord = props.limit * currentPageIdx + props.limit;
  if (toRecord > props.total) {
    toRecord = props.total;
  }

  const onNavigate = (arg: { skip?: number; limit?: number }) => {
    const skip = arg.skip === undefined ? 0 : arg.skip;
    const limit = arg.limit || props.limit;
    props.onChange({ skip, limit });
  };

  return (
    <Box pad={['600', '400', '0', '400']} data-cy={'paging'} kind={'hflex'} fs={'s'} className={styles.pagingation}>
      <Box>
        <Box kind={'hflex'} gap='s' align='center'>
          <Box>{_t('Go directly to page')}</Box>
          <select
            value={currentPageIdx + 1}
            onChange={(el) => {
              let val = parseInt(el.target.value);
              if (!Number.isInteger(val)) val = 1;
              onNavigate({ skip: (val - 1) * props.limit });
            }}
          >
            {Array.from({ length: pages }).map((_, idx) => {
              return (
                <option value={idx + 1} key={idx}>
                  {idx + 1}
                </option>
              );
            })}
          </select>
        </Box>
      </Box>
      <Box flexGrow='1' kind={'hflex'} align='flex-start' justify='center' gap='m'>
        <ButtonV2
          apperance='icon'
          type='button'
          disabled={currentPageIdx <= 0}
          data-cy={'btnPreviousPage'}
          onClick={() => onNavigate({ skip: (currentPageIdx - 1) * props.limit })}
        >
          <Box kind={'hflex'} align='center' gap='xs' fg={currentPageIdx <= 0 ? 'primary-neutral-080' : 'primary-neutral-100'}>
            <Ico file={<ChevronLeftIco />} size='18px' fill='primary-500' />
            <Box>{_t('Previous')}</Box>
          </Box>
        </ButtonV2>
        <Box fs={'xs'} lh='s' kind={'hflex'} gap='m'>
          {pageArray.map((p, idx) => {
            return (
              <ButtonV2
                apperance='none'
                type='button'
                key={`p_${idx}`}
                onClick={() => {
                  if (p.num >= 0) onNavigate({ skip: (p.num - 1) * props.limit });
                }}
                className={classNames(p.cls)}
              >
                {p.lbl}
              </ButtonV2>
            );
          })}
        </Box>

        <ButtonV2
          apperance='icon'
          type='button'
          disabled={currentPageIdx + 1 >= pages}
          onClick={() => onNavigate({ skip: (currentPageIdx + 1) * props.limit })}
          data-cy={'btnNextPage'}
        >
          <Box kind={'hflex'} align='center' gap='xs' fg={currentPageIdx + 1 >= pages ? 'primary-neutral-080' : 'primary-neutral-100'}>
            <Box>{_t('Next')}</Box>
            <Ico file={<ChevronRightIco />} size='18px' fill='primary-500' />
          </Box>
        </ButtonV2>
      </Box>
      <Box kind={'vflex'} align='flex-start' gap='s'>
        <Box kind={'hflex'} gap='s' align='center'>
          <Box>{_t('Lines per page')}</Box>
          <select
            onChange={(el) => {
              let val = parseInt(el.target.value);
              if (!Number.isInteger(val)) {
                val = 10;
              }
              onNavigate({ limit: val });
            }}
            value={SIZES.find((s) => s === props.limit) || SIZES[1]}
            data-cy={'ddlLinesPerPage'}
          >
            {SIZES.map((s) => {
              return <option key={s}>{s}</option>;
            })}
          </select>
        </Box>
        <Box kind={'hflex'} w='100%' justify='flex-end' fs={'s'}>
          {fromRecord} - {toRecord} {_t('of')} {props.total}
        </Box>
      </Box>
    </Box>
  );
}

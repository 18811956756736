import { useEffect, useMemo } from 'react';
import { FormCard } from '../../../components/form-card';
import { mapItems, OrgDiagrammNodeProp, setSelectionStates } from '../../../components/org-hierarchy/org-diagramm-utils';
import OrgHierarchyDiagramm, { OrgHierarchyDiagrammContext, OrgHierarchyDiagrammSettings, useOrgHiearchyDiagramm } from '../../../components/org-hierarchy/org-hierarchy-diagramm';
import styles from './organisation-hierarchy-view.module.scss';
import { ClientHierarchyNodeTypeDto, OrganisationViewDto } from '../../../services/api-client/csp-api';
import classNames from 'classnames';

export default function OrganisationHierarchyView(props: {
  fetching: boolean;
  className?: string;
  classNameDiagramm?: string;
  organisation?: OrganisationViewDto;
  nodesSelectable?: boolean;
  rootNodeSelectable?: boolean;
  onlyAreasAccessable?: boolean;
  onItemClick?: (ctx: OrgHierarchyDiagrammContext, node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => void;
  selectedNodes?: string[];
  accessableNodes?: string[];
  doNotPropagate?: boolean;
}) {
  const diagProps = useMemo(() => {
    const settings: OrgHierarchyDiagrammSettings = {
      diagramProps: {
        readonly: true,
        className: classNames(styles.diagramm, props.classNameDiagramm),
        nodesSelectable: props.nodesSelectable || false,
      },

      onItemClick: props.onItemClick,
    };

    return settings;
  }, [props.nodesSelectable, props.onItemClick, props.classNameDiagramm]);

  let [diagrammProps, diagContext] = useOrgHiearchyDiagramm(diagProps);

  useEffect(() => {
    if (props.fetching) return;
    // need to do it delayed, databinding need to run first
    diagContext?.fitToView();
  }, [props.fetching, diagContext]);

  useEffect(() => {
    if (!props.organisation) return;

    const accessable = mapItems(props.organisation.accessableNodes, props.organisation.code, true);
    const parents = mapItems(props.organisation.forbiddenParents, props.organisation.code, false);
    if (props.selectedNodes && props.selectedNodes.length > 0) {
      setSelectionStates(
        props.selectedNodes,
        props.onlyAreasAccessable ? accessable.filter((nd) => nd.type === ClientHierarchyNodeTypeDto.Area) : accessable,
        false,
        props.doNotPropagate || false
      );
    }
    if (props.accessableNodes && props.accessableNodes.length > 0) {
      for (const node of accessable) {
        let accessible = false;
        if (node.parentCode) {
          if (props.accessableNodes?.includes(node.parentCode)) {
            accessible = true;
          }
        } else {
          if (props.rootNodeSelectable) {
            accessible = true;
          }
        }
        node.accessible = accessible;
      }
    }
    if (props.onlyAreasAccessable) {
      for (const node of accessable) {
        node.accessible = node.type === ClientHierarchyNodeTypeDto.Area ? node.accessible : false;
      }
    }
    diagContext.setItems([...accessable, ...parents]);
  }, [props.organisation, diagContext, props.onlyAreasAccessable, props.selectedNodes, props.accessableNodes, props.doNotPropagate, props.rootNodeSelectable]);

  return (
    <>
      {/* {orgRes && */}
      <FormCard tabletSize='full' className={props.className}>
        <OrgHierarchyDiagramm {...diagrammProps} />
      </FormCard>
    </>
  );
}

import classNames from 'classnames';
import { PropsWithChildren, useState } from 'react';
import { useToggle } from 'react-use';
import { ReactComponent as ChevronIco } from '../../../assets/chevron-left.svg';
import { ReactComponent as ThreeDotsIco } from '../../../assets/dots-three.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { ReactComponent as ExternalHardwareIco } from '../../../assets/exclamation.svg';
import { ReactComponent as PublicStationsIco } from '../../../assets/public-stations.svg';
import { NodeHierarchyLabelInverted } from '../../../components/org-hierarchy/node-hierarchy-label';
import NodeLabel from '../../../components/org-hierarchy/node-label';
import { ApiBasedContent } from '../../../components/page-layout';
import { useBetterNavigate } from '../../../hooks/useBetterNavigate';
import { ChargePoint } from '../../../pages/stations/chargepoint-remote-actions';
import { ChargeBoxDomainStatus, ChargePointsOfStationResultDto, ExtensionStation, SharedClientHierarchyNodeDto } from '../../../services/api-client/csp-api';
import useBetterTranslate from '../../../utils/translation-utils';
import useEffectAsync from '../../../utils/useEffectAsync';
import ButtonV2 from '../../button';
import Ico, { Circle } from '../../ico';
import Box, { Colors, Divider, HugeListItemSkeleton, StackedSkeleton, TableRowSkeleton } from '../../utils';
import VerticalList from '../../vertical-list';
import ChargepointListItem, { ChargepointListItemModel } from '../chargepoint-list-item/chargepoint-list-item';
import { STATION_DOMAIN_STATUS_COLORS } from '../domain-mappings';
import styles from './station-list-item.module.scss';

export interface StationListItemModel {
  chargeBoxId: string;
  customName?: string;
  domainStatus?: ChargeBoxDomainStatus;
  connectorsAvailableCount: number;
  connectorsChargingCount: number;
  connectorsFailureCount: number;
  connectorsNotAvailableCount: number;
  connectorsOccupiedCount: number;
  connectorsUnknownCount: number;
  connectorsTotalCount: number;
  nodeCode: string;
  manufacturer: string;
  model: string;
  serialNumber: string;
  clusterDisplayName?: string;
  mergedChargePointType?: string;
  isExternalHardware: boolean;
  isPublic: boolean;
  can: {
    remoteReset: boolean;
    remoteChangeAvailability: boolean;
    goToSessions: boolean;
  };
  loadMgmtOfClusterActive?: boolean;
  loadManagementActive?: boolean;
  clusterId?: string;
  // connectors?: {
  //   domainStatus?: string;
  //   outletType?: string;
  // }[];
}

export interface StationListItemProps {
  className?: string;
  item: StationListItemModel;
  allNodes: SharedClientHierarchyNodeDto[];
  sessionsLink: string;
  chargepointsPath: string;
  clientCode?: string;
  loadManagementLinkActive?: boolean;
  onClickStationRemoteActionButton: (ev: React.MouseEvent<Element, MouseEvent>) => void;
  onClickChargePointRemoteActionButton: (ev: React.MouseEvent<Element, MouseEvent>, cp: ChargePoint) => void;
  hasStationContextItems: boolean;
  hasChargePointContextItems: (cp: ChargePoint) => boolean;
  fetchStationDetails: () => Promise<ChargePointsOfStationResultDto>;
  fetchStationGroupExtensions: () => Promise<ExtensionStation[]>;
  onClickChangeCustomNameStation: () => void;
  onClickChangeCustomNameStationGroupExtension: (chargeBoxId: string, customName: string) => void;
  onClickChangeCustomNameChargePoint: (chargePoint: ChargepointListItemModel) => void;
  hasChangeCustomNamePopup?: boolean;
  groupId?: string;
}
export interface StationDetailsViewModel {
  chargePoints: ChargepointListItemModel[];
}

export default function StationListItem(props: StationListItemProps) {
  const domainStatus = props.item.domainStatus || ChargeBoxDomainStatus.NotInOperation;
  const station = props.item;
  const navigate = useBetterNavigate();
  const { _t } = useBetterTranslate('station-list-item');
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [stationDetails, setStationDetails] = useState<StationDetailsViewModel>();
  const [stationGroupExtensions, setStationGroupExtensions] = useState<ExtensionStation[]>();

  const fetchStationDetails = props.fetchStationDetails;
  useEffectAsync(async () => {
    if (!showDetails) return;
    if (stationDetails) return;

    const res = await fetchStationDetails();
    setStationDetails({ chargePoints: res.chargePoints });
  }, [station, showDetails, stationDetails]);

  const fetchStationGroupExtensions = props.fetchStationGroupExtensions;
  useEffectAsync(async () => {
    if (!groupId) return;
    if (!!stationGroupExtensions) return;

    const res = await fetchStationGroupExtensions();
    setStationGroupExtensions(res);
  }, [station, stationGroupExtensions]);

  const loadMgmgOfClusterAndStationActive = station.loadMgmtOfClusterActive && station.loadManagementActive;
  // const navigateToClusterDetailStationsPage = (clusterId: string) => {
  //   const link = `/loadmanagement/${props.clientCode}/cluster-stations/${clusterId}`;
  //   navigate(link);
  // };

  const navigateToChargePointsTab = (chargeBoxId: string) => {
    navigate(props.chargepointsPath, { search: chargeBoxId });
  };

  // const chargepoints = stationDetails?.chargePoints || [];
  // _.groupBy(connectors, c => c.domainStatus)

  let groupId = props.groupId;
  return (
    <Box kind={'vflex'} gap='s' className={classNames(styles.root, props.className)}>
      <Box className={classNames(styles.stationListItem)} kind={'vflex'} gap='xs'>
        {groupId && (
          <Box className={classNames(styles.stationGroup)} kind={'hflex'}>
            <ApiBasedContent
              fetching={!stationGroupExtensions}
              resp={stationGroupExtensions}
              placeholder={() => <StackedSkeleton times={1} skeleton={() => <TableRowSkeleton />} />}
            >
              {(extensions) => {
                return (
                  <Box kind={'hflex'}>
                    {extensions.map((extension) => (
                      <Box kind={'vflex'} className={classNames(styles.stationGroupItem)} key={extension.chargeBoxId}>
                        <Box kind={'hflex'}>
                          <Box
                            testId={'station_status'}
                            className={styles.statusNoch}
                            bg={STATION_DOMAIN_STATUS_COLORS[extension.domainStatus || ChargeBoxDomainStatus.NotInOperation]}
                          ></Box>
                          <Box kind={'vflex'} className={styles.contentArea} gap='xxs'>
                            <Box kind={'hflex'} gap='s' align='center'>
                              <Box fg={extension.customName !== extension.chargeBoxId ? 'primary-neutral-100' : 'primary-neutral-085'} ellipses fs={'m'} fw='700'>
                                {extension.customName || extension.chargeBoxId}
                              </Box>
                              <VerticalList
                                gap='s'
                                divider
                                items={[
                                  props.hasChangeCustomNamePopup && (
                                    <ButtonV2
                                      onClick={() => props.onClickChangeCustomNameStationGroupExtension(extension.chargeBoxId, extension.customName || '')}
                                      className={styles.editCustomName}
                                      apperance='icon'
                                    >
                                      <Ico file={<EditIcon />} />
                                    </ButtonV2>
                                  ),
                                  station.isExternalHardware && <Ico fill='primary-neutral-080' size='16px' file={<ExternalHardwareIco />} />,
                                  station.isPublic && <Ico fill='primary-neutral-080' size='16px' file={<PublicStationsIco />} />,
                                ]}
                              />
                            </Box>{' '}
                            <Box onClick={() => navigateToChargePointsTab(extension.chargeBoxId)} hover={['pointer', 'underline']} fs={'m'} fw='700'>
                              {`${_t('ID')}: ${extension.chargeBoxId}`}
                            </Box>
                            <Box>{`${_t('S/N')}: ${extension.serialNumber}`}</Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                );
              }}
            </ApiBasedContent>
          </Box>
        )}
        <Box kind={'hflex'}>
          {!groupId && <Box testId={'station_status'} className={styles.statusNoch} bg={STATION_DOMAIN_STATUS_COLORS[domainStatus]}></Box>}
          <Box fs={'s'} gap='s' kind={'hgrid'} className={styles.contentArea} flexGrow='1'>
            <Box cellSize='1' kind={'vflex'} justify='flex-start' gap='xs'>
              {!groupId && (
                <Box kind={'hflex'} gap='s' align='center'>
                  <Box testId='station_name' fg={station.customName !== station.chargeBoxId ? 'primary-neutral-100' : 'primary-neutral-085'} ellipses fs={'m'} fw='700'>
                    {station.customName || station.chargeBoxId}
                  </Box>
                  <VerticalList
                    gap='s'
                    divider
                    items={[
                      props.hasChangeCustomNamePopup && (
                        <ButtonV2 dataCy='station_edit' onClick={props.onClickChangeCustomNameStation} className={styles.editCustomName} apperance='icon'>
                          <Ico file={<EditIcon />} />
                        </ButtonV2>
                      ),
                      station.isExternalHardware && <Ico fill='primary-neutral-080' size='16px' file={<ExternalHardwareIco />} />,
                      station.isPublic && <Ico testId='station_public' fill='primary-neutral-080' size='16px' file={<PublicStationsIco />} />,
                    ]}
                  />
                </Box>
              )}
              <NodeHierarchyLabelInverted allNodes={props.allNodes} code={station.nodeCode} hideArea={true} hideClientRoot={true} />
              <NodeLabel allNodes={props.allNodes} code={station.nodeCode} />
            </Box>
            <Box cellSize='1' kind={'vflex'} justify='flex-start' gap='xs'>
              <Box testId='station_original_name' onClick={() => navigateToChargePointsTab(station.chargeBoxId)} hover={['pointer', 'underline']} fs={'m'} fw='700'>
                {station.chargeBoxId}
              </Box>
              <Box ellipses>
                {_t('Modell')}: {station.manufacturer + ' - ' + station.model}
              </Box>
              {!groupId && (
                <Box>
                  {_t('S/N')}: {station.serialNumber}
                </Box>
              )}
              {station.clusterDisplayName ? (
                <Box kind={'hflex'} gap='xs'>
                  <Box fw='700'>{_t('Lastmanagement')}:</Box>
                  {loadMgmgOfClusterAndStationActive && (
                    <Box className={styles.lmStatus} fw='700' kind={'hflex'} align='center' gap='xs'>
                      <Circle color='primary-500' size='8px' />
                      {_t('Aktiviert')}
                    </Box>
                  )}
                  {!loadMgmgOfClusterAndStationActive && (
                    <Box className={styles.lmStatus} kind={'hflex'} align='center' gap='xs'>
                      <Circle borderColor='primary-500' color='brand-neutral-000' size='8px' />
                      {_t('Deaktiviert')}
                    </Box>
                  )}
                </Box>
              ) : (
                <span>&nbsp;</span>
              )}
            </Box>

            <Box kind={'vflex'} cellSize='2' gap='m'>
              <Box kind={'hflex'}>
                <Box flexGrow='1' fw='700'>
                  {station.connectorsTotalCount} {station.connectorsTotalCount === 1 ? _t('Ladepunkt') : _t('Ladepunkte')}
                  {station.connectorsTotalCount > 0 && !!station.mergedChargePointType && ` (${station.mergedChargePointType})`}
                </Box>
                <Box kind={'hflex'} gap='m'>
                  <ButtonV2 dataCy='station_charging_session' apperance='secondary' disabled={!station.can.goToSessions} href={props.sessionsLink}>
                    {_t('Charging Records')}
                  </ButtonV2>
                  <Divider />
                  <ButtonV2 disabled={station.connectorsTotalCount <= 0} onClick={station.connectorsTotalCount > 0 ? toggleShowDetails : undefined} apperance='icon'>
                    <Ico size='20px' rotate={270} file={<ChevronIco />} />
                  </ButtonV2>
                  <Divider />
                  <ButtonV2
                    dataCy='station_sub_menu'
                    onClick={props.hasStationContextItems ? props.onClickStationRemoteActionButton : undefined}
                    disabled={!props.hasStationContextItems}
                    tooltip={
                      !props.hasStationContextItems
                        ? _t('Fernsteuerung nicht möglich: Die Ladestation ist entweder offline oder nicht in Betrieb oder Sie verfügen nicht über die notwendige Berechtigung.')
                        : ''
                    }
                    apperance='icon'
                  >
                    <Ico size='20px' rotate={90} file={<ThreeDotsIco />} />
                  </ButtonV2>
                </Box>
              </Box>
              <Box>
                <VerticalList
                  gap='s'
                  items={[
                    station.connectorsAvailableCount > 0 && (
                      <StatisticPill testId={'station_connectors_count_available'} notchColor='secondary-teal'>
                        {station.connectorsAvailableCount}
                      </StatisticPill>
                    ),
                    station.connectorsChargingCount > 0 && (
                      <StatisticPill testId={'station_connectors_count_charging'} notchColor='blue-blue-jeans'>
                        {station.connectorsChargingCount}
                      </StatisticPill>
                    ),
                    station.connectorsOccupiedCount > 0 && (
                      <StatisticPill testId={'station_connectors_count_occupied'} notchColor='red-light-salmon'>
                        {station.connectorsOccupiedCount}
                      </StatisticPill>
                    ),
                    station.connectorsFailureCount > 0 && (
                      <StatisticPill testId={'station_connectors_count_failure'} notchColor='functional-error-full'>
                        {station.connectorsFailureCount}
                      </StatisticPill>
                    ),
                    station.connectorsNotAvailableCount > 0 && (
                      <StatisticPill testId={'station_connectors_count_notavailable'} notchColor='primary-neutral-090'>
                        {station.connectorsNotAvailableCount}
                      </StatisticPill>
                    ),
                    station.connectorsUnknownCount > 0 && (
                      <StatisticPill testId={'station_connectors_count_unknown'} notchColor='functional-disabled-200'>
                        {station.connectorsUnknownCount}
                      </StatisticPill>
                    ),
                  ]}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {showDetails && (
        <ApiBasedContent
          fetching={!stationDetails}
          resp={stationDetails}
          placeholder={() => <StackedSkeleton times={station.connectorsTotalCount} skeleton={() => <HugeListItemSkeleton />} />}
        >
          {(details) => {
            return (
              <Box className={styles.cpContainer} kind={'vflex'} gap='s'>
                {details.chargePoints.map((cp, i) => {
                  const hasContextItems = props.hasChargePointContextItems(cp);
                  return (
                    <ChargepointListItem
                      item={cp}
                      key={i}
                      allNodes={props.allNodes}
                      sessionsLink={props.sessionsLink}
                      loadManagementLinkActive={props.loadManagementLinkActive}
                      clientCode={props.clientCode}
                      onClickRemoteActionButton={props.onClickChargePointRemoteActionButton}
                      hasContextIcons={hasContextItems}
                      hasChangeCustomNamePopup={props.hasChangeCustomNamePopup}
                      onClickChangeCustomName={() => props.onClickChangeCustomNameChargePoint(cp)}
                    />
                  );
                })}
              </Box>
            );
          }}
        </ApiBasedContent>
      )}
    </Box>
  );
}

interface StatisticPillProps {
  notchColor: Colors;
  className?: string;
  testId?: string;
}
function StatisticPill(props: PropsWithChildren<StatisticPillProps>) {
  return (
    <Box testId={props.testId} className={classNames(styles.statisticPill, props.className)} kind={'hflex'} align='center'>
      <Box bg={props.notchColor} className={styles.notch}></Box>
      <Box kind={'hflex'} align='center' justify='center' flexGrow='1' className={styles.value}>
        {props.children}
      </Box>
    </Box>
  );
}

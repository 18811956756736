import clsx from 'clsx';
import { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as ChevronLeftIcon } from '../../assets/chevron-left.svg';
import { ReactComponent as CloseIcon } from '../../assets/close2.svg';
import useBetterTranslate from '../../utils/translation-utils';
import ButtonV2, { ButtonV2Primary, ButtonV2Props } from '../button';
import Box from '../utils';
import st from './modal.module.scss';

import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import Ico from '../ico';
export interface ModalProps<TOk, TNok, TArgs> {
  className?: string;
  open: boolean;
  // testId?: string;
  appearDirection?: 'appearTop' | 'appearRight';

  args?: TArgs;
  // onClose: () => void;
  onClose?: (val: TOk | TNok | null) => void;
  children?: (close: (val: TOk | TNok | null) => void, args?: TArgs) => ReactNode;
}

export const SimpleModal = Modal<true, false, {}>;

export function Modal<TOk, TNok, TArgs>(props: ModalProps<TOk, TNok, TArgs>) {
  const ref = useRef<HTMLDialogElement>(null);
  const appearDirection = props.appearDirection || 'top';

  const { open, onClose } = props;
  useEffect(() => {
    if (!ref.current) return;
    if (open === ref.current.open) return;

    if (open) {
      ref.current.showModal();
    } else {
      ref.current.classList.add(st.closed);
      const animHandler = () => {
        ref.current?.classList?.remove(st.closed);
        ref.current?.close();
        ref.current?.removeEventListener('animationend', animHandler);
      };
      ref.current?.addEventListener('animationend', animHandler);
    }
  }, [open]);

  const closeCallback = useCallback(
    (val: TOk | TNok | null) => {
      onClose?.(val);
    },
    [onClose]
  );

  const cancelHandler = useCallback(
    (e: Event) => {
      e.preventDefault();
      closeCallback(null);
    },
    [closeCallback]
  );

  // const closeHandler = useCallback(() => {
  //   props.onClose?.();
  // }, [props.onClose]);

  useEffect(() => {
    const currentRef = ref.current;
    currentRef?.addEventListener('cancel', cancelHandler);

    // ref.current?.addEventListener('close', closeHandler);

    return () => {
      currentRef?.removeEventListener('cancel', cancelHandler);
      // ref.current?.removeEventListener('close', closeHandler);
    };
  }, [cancelHandler]);

  return (
    <dialog ref={ref} className={clsx(st.root, props.className, st[appearDirection])}>
      {props.children?.(closeCallback, props.args)}
    </dialog>
  );
}

export type UseModalProps = {
  className?: string;
  appearDirection?: 'appearTop' | 'appearRight';
};

export const useSimpleModal = useModal<true, false, {}>;
export function useModal<TOk, TNok, TArgs>(props?: UseModalProps): [(arg: TArgs) => Promise<TOk | TNok | null>, ModalProps<TOk, TNok, TArgs>] {
  const [open, setOpen] = useState<boolean>(false);
  const [args, setArgs] = useState<TArgs>();

  const refObj = useMemo(() => {
    let resolve = (val: TOk | TNok | null) => {};
    let promise = new Promise<TOk | TNok | null>((res) => {
      resolve = res;
    });

    const refObj = {
      show: (args: TArgs) => {
        setOpen(true);
        setArgs(args);
        return promise;
      },
      __resolve: (val: TOk | TNok | null) => {
        resolve(val);
      },
      __reset: () => {
        promise = new Promise<TOk | TNok | null>((res) => {
          resolve = res;
        });
      },
    };

    return refObj;
  }, []);

  const { __resolve, show, __reset } = refObj;
  const onClose = useCallback(
    (val: TOk | TNok | null) => {
      __resolve(val);
      setOpen(false);
      __reset();
    },
    [__resolve, __reset]
  );

  const className = props?.className;
  const appearDirection = props?.appearDirection;
  const componentProps = useMemo(() => {
    const componentProps: ModalProps<TOk, TNok, TArgs> = {
      open: open,
      className: className,
      args: args,
      appearDirection: appearDirection,
      onClose: onClose,
    };
    return componentProps;
  }, [open, className, args, onClose, appearDirection]);

  return [show, componentProps];
}

export function ModalHeader(props: { className?: string; title: ReactNode; onClose?: () => void }) {
  return (
    <Box kind={'hflex'} w={'100%'} className={st.headerWrapper} justify='center'>
      <Box className={clsx(props.className, st.header)} kind={'hflex'} align={'center'} justify={'space-between'}>
        {props.title && (
          <Box w='100%' lh='2xl' kind={'vflex'} align='center' txtAlign='center' fw={'700'} fs={'2xl'}>
            {props.title}
          </Box>
        )}
      </Box>
      {props.onClose && (
        <Box onClick={props.onClose} className={st.closeBtn}>
          <Ico size='16px' file={<CloseIcon />} />
        </Box>
      )}
    </Box>
  );
}

export function ModalContent(props: PropsWithChildren<{ className?: string }>) {
  return (
    <Box flexGrow='1' className={clsx(props.className, st.content)} w='100%'>
      {props.children}
    </Box>
  );
}

export function ModalFooter(props: PropsWithChildren<{ className?: string }>) {
  return (
    <Box className={clsx(props.className, st.footer)} kind='hflex' gap={'l'} justify={'center'}>
      {props.children}
    </Box>
  );
}

export function ModalAcceptButton(props: ButtonV2Props) {
  const { _t } = useBetterTranslate('modal');
  return (
    <ButtonV2Primary {...props}>
      <Box kind={'hflex'} gap='s' pad={['0', '100', '0', '0']}>
        <Ico file={<CheckIcon />} fill='primary-white' size='16px' /> {props.children || _t('Accept')}
      </Box>
    </ButtonV2Primary>
  );
}
export function ModalCancelButton(props: ButtonV2Props) {
  const { _t } = useBetterTranslate('modal');
  return (
    <ButtonV2 {...props} apperance='secondary'>
      <Box kind={'hflex'} gap='s' pad={['0', '0', '0', '0']}>
        <Ico file={<ChevronLeftIcon />} size='16px' />
        {props.children || _t('Cancel')}
      </Box>
    </ButtonV2>
  );
}

import { useEffect, useState } from 'react';
import { isHttpResponse } from '../../../services/api';
import { ApiError, HttpResponse, SharedClientContext, SharedClientDto } from '../../../services/api-client/csp-api';
import useBetterTranslate from '../../../utils/translation-utils';
import useEffectAsync from '../../../utils/useEffectAsync';
import { FormContainerV2 } from '../../input';
import { Modal, ModalAcceptButton, ModalCancelButton, ModalContent, ModalFooter, ModalHeader, ModalProps, useModal } from '../../modal/modal';
import SingleSelect from '../../select/single-select';
import Box, { Divider } from '../../utils';

export type ClientChooserPopupArgs = {
  searchClients: (search: string) => Promise<SharedClientDto[] | HttpResponse<SharedClientDto[], ApiError>>;
  clientContext?: SharedClientContext;
};

export function useClientChooser() {
  return useModal<string, false, ClientChooserPopupArgs>({});
}

export function ClientChoosePopup(props: ModalProps<string, false, ClientChooserPopupArgs>) {
  const { _t } = useBetterTranslate('client-choose-popup');
  const [client, setClient] = useState<{ id: string; label: string } | null>(null);
  const [initialList, setInitialList] = useState<{ id: string; label: string }[]>([]);

  const searchClients = props.args?.searchClients;
  useEffectAsync(async () => {
    if (!searchClients) return;
    const res = await searchClients('');
    if (isHttpResponse(res)) setInitialList(res.data.map((r) => ({ id: r.code, label: r.title })));
    else setInitialList(res.map((r) => ({ id: r.code, label: r.title, value: r })));
  }, [searchClients]);

  const current = props?.args?.clientContext;
  useEffect(() => {
    setClient(current ? { id: current.code, label: current.title } : null);
  }, [current]);

  return (
    <Modal {...props}>
      {(close, args) => {
        return (
          <>
            <ModalHeader title={_t('Choose Organisation')} onClose={() => close(false)} />
            <ModalContent>
              <Box maxw='400px' w='100%'>
                <FormContainerV2 label={_t('Select an organisation')} w='1'>
                  <SingleSelect
                    selectedValue={client}
                    options={initialList}
                    maxMenuHeight={120}
                    fetchOptions={async (search) => {
                      if (!args) return [];
                      const res = await args.searchClients(search);
                      if (isHttpResponse(res)) return res.data.map((r) => ({ id: r.code, label: r.title }));
                      return res.map((r) => ({ id: r.code, label: r.title, value: r }));
                    }}
                    createOption={(val) => val.label}
                    onChanged={setClient}
                  />
                </FormContainerV2>
              </Box>
            </ModalContent>
            <ModalFooter>
              <ModalCancelButton onClick={() => close(false)}>{_t('Cancel and return')}</ModalCancelButton>
              <Divider kind='v' />
              <ModalAcceptButton onClick={() => close(client?.id || null)}>{_t('Apply Organisation')}</ModalAcceptButton>
            </ModalFooter>
          </>
        );
      }}
    </Modal>
  );
}

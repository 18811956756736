import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';
import { ReactComponent as SortingIco } from '../../assets/sorting.svg';
import { ReactComponent as ChevronIco } from '../../assets/chevron-left.svg';
import ifTrue from '../../utils/class-name';
import Ico from '../ico';
import Box, { BoxProps, Spaces2 } from '../utils';
import styles from './data-table.module.scss';

export type SortDirection = 'desc' | 'asc';
export type SortableFilterParams = {
  sortBy?: string;
  sortDesc?: string;
};
export function sortHandler(key: string, setFilterParams: (val: SortableFilterParams) => void) {
  return (desc: boolean) => {
    setFilterParams({ sortBy: key, sortDesc: desc ? '1' : undefined });
  };
}

export function sortChecker(key: string, filter: SortableFilterParams): SortDirection | undefined {
  if (filter.sortBy !== key) return undefined;
  if (filter.sortDesc === '1') return 'desc';
  else return 'asc';
}

export type DataTableLayout<T> = {
  width?: `${number}fr` | `${number}px`;
  header?: {
    span?: number;
    onSort?: (desc: boolean) => void;
    sorting?: SortDirection;
    ellipsis?: boolean;
    padding?: Spaces2 | Spaces2[];
    node?: (records: T[]) => ReactNode;
    testId?: string;
  };
  subHeader?: {
    // will be rendered over span columns
    // you should set padding in the next columns to 0
    // as they are still rendered without w. and hight BUT they will have padding
    // which occupies space
    ellipsis?: boolean;
    span?: number;
    padding?: Spaces2 | Spaces2[];
    node?: (records: T[]) => ReactNode;
  };
  cell?: {
    ellipsis?: boolean;
    span?: number;
    padding?: Spaces2 | Spaces2[];
    node?: (row: T) => ReactNode;
  };
}[];

export interface DataTableProps<T> {
  className?: string;
  records?: T[];
  rowClass?: (record: T) => string;
  subHeaderClass?: string;
  layout?: DataTableLayout<T>;
  h?: BoxProps['h'];
}

export default function DataTable<T>(props: DataTableProps<T>) {
  const layout = props.layout || [];
  const headerCells = layout.filter((r) => !!r.header);
  const subHeaderCells = layout.filter((r) => !!r.subHeader);
  const dataCells = layout.filter((r) => !!r.cell);

  const tplColStyle = layout.map((c) => c.width || '1fr').join(' ');

  const hasHeader = headerCells.length > 0;
  const hasSubHeader = subHeaderCells.length > 0;
  const hasDataCells = dataCells.length > 0;
  const records = props.records || [];
  const h = props.h || '100%';

  return (
    <Box kind={'vflex'} className={classNames(styles.root, props.className)} h={h}>
      {(hasHeader || hasSubHeader) && layout && (
        <Box kind={'vflex'} className={styles.allHeaders}>
          {hasHeader && layout && (
            <div className={styles.headerWrapper}>
              <div style={{ display: 'grid', gridTemplateColumns: tplColStyle }} className={styles.header}>
                {layout.map((h, i) => {
                  const header = h.header;
                  const ellipsis = header?.ellipsis || header?.ellipsis === undefined;
                  const hasSorting = !!header?.onSort;

                  const onSort = header?.onSort || ((val) => {});

                  return (
                    <Box
                      className={classNames(ifTrue(styles.sorting, hasSorting))}
                      onClick={() => onSort(header?.sorting !== 'desc')}
                      ellipses={ellipsis}
                      key={i}
                      testId={header?.testId}
                      styles={{ gridColumnStart: header?.span ? `${header.span} span` : undefined }}
                      pad={header?.padding || ['200', '0', '200', '400']}
                    >
                      <div className={styles.cellInner}>
                        {header?.node?.(records)}
                        {hasSorting && (
                          <Box kind={'vflex'} justify='flex-start' align='center'>
                            <Ico
                              size='8px'
                              className={classNames(styles.sortIco, header?.sorting === 'asc' ? styles.active : '')}
                              rotate={90}
                              file={<ChevronIco />}
                              fill={'primary-500'}
                            />
                            <Ico
                              size='8px'
                              className={classNames(styles.sortIco, header?.sorting === 'desc' ? styles.active : '')}
                              rotate={270}
                              file={<ChevronIco />}
                              fill={'primary-500'}
                            />

                            {/* <Ico size='10px' file={<SortingIco />} fill={h.header?.sorting}  /> */}
                          </Box>
                        )}
                      </div>
                    </Box>
                  );
                })}
              </div>
            </div>
          )}
          {hasSubHeader && layout && (
            <div className={classNames(styles.subHeaderWrapper, props.subHeaderClass)}>
              <div style={{ display: 'grid', gridTemplateColumns: tplColStyle }} className={styles.subHeader}>
                {layout.map((h, i) => {
                  var subHeader = h.subHeader;
                  const ellipsis = subHeader?.ellipsis || subHeader?.ellipsis === undefined;
                  if (!subHeader) return <Fragment key={i}></Fragment>;
                  return (
                    <Box
                      ellipses={ellipsis}
                      key={i}
                      styles={{ gridColumnStart: subHeader?.span ? `${subHeader.span} span` : undefined }}
                      pad={subHeader?.padding || ['200', '0', '200', '400']}
                    >
                      <div className={styles.cellInner}>{subHeader?.node?.(records)}</div>
                    </Box>
                  );
                })}
              </div>
            </div>
          )}
        </Box>
      )}

      {hasDataCells && layout && records.length > 0 && (
        <Box kind={'vflex'} gap='m' className={styles.data}>
          {records.map((r, i) => {
            return (
              <div key={i} className={classNames(styles.rowWrapper, props.rowClass?.(r))}>
                <div style={{ display: 'grid', gridTemplateColumns: tplColStyle }} className={styles.row}>
                  {layout.map((h, i) => {
                    var cell = h.cell;
                    const ellipsis = cell?.ellipsis || cell?.ellipsis === undefined;
                    return (
                      <Box ellipses={ellipsis} key={i} styles={{ gridColumnStart: cell?.span ? `${cell.span} span` : undefined }} pad={cell?.padding || ['200', '0', '200', '400']}>
                        <div className={styles.cellInner}>{cell?.node?.(r)}</div>
                      </Box>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiError {
  message: string;
  statusCode: number;
  error: string;
}

export interface HealtcheckResponseDto {
  /** @format date-time */
  time: string;
  status: object;
}

export interface UserProfileDto {
  mail: string;
  name: string;
  firstName: string;
  lastName: string;
}

export interface UiTranslationEtagResponse {
  etag: string;
}

export interface UiTranslationItemDto {
  namespace: string;
  key: string;
  value: string;
}

export interface UiTranslationResponse {
  items: UiTranslationItemDto[];
  etag: string;
}

export enum Permission {
  ClientStationListView = 'client-station-list-view',
  ClientStationStatusView = 'client-station-status-view',
  ClientStationRemoteReset = 'client-station-remote-reset',
  ClientStationRemoteStart = 'client-station-remote-start',
  ClientStationRemoteStop = 'client-station-remote-stop',
  ClientStationRemoteUnlock = 'client-station-remote-unlock',
  ClientStationRemoteChangeavailability = 'client-station-remote-changeavailability',
  ClientStationRemoteReserve = 'client-station-remote-reserve',
  ClientStationRename = 'client-station-rename',
  ClientCardHomeView = 'client-card-home-view',
  ClientCardEmployeeView = 'client-card-employee-view',
  ClientCardWorkView = 'client-card-work-view',
  ClientCardWorkEdit = 'client-card-work-edit',
  ClientCardUndefinedView = 'client-card-undefined-view',
  ClientSessionExportAggregation = 'client-session-export-aggregation',
  ClientClusterLoadMgmtView = 'client-cluster-load-mgmt-view',
  AdminRoleView = 'admin-role-view',
  AdminRoleRootWrite = 'admin-role-root-write',
  AdminRoleRootDelete = 'admin-role-root-delete',
  AdminRoleStandardWrite = 'admin-role-standard-write',
  AdminRoleStandardDelete = 'admin-role-standard-delete',
  AdminRoleIndividualWrite = 'admin-role-individual-write',
  AdminRoleIndividualDelete = 'admin-role-individual-delete',
  AdminClientView = 'admin-client-view',
  AdminClientWrite = 'admin-client-write',
  AdminClientDelete = 'admin-client-delete',
  AdminUserManage = 'admin-user-manage',
  AdminUserView = 'admin-user-view',
  SystemAffiliateManage = 'system-affiliate-manage',
  SystemUserManage = 'system-user-manage',
  ClientOrgStructureView = 'client-org-structure-view',
  ClientOrgNodeManage = 'client-org-node-manage',
  ClientOrgAreaManage = 'client-org-area-manage',
  ClientOrgUserManage = 'client-org-user-manage',
  ClientOrgCardAssign = 'client-org-card-assign',
  ClientOrgStationAssign = 'client-org-station-assign',
  DevVersionMsvcView = 'dev-version-msvc-view',
  DevLanguageView = 'dev-language-view',
  DevToolsManage = 'dev-tools-manage',
  OperationManage = 'operation-manage',
  ClientStatuslogView = 'client-statuslog-view',
  SupportTicketStationCreate = 'support-ticket-station-create',
}

export interface ListAccessibleClientNodesRequest {
  permissions: Permission[];
  search: string;
}

export enum ClientHierarchyNodeTypeDto {
  ClientRoot = 'clientRoot',
  Region = 'region',
  Location = 'location',
  Area = 'area',
}

export interface SharedClientHierarchyNodeDto {
  type: ClientHierarchyNodeTypeDto;
  code: string;
  title: string;
  path?: string;
  parentCode?: string;
  autoCreated?: boolean;
  cpmsId?: string;
}

export interface SharedClientDto {
  code: string;
  title: string;
  cpmsId?: string;
  hierarchy: SharedClientHierarchyNodeDto[];
}

export interface SharedAffiliateDto {
  code: string;
  title: string;
}

export interface UserClientPreferencesDto {
  clusters: {
    visible: boolean;
    count?: number;
    defaultId?: string;
  };
}

export interface AccessibleViews {
  infrastructure: {
    visible: boolean;
    clients?: {
      codes: string[];
      root: boolean;
    };
  };
  cards: {
    visible: boolean;
    clients?: {
      codes: string[];
      root: boolean;
    };
  };
  locations: {
    visible: boolean;
    clients?: {
      codes: string[];
      root: boolean;
    };
  };
  clusters: {
    visible: boolean;
    clients?: {
      codes: string[];
      root: boolean;
    };
  };
  logs: {
    visible: boolean;
    clients?: {
      codes: string[];
      root: boolean;
    };
  };
  administration: {
    clients?: {
      visible: boolean;
      affiliates?: {
        codes: string[];
        root: boolean;
      };
    };
    users?: {
      visible: boolean;
      affiliates?: {
        codes: string[];
        root: boolean;
      };
    };
    roles?: {
      visible: boolean;
      affiliates?: {
        codes: string[];
        root: boolean;
      };
    };
  };
  system: {
    affiliates: boolean;
    users: boolean;
  };
  dev: {
    versions: boolean;
    translations: boolean;
  };
  sessions: {
    visible: boolean;
    prefix: string;
    clients?: {
      codes: string[];
      root: boolean;
    };
  };
  organization: {
    structure?: {
      visible: boolean;
      clients?: {
        codes: string[];
        root: boolean;
      };
    };
    users?: {
      visible: boolean;
      clients?: {
        codes: string[];
        root: boolean;
      };
    };
  };
  kiosk: {
    visible: boolean;
    loginUrl?: string;
  };
  operations: {
    visible: boolean;
  };
}

export interface UserPreferencesDto {
  availableRootViews: ('loadmanagement' | 'chargepoints' | 'charging-cards' | 'charging-sessions' | 'administration' | 'locations' | 'organisation')[];
  environment: string;
  views: AccessibleViews;
  languageCode?: string;
}

export interface NodeResourcesRequestDto {
  clientCode: string;
  nodeCode: string;
}

export interface NodeResourcesResponseDto {
  stationsCount: number;
  connectorsCount: number;
  cardsCount: number;
  usersCount: number;
}

export interface UsersListRequest {
  limit: number;
  skip: number;
  affiliateCode: string;
  search?: string;
  roleIds?: string[];
  clientCodes?: string[];
  client?: boolean;
  root?: boolean;
  sortBy?: 'firstName' | 'mail';
  sortDesc?: boolean;
}

export interface SharedAffiliateContext {
  requestedPermissions: Permission[];
  code: string;
  title: string;
  matchOtherAffiliate: boolean;
}

export interface UserListRoleDto {
  id: string;
  name: string;
  type: 'root' | 'standard' | 'individual' | 'system' | 'anchor';
}

export interface UserClientOptionDTO {
  id: string;
  title: string;
}

export interface UserListEntryDto {
  firstName: string;
  lastName: string;
  fullName: string;
  mail: string;
  roles: UserListRoleDto[];
  roleAccessTypes: string[];
  clients?: UserClientOptionDTO[];
}

export enum RoleType {
  Root = 'root',
  Standard = 'standard',
  Individual = 'individual',
  System = 'system',
  Anchor = 'anchor',
}

export interface UserRoleOptionDto {
  type: RoleType;
  id: string;
  name: string;
}

export interface UsersListResponse {
  affiliateContext: SharedAffiliateContext;
  limit: number;
  skip: number;
  total: number;
  entries: UserListEntryDto[];
  roleOptions: UserRoleOptionDto[];
  clientOptions: UserClientOptionDTO[];
  defaultLanguage: string;
  can: {
    viewRoleList: boolean;
    viewClientList: boolean;
    manageUser: boolean;
    manageClientUsers: boolean;
  };
}

export interface RootUserDto {
  mail: string;
  firstName: string;
  lastName: string;
  affiliateCode?: string;
  roleIds: string[];
  defaultLanguage: string;
}

export enum UserType {
  AFFILIATE_ROOT = 'AFFILIATE_ROOT',
  CLIENT_OR_OTHER_AFFILIATE = 'CLIENT_OR_OTHER_AFFILIATE',
  NOT_EXISTS = 'NOT_EXISTS',
}

export interface UserWithTypeResponse {
  userType: UserType;
  user?: RootUserDto;
}

export interface PermissionsOfRoleResponse {
  permissions: Permission[];
}

export enum ClusterStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum LoadManagementStrategyGroup {
  Static = 'static',
  Dynamic = 'dynamic',
}

export enum WorkloadLevel {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export enum ClusterColumnFrontEnd {
  PostalCode = 'postalCode',
  Workload = 'workload',
  ConnectorsCount = 'connectorsCount',
  Status = 'status',
  Strategy = 'strategy',
}

export interface ListClusterArgsDto {
  /** @example ["BMW"] */
  hierarchyNodeCodes: string[];
  status?: ClusterStatus[];
  strategy?: LoadManagementStrategyGroup[];
  workload?: WorkloadLevel[];
  sortBy?: ClusterColumnFrontEnd;
  /**
   * @default false
   * @example false
   */
  sortDesc?: boolean;
  limit?: number;
  skip?: number;
  clientCode: string;
  searchText?: string;
}

export interface SharedClientContext {
  requestedPermissions: Permission[];
  code: string;
  title: string;
  accessableNodes: SharedClientHierarchyNodeDto[];
  forbiddenParents: SharedClientHierarchyNodeDto[];
  selectedHierarchy: SharedClientHierarchyNodeDto[];
  selectedNodes: SharedClientHierarchyNodeDto[];
  selectedCommonParent: SharedClientHierarchyNodeDto;
  matchOtherClients: boolean;
}

export interface ClusterListItemDto {
  id: string;
  title: string;
  postalCode: string;
  nodePath: string;
  loadManagementStatus: 'active' | 'inactive';
  chargePointsTotal: number;
  loadManagementStrategyGroup?: 'static' | 'dynamic';
  workload: 'high' | 'medium' | 'low';
}

export interface ClusterListResultDto {
  clientContext: SharedClientContext;
  entries: ClusterListItemDto[];
  total: number;
  limit: number;
  skip: number;
}

export interface ClusterSummaryRequestDto {
  requestId?: string;
  client: string;
  hierarchyNodeCodes: string[];
  clusterId?: string;
}

export interface ClusterSummary {
  nodeCode: string;
  nodePath: string;
  nodeTitle: string;
  subNodes: number;
  stationsOnline: number;
  stationsOffline: number;
  stationsFailure: number;
  stationsNotInOperation: number;
  connectorsAvailable: number;
  connectorsCharging: number;
  connectorsUnavailable: number;
  connectorsOccupied: number;
  connectorsFailure: number;
  connectorsUnknown: number;
  stationsAssumedEnergy: number;
  can: {
    goToList: boolean;
  };
}

export interface ClusterSummaryResponseDto {
  clientContext: SharedClientContext;
  summary: ClusterSummary;
}

export interface GetStationGroupExtensionsArgsDto {
  chargeBoxId: string;
  clientCode: string;
  hierarchyNodeCodes: string[];
}

export interface ExtensionStation {
  chargeBoxId: string;
  customName?: string;
  serialNumber?: string;
  domainStatus?: 'online' | 'offline' | 'failure' | 'notInOperation';
}

export interface GetStationGroupExtensionsResultDto {
  stations: ExtensionStation[];
}

export interface ListClusterStationsArgsDto {
  limit: number;
  skip: number;
  client?: string;
  from?: string;
  to?: string;
  online?: boolean;
  offline?: boolean;
  failure?: boolean;
  notInOperation?: boolean;
  ac?: boolean;
  dc?: boolean;
  acdc?: boolean;
  chargeBoxIds?: string[];
  search?: string;
  clusterId?: string;
  isPublic?: boolean;
}

export enum ChargeBoxDomainStatus {
  Online = 'online',
  Offline = 'offline',
  Failure = 'failure',
  NotInOperation = 'notInOperation',
}

export interface GroupDomainStatusesDto {
  L?: 'online' | 'offline' | 'failure' | 'notInOperation';
  R?: 'online' | 'offline' | 'failure' | 'notInOperation';
}

export interface ClusterStationsListItemDto {
  domainStatus?: ChargeBoxDomainStatus;
  chargeBoxId: string;
  displayName?: string;
  customName?: string;
  clusterDisplayName?: string;
  clusterId?: string;
  nodeCode: string;
  connectorsAvailableCount: number;
  connectorsChargingCount: number;
  connectorsFailureCount: number;
  connectorsNotAvailableCount: number;
  connectorsOccupiedCount: number;
  connectorsUnknownCount: number;
  connectorsTotalCount: number;
  loadMgmtOfClusterActive?: boolean;
  loadManagementActive?: boolean;
  can: {
    remoteReset: boolean;
    remoteChangeAvailability: boolean;
    goToSessions: boolean;
    setCustomName: boolean;
  };
  mergedChargePointType?: string;
  model: string;
  manufacturer: string;
  serialNumber: string;
  isExternalHardware: boolean;
  isPublic: boolean;
  groupId: string;
  groupDomainStatuses: GroupDomainStatusesDto;
  groupMemberType: 'none' | 'main' | 'extension';
}

export interface ClusterStationListResultDto {
  clientContext: SharedClientContext;
  stations: ClusterStationsListItemDto[];
  sessionsPagePrefix: string;
  total: number;
  limit: number;
  skip: number;
}

export interface ListClusterChargePointsArgsDto {
  limit: number;
  skip: number;
  client: string;
  ac?: boolean;
  dc?: boolean;
  available?: boolean;
  charging?: boolean;
  occupied?: boolean;
  failure?: boolean;
  unavailable?: boolean;
  unknown?: boolean;
  outletTypes?: string[];
  searchText?: string;
  clusterId?: string;
  hideNotInOperation?: boolean;
  isPublic?: boolean;
}

export interface ClusterChargePointsListItemDto {
  chargePointType: string;
  chargeBoxId: string;
  connectorId: string;
  currentLoad: number;
  currentLoadUnit: string;
  nodeCode: string;
  domainStatus: 'available' | 'charging' | 'notAvailable' | 'failure' | 'unknown' | 'occupied';
  domainStatusOfStation: 'online' | 'offline' | 'failure' | 'notInOperation';
  evseId: string;
  customName: string;
  loadLimit: number;
  loadLimitUnit: string;
  possibleChargingPower: string;
  outletType: string;
  maxLoadLimit: number;
  maxLoadLimitUnit: string;
  loadMgmtOfClusterAndStationActive: boolean;
  isAssignedToCluster: boolean;
  clusterId: string;
  isReduced: boolean;
  domainSubStatusForNotAvailable: 'none' | 'locked' | 'reserved';
  isExternalHardware: boolean;
  isPublic: boolean;
  can: {
    remoteStartSession: boolean;
    remoteStopSession: boolean;
    remoteUnlock: boolean;
    remoteReserve: boolean;
    remoteChangeAvailability: boolean;
    goToSessions: boolean;
    setCustomName: boolean;
  };
}

export interface ClusterChargePointsListResultDto {
  clientContext: SharedClientContext;
  sessionsPagePrefix: string;
  chargePoints: ClusterChargePointsListItemDto[];
  total: number;
  limit: number;
  skip: number;
}

export interface ClusterDetailsArgsDto {
  clusterId: string;
  clientCode: string;
  from?: string;
  to?: string;
}

export interface ValueUnit {
  value: number;
  unit: string;
}

export interface ClusterDetailDto {
  displayName: string;
  loadManagementStatus: 'active' | 'inactive';
  loadManagementStrategyGroup: 'static' | 'dynamic';
  loadLimit: ValueUnit;
  workload: 'high' | 'medium' | 'low';
  meter: {
    physicalSupplyLimit?: ValueUnit;
    definedMaxSupplyLimitSum?: ValueUnit;
  };
}

export interface HistoricalDataPoint {
  value: number;
  timestamp: string;
}

export interface HistoricalDataSet {
  unit: string;
  values: HistoricalDataPoint[];
}

export interface HistoricalClusterData {
  clusterLoad: HistoricalDataSet;
  clusterLoadLimit: HistoricalDataSet;
  locationLoad: {
    L0: HistoricalDataSet;
    L1: HistoricalDataSet;
    L2: HistoricalDataSet;
    L3: HistoricalDataSet;
  };
  locationLoadLimit: {
    L0: HistoricalDataSet;
    L1: HistoricalDataSet;
  };
}

export interface ClusterDetailResultDto {
  cluster: ClusterDetailDto;
  clusterCount: number;
  historicalData: HistoricalClusterData;
}

export interface ChargepointsOfStationsArgsDto {
  client?: string;
  hierarchyNodeCodes: string[];
  chargeBoxId: string;
}

export enum ConnectorDomainStatus {
  Available = 'available',
  Charging = 'charging',
  NotAvailable = 'notAvailable',
  Failure = 'failure',
  Unknown = 'unknown',
  Occupied = 'occupied',
}

export enum DomainSubStatusForNotAvailable {
  None = 'none',
  Locked = 'locked',
  Reserved = 'reserved',
}

export interface ChargePointItemDto {
  domainStatus: ConnectorDomainStatus;
  domainStatusOfStation: ChargeBoxDomainStatus;
  domainSubStatusForNotAvailable: DomainSubStatusForNotAvailable;
  chargePointType: string;
  chargeBoxId: string;
  currentLoad: number;
  currentLoadUnit: string;
  customName?: string;
  evseId: string;
  loadLimit: number;
  loadLimitUnit: string;
  possibleChargingPower: string;
  outletType: string;
  maxLoadLimit: number;
  maxLoadLimitUnit: string;
  nodeCode: string;
  connectorId: string;
  loadMgmtOfClusterAndStationActive: boolean;
  isAssignedToCluster: boolean;
  clusterId?: string;
  can: {
    remoteStartSession: boolean;
    remoteStopSession: boolean;
    remoteUnlock: boolean;
    remoteReserve: boolean;
    remoteChangeAvailability: boolean;
    goToSessions: boolean;
  };
  isReduced: boolean;
  isExternalHardware: boolean;
  isPublic: boolean;
}

export interface ChargePointsOfStationResultDto {
  chargePoints: ChargePointItemDto[];
}

export interface CardListItemDto {
  extId: string;
  /** the card number (tagId) */
  id: string;
  label: string;
}

export interface CardSearchListResultDto {
  cards: CardListItemDto[];
}

export enum ChangeAvailabilityTypes {
  Inoperative = 'Inoperative',
  Operative = 'Operative',
}

export interface RemoteChangeStationAvailabilityRequest {
  type: ChangeAvailabilityTypes;
  chargeBoxId: string;
}

export enum ChangeAvailabilityResult {
  Success = 'success',
  Partial = 'partial',
  Failed = 'failed',
}

export interface ChangeStationAvailabilityResponse {
  changeAvailabilityStatus: ChangeAvailabilityResult;
  connectors: number[];
}

export interface RemoteStartSessionRequest {
  chargeBoxId: string;
  connectorId: string;
  connectorEvseId?: string;
  cardNumber: string;
  cardExtId: string;
}

export interface RemoteStopSessionRequest {
  chargeBoxId: string;
  connectorId: string;
  connectorEvseId?: string;
}

export interface RemoteReserveRequest {
  chargeBoxId: string;
  connectorId: string;
  cardNumber: string;
  cardExtId: string;
  minutes: number;
  connectorEvseId?: string;
}

export interface RemoteCancelReserveRequest {
  chargeBoxId: string;
  connectorId?: string;
  connectorEvseId?: string;
}

export interface RemoteUnlockRequest {
  chargeBoxId: string;
  connectorId: string;
  connectorEvseId?: string;
}

export interface RemoteChangeChargePointAvailabilityRequest {
  type: ChangeAvailabilityTypes;
  chargeBoxId: string;
  connectorId: string;
  connectorEvseId?: string;
}

export enum RateServiceType {
  HOME = 'HOME',
  EMPLOYEE = 'EMPLOYEE',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  WORK = 'WORK',
  UNDEFINED = 'UNDEFINED',
}

export enum SessionStatus {
  Occupied = 'occupied',
  Charging = 'charging',
  Closed = 'closed',
}

export interface NumberRangeFilter {
  from?: number;
  to?: number;
}

export interface ChargingSessionsEntityFilterDto {
  title?: string;
  type: string;
  id: string;
}

export interface ListChargingSessionsArgsDto {
  /** @example ["BMW"] */
  hierarchyNodeCodes: string[];
  serviceTypes?: RateServiceType[];
  status?: SessionStatus[];
  limit: number;
  skip: number;
  client: string;
  from?: string;
  to?: string;
  energy?: NumberRangeFilter;
  totalCost?: NumberRangeFilter;
  workTotalCost?: NumberRangeFilter;
  purchaseCost?: NumberRangeFilter;
  entityFilter: ChargingSessionsEntityFilterDto[];
  stationIsPublic?: boolean;
  sortBy?:
    | 'cardNumber'
    | 'cardLabel'
    | 'cardCustomerReference'
    | 'cardComment'
    | 'evseId'
    | 'startTime'
    | 'endTime'
    | 'duration'
    | 'energy'
    | 'totalCost'
    | 'workTotalCost'
    | 'purchaseCost'
    | 'rateServiceType'
    | 'status';
  sortDesc?: boolean;
}

export interface LmChargingSessionListItemDto {
  status: SessionStatus;
  rateServiceType: RateServiceType;
  purchaseCost?: number;
  workTotalCost?: number;
  chargeBoxId: string;
  evseId: string;
  nodeCode: string;
  stationIsExternalHardware: boolean;
  stationIsPublic: boolean;
  transactionId: string;
  startTime: string;
  endTime?: string;
  duration?: number;
  meterStart: number;
  meterStop?: number;
  energy?: number;
}

export interface LmChargingSessionListResultDto {
  allowedServiceTypes: RateServiceType[];
  sessions: LmChargingSessionListItemDto[];
  clientContext: SharedClientContext;
  limit: number;
  skip: number;
  entityFilter: ChargingSessionsEntityFilterDto[];
  canAggregate: boolean;
}

export interface LmChargingSessionSumsResultDto {
  purchaseCost: number;
  workTotalCost?: number;
  totalEnergy: number;
  totalDuration: number;
  total: number;
}

export enum ExportFileType {
  Csv = 'csv',
  Xlsx = 'xlsx',
}

export enum ExportSessionField {
  EvseId = 'evseId',
  StationLocation = 'stationLocation',
  StationRegion = 'stationRegion',
  CardLocation = 'cardLocation',
  CardRegion = 'cardRegion',
  CardComment = 'cardComment',
  StartTime = 'startTime',
  EndTime = 'endTime',
  StartTimeLocal = 'startTimeLocal',
  EndTimeLocal = 'endTimeLocal',
  Duration = 'duration',
  Energy = 'energy',
  RateServiceType = 'rateServiceType',
}

export interface ExportSessionColumn {
  field: ExportSessionField;
  label: string;
  selected: boolean;
}

export enum ExportStationField {
  StationManufacturer = 'stationManufacturer',
  StationModel = 'stationModel',
  ConnectorOutletTypeAndMaxPower = 'connectorOutletTypeAndMaxPower',
  ConnectorType = 'connectorType',
  ConnectorName = 'connectorName',
  PurchaseCost = 'purchaseCost',
  StationStreet = 'stationStreet',
  StationPostalCode = 'stationPostalCode',
  StationCity = 'stationCity',
  StationIsExternalHardware = 'stationIsExternalHardware',
  StationIsPublic = 'stationIsPublic',
}

export interface ExportStationColumn {
  field: ExportStationField;
  label: string;
  selected: boolean;
}

export enum ExportCardField {
  CardNumber = 'cardNumber',
  CardLabel = 'cardLabel',
  CardOwnerName = 'cardOwnerName',
  CardCustomerReference = 'cardCustomerReference',
  CardRateServiceType = 'cardRateServiceType',
  CardTagId = 'cardTagId',
  TotalCost = 'totalCost',
}

export interface ExportCardColumn {
  field: ExportCardField;
  label: string;
  selected: boolean;
}

export interface RateServiceTypeLabel {
  serviceType: RateServiceType;
  label: string;
}

export interface AggregationLabels {
  cardNumber: string;
  sessionsCount: string;
  totalDuration: string;
  startDate: string;
  endDate: string;
  exportedDate: string;
  exportedTime: string;
}

export interface ExportChargingSessionArgsDto {
  fileType: ExportFileType;
  filter: ListChargingSessionsArgsDto;
  sessionFields: ExportSessionColumn[];
  stationFields?: ExportStationColumn[];
  cardFields?: ExportCardColumn[];
  serviceTypeLabels: RateServiceTypeLabel[];
  aggregateByStationLocation: boolean;
  aggregateByCardLocation: boolean;
  aggregateByStationId: boolean;
  aggregateByCardId: boolean;
  aggregationLabels: AggregationLabels;
  timezone: string;
  debugMode?: boolean;
}

export interface GetSessionsEnergyConsumptionArgsDto {
  /** @example ["/BMW/"] */
  hierarchyNodeCodes?: string[];
  /**
   * @default "Europe/Berlin"
   * @example "Europe/Berlin"
   */
  timezone: string;
  client: string;
  from?: string;
  to?: string;
}

export enum TimeGroupResolution {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface SessionsEnergyEntryDto {
  date: string;
  publicEnergy: number;
  employeeEnergy: number;
  workEnergy: number;
  undefinedEnergy: number;
  homeEnergy: number;
}

export interface SumField {
  value: number;
  percentage: number;
}

export interface SessionsEnergySumDto {
  publicEnergy: SumField;
  employeeEnergy: SumField;
  workEnergy: SumField;
  homeEnergy: SumField;
  undefinedEnergy: SumField;
  totalEnergy: SumField;
}

export interface SessionsCountDto {
  publicCount: number;
  employeeCount: number;
  workCount: number;
  undefinedCount: number;
  homeCount: number;
  totalCount: number;
}

export interface GetSessionsEnergyConsumptionResultDto {
  allowedServiceTypes: RateServiceType[];
  resolution: TimeGroupResolution;
  entries: SessionsEnergyEntryDto[];
  sum: SessionsEnergySumDto;
  count: SessionsCountDto;
}

export interface FmChargingSessionListItemDto {
  status: SessionStatus;
  rateServiceType: RateServiceType;
  totalCost?: number;
  cardNumber: string;
  cardLabel: string;
  cardComment: string;
  transactionId: string;
  startTime: string;
  endTime?: string;
  duration?: number;
  meterStart: number;
  meterStop?: number;
  energy?: number;
}

export interface FmChargingSessionListResultDto {
  allowedServiceTypes: RateServiceType[];
  sessions: FmChargingSessionListItemDto[];
  clientContext: SharedClientContext;
  limit: number;
  skip: number;
  entityFilter: ChargingSessionsEntityFilterDto[];
  canAggregate: boolean;
}

export interface FmChargingSessionSumsResultDto {
  totalCost: number;
  totalEnergy: number;
  totalDuration: number;
  total: number;
}

export interface LmFmChargingSessionListItemDto {
  status: SessionStatus;
  rateServiceType: RateServiceType;
  totalCost?: number;
  purchaseCost?: number;
  chargeBoxId: string;
  evseId: string;
  stationClientCode: string;
  stationClientNodeCode: string;
  stationIsExternalHardware: boolean;
  stationIsPublic: boolean;
  cardNumber: string;
  cardLabel: string;
  cardComment: string;
  cardClientCode: string;
  cardClientNodeCode: string;
  transactionId: string;
  startTime: string;
  endTime?: string;
  duration?: number;
  meterStart: number;
  meterStop?: number;
  energy?: number;
}

export interface LmFmChargingSessionListResultDto {
  allowedServiceTypes: RateServiceType[];
  sessions: LmFmChargingSessionListItemDto[];
  allClientHierarchy: SharedClientHierarchyNodeDto[];
  clientContext: SharedClientContext;
  limit: number;
  skip: number;
  entityFilter: ChargingSessionsEntityFilterDto[];
  canAggregate: boolean;
}

export interface LmFmChargingSessionSumsResultDto {
  totalCost: number;
  purchaseCost: number;
  totalEnergy: number;
  totalDuration: number;
  total: number;
}

export interface GetChargingCardsEnergyConsumptionArgsDto {
  /** @example ["/BMW/"] */
  hierarchyNodeCodes?: string[];
  /**
   * @default "Europe/Berlin"
   * @example "Europe/Berlin"
   */
  timezone: string;
  client: string;
  from?: string;
  to?: string;
}

export interface ChargingCardsEnergyConsumptionResultDto {
  resolution: TimeGroupResolution;
  allowedServiceTypes: RateServiceType[];
  entries: SessionsEnergyEntryDto[];
  totalCardsCount: number;
  sessionsCount: SessionsCountDto;
  sessionsSum: SessionsEnergySumDto;
}

export interface ChargingCardChangeLabel {
  badgeNumber: number;
  client: string;
  /** @example ["/BMW/"] */
  hierarchyNodeCodes?: string[];
  label: string;
}

export interface ChargingCardChangeActivation {
  badgeNumber: number;
  client: string;
  /** @example ["/BMW/"] */
  hierarchyNodeCodes?: string[];
  active: boolean;
}

export interface ChargingCardChangeComment {
  badgeNumber: number;
  client: string;
  /** @example ["/BMW/"] */
  hierarchyNodeCodes?: string[];
  comment: string;
}

export interface ChargingCardsEntityFilterDto {
  title?: string;
  type: string;
  id: string;
}

export interface ListChargingCardsArgsDto {
  /** @example ["/BMW/"] */
  hierarchyNodeCodes?: string[];
  serviceTypes?: RateServiceType[];
  /** @example true */
  active?: boolean;
  /** @example true */
  onGoing?: boolean;
  limit: number;
  skip: number;
  client: string;
  from?: string;
  to?: string;
  entityFilter?: ChargingCardsEntityFilterDto[];
  sortBy?: 'active' | 'number' | 'totalEnergy' | 'ownerName' | 'rateSlug' | 'rateServiceType' | 'customerReference' | 'comment' | 'sessionServiceTypes' | 'label';
  sortDesc?: boolean;
}

export interface ServiceTypeEnergy {
  serviceType: RateServiceType;
  energy: number;
}

export interface ChargingCardListItemDto {
  rateServiceType: RateServiceType;
  extId: number;
  number: string;
  label: string;
  active: boolean;
  rateId: number;
  rateName: string;
  rateSlug: string;
  ownerName: string;
  comment: string;
  totalEnergy: number;
  clientHierarchy: string;
  serviceTypeEnergies: ServiceTypeEnergy[];
  onGoingSessions: number;
}

export interface ChargingCardListResultDto {
  allowedServiceTypes: RateServiceType[];
  clientContext: SharedClientContext;
  total: number;
  limit: number;
  skip: number;
  totalEnergy: number;
  can: {
    clientCardWorkEdit: boolean;
  };
  cards: ChargingCardListItemDto[];
  entityFilter: ChargingCardsEntityFilterDto[];
  sessionsPagePrefix: string;
}

export interface StationsCustomNameRequestBody {
  chargeBoxId: string;
  customName: string;
  clientCode: string;
}

export interface ChargePointCustomNameRequestBody {
  chargeBoxId: string;
  connectorId: string;
  customName: string;
  clientCode: string;
  connectorEvseId?: string;
}

export interface ListStationsArgsDto {
  limit: number;
  skip: number;
  client?: string;
  hierarchyNodeCodes: string[];
  from?: string;
  to?: string;
  online?: boolean;
  offline?: boolean;
  failure?: boolean;
  notInOperation?: boolean;
  ac?: boolean;
  dc?: boolean;
  acdc?: boolean;
  chargeBoxIds?: string[];
  search?: string;
  clusterId?: string;
  isPublic?: boolean;
}

export interface StationsListItemDto {
  domainStatus?: ChargeBoxDomainStatus;
  chargeBoxId: string;
  displayName?: string;
  customName?: string;
  clusterDisplayName?: string;
  clusterId?: string;
  nodeCode: string;
  connectorsAvailableCount: number;
  connectorsChargingCount: number;
  connectorsFailureCount: number;
  connectorsNotAvailableCount: number;
  connectorsOccupiedCount: number;
  connectorsUnknownCount: number;
  connectorsTotalCount: number;
  loadMgmtOfClusterActive?: boolean;
  loadManagementActive?: boolean;
  can: {
    remoteReset: boolean;
    remoteChangeAvailability: boolean;
    goToSessions: boolean;
    setCustomName: boolean;
  };
  mergedChargePointType?: string;
  model: string;
  manufacturer: string;
  serialNumber: string;
  isExternalHardware: boolean;
  isPublic: boolean;
  groupId: string;
  groupDomainStatuses: GroupDomainStatusesDto;
  groupMemberType: 'none' | 'main' | 'extension';
}

export interface StationListResultDto {
  can: {
    createSupportTicket: boolean;
  };
  clientContext: SharedClientContext;
  stations: StationsListItemDto[];
  sessionsPagePrefix: string;
  total: number;
  limit: number;
  skip: number;
}

export interface ListChargePointsArgsDto {
  limit: number;
  skip: number;
  client: string;
  hierarchyNodeCodes: string[];
  ac?: boolean;
  dc?: boolean;
  available?: boolean;
  charging?: boolean;
  occupied?: boolean;
  failure?: boolean;
  unavailable?: boolean;
  unknown?: boolean;
  outletTypes?: string[];
  searchText?: string;
  clusterId?: string;
  hideNotInOperation?: boolean;
  isPublic?: boolean;
}

export interface ChargePointsListItemDto {
  domainStatus: ConnectorDomainStatus;
  domainStatusOfStation: ChargeBoxDomainStatus;
  domainSubStatusForNotAvailable: DomainSubStatusForNotAvailable;
  chargePointType: string;
  chargeBoxId: string;
  connectorId: string;
  currentLoad: number;
  currentLoadUnit: string;
  nodeCode: string;
  evseId: string;
  loadLimit: number;
  loadLimitUnit: string;
  possibleChargingPower: string;
  outletType: string;
  maxLoadLimit: number;
  maxLoadLimitUnit: string;
  loadMgmtOfClusterAndStationActive: boolean;
  isAssignedToCluster: boolean;
  clusterId: string;
  isReduced: boolean;
  customName?: string;
  isExternalHardware: boolean;
  isPublic: boolean;
  can: {
    remoteStartSession: boolean;
    remoteStopSession: boolean;
    remoteUnlock: boolean;
    remoteReserve: boolean;
    remoteChangeAvailability: boolean;
    setCustomName: boolean;
    goToSessions: boolean;
  };
}

export interface ChargePointsListResultDto {
  clientContext: SharedClientContext;
  sessionsPagePrefix: string;
  chargePoints: ChargePointsListItemDto[];
  can: {
    createSupportTicket: boolean;
  };
  total: number;
  limit: number;
  skip: number;
}

export interface StationListSummaryRequest {
  code: string;
  hierarchyNodeCodes: string[];
}

export interface StationListSummaryResponse {
  clientContext: SharedClientContext;
  nodeCode: string;
  nodePath: string;
  nodeTitle: string;
  subNodes: number;
  stationsOnline: number;
  stationsOffline: number;
  stationsFailure: number;
  stationsNotInOperation: number;
  connectorsAvailable: number;
  connectorsCharging: number;
  connectorsUnavailable: number;
  connectorsOccupied: number;
  connectorsFailure: number;
  connectorsUnknown: number;
  stationsAssumedEnergy: number;
  can: {
    goToList: boolean;
  };
}

export interface StationSummaryPageRequestDto {
  requestId?: string;
  client: string;
  hierarchyNodeCodes: string[];
  clusterId?: string;
}

export interface StationSummaryClientNodeResponse {
  nodeCode: string;
  nodePath: string;
  nodeTitle: string;
  subNodes: number;
  stationsOnline: number;
  stationsOffline: number;
  stationsFailure: number;
  stationsNotInOperation: number;
  connectorsAvailable: number;
  connectorsCharging: number;
  connectorsUnavailable: number;
  connectorsOccupied: number;
  connectorsFailure: number;
  connectorsUnknown: number;
  stationsAssumedEnergy: number;
  can: {
    goToList: boolean;
  };
}

export interface StationSummaryPageResponseDto {
  clientContext: SharedClientContext;
  summary: StationSummaryClientNodeResponse;
}

export interface StationEnergyPageRequestDto {
  /**
   * @default "Europe/Berlin"
   * @example "Europe/Berlin"
   */
  timezone: string;
  client: string;
  hierarchyNodeCodes: string[];
  from?: string;
  to?: string;
}

export interface StationEnergyPageResponseDto {
  resolution: TimeGroupResolution;
  clientContext: SharedClientContext;
  entries: SessionsEnergyEntryDto[];
  sum: SessionsEnergySumDto;
}

export interface AssignStationToClient {
  code: string;
  clientHierarchy: string;
  evseId: string;
}

export interface CreateYoutrackIssueRequest {
  summary: string;
  description: string;
}

export interface CreateYoutrackIssueResponse {
  ticketId: string;
}

export interface ReflectEvpResourceResultDto {
  assignedCount: number;
  unassignedCount: number;
  unmodifiedCount: number;
  failedCount: number;
  createdAreaCount?: number;
  deletedAreaCount?: number;
}

export interface ReflectEvpClientResponseDto {
  clientCode: string;
  stations: ReflectEvpResourceResultDto;
}

export interface MoveStationsFromAreaToAnother {
  clientCode: string;
  sourceAreaCode: string;
  targeAreaCode: string;
}

export interface InvalidRoleAssignmentDto {
  roleId: string;
  roleName: string;
  permissions: string[];
}

export interface InvalidRoleAssignmentResponse {
  invalidRoles: InvalidRoleAssignmentDto[];
}

export interface NodeAssignmentResponse {
  clients: SharedClientDto[];
}

export interface MsvcRecord {
  version: string;
  environment: string;
  msvc: string;
  msgCount: number;
  consumerCount: number;
}

export interface MsvcInfoResponseDto {
  records: MsvcRecord[];
}

export interface QueueTotalSizesResponse {
  value: number;
}

export interface TranslationItemDto {
  key: string;
  value: string;
}

export interface TranslationNamespaceDto {
  namespace: string;
  items: TranslationItemDto[];
}

export interface TranslationLanguagePayload {
  namespaces: TranslationNamespaceDto[];
}

export interface GitlabProjectListItem {
  title: string;
  projectId: number;
}

export interface GitlabProjectItemDetails {
  title: string;
  projectId: number;
  masterToDevelopChanges: number;
  developToMasterChanges: number;
  devPipelineStatus: string;
  masterPipelineStatus: string;
}

export interface ClientHierarchyPreviewRequest {
  clientCode: string;
  affiliateCode: string;
  clientTitle: string;
  cpmsId: string;
}

export interface ClientHierarchyPreviewResponse {
  hierarchy: SharedClientHierarchyNodeDto[];
}

export enum ClientCpmsIdCheck {
  FOUND_AT_HTB = 'FOUND_AT_HTB',
  FOUND_AT_EVP = 'FOUND_AT_EVP',
  NOT_FOUND_AT_ANY_CPMS = 'NOT_FOUND_AT_ANY_CPMS',
  ALREADY_USED_BY_ANOTHER_CLIENT = 'ALREADY_USED_BY_ANOTHER_CLIENT',
}

export interface ClientCpmsIdCheckResult {
  result: ClientCpmsIdCheck;
}

export interface ClientIndividualRoleDto {
  permissions: Permission[];
  id?: string;
  /**
   * @minLength 4
   * @maxLength 40
   */
  name: string;
  /** @maxLength 1000 */
  description?: string;
}

export interface ClientDto {
  standardRoleIds: string[];
  individualRoles?: ClientIndividualRoleDto[];
  /** @maxLength 100 */
  title: string;
  /** @maxLength 16 */
  code: string;
  affiliateCode: string;
  /** @maxLength 50 */
  cpmsId?: string;
}

export interface ClientListEntryDto {
  /** @maxLength 100 */
  title: string;
  /** @maxLength 16 */
  code: string;
  affiliateCode: string;
  /** @maxLength 50 */
  cpmsId?: string;
}

export interface ClientListResponse {
  affiliateContext: SharedAffiliateContext;
  limit: number;
  skip: number;
  total: number;
  entries: ClientListEntryDto[];
  can: {
    viewRoleList: boolean;
    viewUserList: boolean;
    deleteClient: boolean;
    writeClient: boolean;
    writeIndividualRole: boolean;
    deleteUser: boolean;
    viewClientOrgStructure: boolean;
  };
}

export interface ListClientRoleOptionsRequestDto {
  type: RoleType;
  affiliateCode: string;
  clientCode?: string;
}

export interface ClientRoleOptionDto {
  type: RoleType;
  id: string;
  name: string;
  clientCode?: string;
}

export interface ImportClientResourceDto {
  assignedCount: number;
  unassignedCount: number;
  unmodifiedCount: number;
  notFoundCount: number;
  failedCount: number;
  createdAreaCount?: number;
  deletedAreaCount?: number;
}

export interface ImportClientResourcesResponse {
  stations: ImportClientResourceDto;
  cards: ImportClientResourceDto;
}

export interface AssociatedUsersResponseDto {
  total: number;
  withoutOtherRole: number;
}

export interface AllowedPermissionsResponseDto {
  permissions: Permission[];
}

export interface RoleDto {
  type: RoleType;
  permissions: Permission[];
  affiliateCode: string;
  clientTitle?: string;
  id?: string;
  /**
   * @minLength 4
   * @maxLength 40
   */
  name: string;
  /** @maxLength 1000 */
  description?: string;
  clientCode?: string;
  createdBySystem?: boolean;
}

export interface ListRolesRequestDto {
  types?: RoleType[];
  name?: string;
  clientCode?: string;
  skip: number;
  limit: number;
  affiliateCode: string;
  sortBy?: 'name' | 'type';
  sortDesc?: boolean;
}

export interface ListRoleEntryDto {
  type: RoleType;
  id?: string;
  /**
   * @minLength 4
   * @maxLength 40
   */
  name: string;
  /** @maxLength 1000 */
  description?: string;
  clientCode?: string;
  clientTitle?: string;
  createdBySystem?: boolean;
}

export interface ListRolesResponseDto {
  affiliateContext: SharedAffiliateContext;
  total: number;
  limit: number;
  skip: number;
  can: {
    viewUserList: boolean;
    viewClientList: boolean;
    addAnyRole: boolean;
    editStandardRole: boolean;
    editRootRole: boolean;
    editIndividualRole: boolean;
    deleteStandardRole: boolean;
    deleteRootRole: boolean;
    deleteIndividualRole: boolean;
    writeStandardRole: boolean;
    writeRootRole: boolean;
    writeIndividualRole: boolean;
  };
  roles: ListRoleEntryDto[];
}

export interface DeleteRoleRequestDto {
  id: string;
  affiliateCode: string;
}

export interface OrgUsersListRequest {
  clientCode: string;
  limit: number;
  skip: number;
  search?: string;
  roleIds?: string[];
  client?: boolean;
  root?: boolean;
  sortBy?: 'firstName' | 'mail';
  sortDesc?: boolean;
}

export interface OrgUserRoleDto {
  id: string;
  name: string;
  nodeCodes: string[];
  type: 'root' | 'standard' | 'individual' | 'system' | 'anchor';
}

export interface OrgUserDto {
  firstName: string;
  lastName: string;
  fullName: string;
  mail: string;
  roles: OrgUserRoleDto[];
  defaultLanguage: string;
}

export interface OrgUserRoleOptionDto {
  type: RoleType;
  id: string;
  name: string;
}

export interface OrganisationViewDto {
  title: string;
  code: string;
  accessableNodes: SharedClientHierarchyNodeDto[];
  forbiddenParents: SharedClientHierarchyNodeDto[];
  structure: {
    visible: boolean;
  };
  stations: {
    visible: boolean;
  };
  cluster: {
    visible: boolean;
  };
  cards: {
    visible: boolean;
  };
  users: {
    visible: boolean;
  };
}

export interface OrgUsersListResponse {
  limit: number;
  skip: number;
  total: number;
  users: OrgUserDto[];
  clientRoles: OrgUserRoleOptionDto[];
  clientContext: SharedClientContext;
  organization: OrganisationViewDto;
  defaultLang: string;
}

export interface OrgUserResponse {
  user: OrgUserDto;
  clientContext: SharedClientContext;
}

export enum OrgUserType {
  CURRENT_CLIENT = 'CURRENT_CLIENT',
  NOT_CURRENT_CLIENT = 'NOT_CURRENT_CLIENT',
  NOT_EXISTS = 'NOT_EXISTS',
}

export interface OrgUserWithTypeResponse {
  userType: OrgUserType;
  user?: OrgUserDto;
}

export interface CreateOrgUserDto {
  firstName: string;
  lastName: string;
  mail: string;
  clientCode: string;
  roleIds: string[];
  nodeCodes: string[];
  defaultLanguage: string;
}

export interface UpdateOrgUserAccountDto {
  firstName: string;
  lastName: string;
  mail: string;
  clientCode: string;
  defaultLanguage: string;
}

export interface DeleteOrgUsersDto {
  clientCode: string;
  mail: string;
}

export interface AddOrgUserRolesDto {
  clientCode: string;
  userMail: string;
  roleIds: string[];
  nodeCodes: string[];
}

export interface UpdateOrgUserRoleDto {
  clientCode: string;
  userMail: string;
  roleId: string;
  nodeCodes: string[];
}

export interface DeleteOrgUserRoleDto {
  clientCode: string;
  userMail: string;
  roleId: string;
}

export interface OrgStructureResponse {
  clientContext: SharedClientContext;
  organization: OrganisationViewDto;
  canManageArea: boolean;
}

export interface ManageStructurePermissions {
  manageArea: boolean;
  manageNode: boolean;
}

export interface ClientStructureNodeDto {
  type: 'clientRoot' | 'region' | 'location' | 'area';
  code: string;
  title: string;
  parentCode?: string;
  path?: string;
  cpmsId?: string;
  autoCreated?: boolean;
  can: ManageStructurePermissions;
}

export interface ClientStructureDto {
  title: string;
  code: string;
  accessableNodes: ClientStructureNodeDto[];
  forbiddenParents: ClientStructureNodeDto[];
}

export interface AssignedUsersDTO {
  count: number;
}

export interface CreateNodeDTO {
  clientCode: string;
  parentCode: string;
  /** @maxLength 30 */
  title: string;
  type: 'clientRoot' | 'region' | 'location' | 'area';
  cpmsId: string;
}

export interface UpdateNodeDTO {
  clientCode: string;
  code: string;
  /** @maxLength 30 */
  title: string;
  cpmsId: string;
}

export interface DeleteNodeDTO {
  clientCode: string;
  code: string;
}

export interface OrgStationsListRequest {
  clientCode: string;
  limit: number;
  skip: number;
  search?: string;
  ac?: boolean;
  dc?: boolean;
  acdc?: boolean;
  hierarchyNodeCodes: string[];
  chargeBoxIdsToExclude?: string[];
  isPublic?: boolean;
  sortBy?: 'chargeBoxId' | 'manufacturer' | 'customName';
  sortDesc?: boolean;
}

export interface OrgStationsListItemDto {
  chargeBoxId: string;
  nodeCode: string;
  connectorsTotalCount: number;
  mergedChargePointType: string;
  model: string;
  manufacturer: string;
  serialNumber: string;
  isExternalHardware: boolean;
  isPublic: boolean;
}

export interface OrgStationListResultDto {
  stations: OrgStationsListItemDto[];
  total: number;
  limit: number;
  skip: number;
  organization: OrganisationViewDto;
  clientContext: SharedClientContext;
}

export interface OrgStationsAssignStationToClientDto {
  clientCode: string;
  sourceNodeCodes: string[];
  targetNodeCode: string;
  clientHierarchy: string;
  chargeBoxIds: string[];
}

export interface OrgStationsUnassignStationsFromNodeDto {
  clientCode: string;
  parentCode: string;
  nodeCodes: string[];
  chargeBoxIds: string[];
}

export enum MergedChargePointType {
  AC = 'AC',
  DC = 'DC',
  ACDC = 'AC/DC',
}

export enum InfrastructureLevel {
  Station = 'station',
  Connector = 'connector',
}

export enum ChargeBoxOrConnectorStatus {
  ChargeBoxOnline = 'chargeBoxOnline',
  ChargeBoxOffline = 'chargeBoxOffline',
  ChargeBoxFailure = 'chargeBoxFailure',
  ChargeBoxNotInOperation = 'chargeBoxNotInOperation',
  ConnectorAvailable = 'connectorAvailable',
  ConnectorCharging = 'connectorCharging',
  ConnectorNotAvailableReserved = 'connectorNotAvailableReserved',
  ConnectorNotAvailableLocked = 'connectorNotAvailableLocked',
  ConnectorNotAvailableNone = 'connectorNotAvailableNone',
  ConnectorFailure = 'connectorFailure',
  ConnectorUnknown = 'connectorUnknown',
  ConnectorOccupied = 'connectorOccupied',
}

export interface StatusLogEntityFilterDto {
  title?: string;
  type: string;
  id: string;
}

export enum StatusLogSortableColumnFrontEnd {
  EntityId = 'entityId',
  Manufacturer = 'manufacturer',
  Model = 'model',
  StatusStart = 'statusStart',
}

export interface StatusLogRequest {
  /** @example ["BMW"] */
  hierarchyNodeCodes: string[];
  typeOfCurrent?: MergedChargePointType[];
  infrastructureLevel?: InfrastructureLevel[];
  statuses?: ChargeBoxOrConnectorStatus[];
  entityFilter?: StatusLogEntityFilterDto[];
  ownerTypes?: any[][];
  sortBy?: StatusLogSortableColumnFrontEnd;
  limit?: number;
  skip?: number;
  clientCode: string;
  from?: string;
  to?: string;
  search?: string;
  isPublic?: boolean;
  /** @example false */
  sortDesc?: boolean;
}

export interface StatusLogRecordDto {
  entityId: string;
  client: string;
  clientHierarchy: string;
  isConnector: boolean;
  manufacturer: string;
  model: string;
  typeOfCurrent: 'AC' | 'DC' | 'AC/DC';
  statusStartAt: string;
  status:
    | 'chargeBoxOnline'
    | 'chargeBoxOffline'
    | 'chargeBoxFailure'
    | 'chargeBoxNotInOperation'
    | 'connectorAvailable'
    | 'connectorCharging'
    | 'connectorNotAvailableReserved'
    | 'connectorNotAvailableLocked'
    | 'connectorNotAvailableNone'
    | 'connectorFailure'
    | 'connectorUnknown'
    | 'connectorOccupied';
  isExternalHardware: boolean;
  isPublic: boolean;
}

export interface StatusLogResult {
  clientContext: SharedClientContext;
  entityFilter: StatusLogEntityFilterDto[];
  entries: StatusLogRecordDto[];
  total: number;
  limit: number;
  skip: number;
}

export interface AffiliateListRequest {
  limit: number;
  skip: number;
  search?: string;
  sortBy?: 'title' | 'code';
  sortDesc?: boolean;
}

export interface AffiliateDto {
  permissions: Permission[];
  /** @maxLength 100 */
  title: string;
  /** @maxLength 6 */
  code: string;
  /** @maxLength 50 */
  cpmsId?: string;
  /** @maxLength 2 */
  defaultLanguageCode: string;
}

export interface AffiliateListResponse {
  limit: number;
  skip: number;
  total: number;
  entries: AffiliateDto[];
}

export interface AdminsListRequest {
  limit: number;
  skip: number;
  search?: string;
  sortBy?: 'firstName' | 'mail';
  sortDesc?: boolean;
}

export interface AdminDto {
  mail: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  defaultLanguageCode: string;
}

export interface AdminsListResponse {
  limit: number;
  skip: number;
  total: number;
  entries: AdminDto[];
}

export enum SystemAdminUserType {
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
  NO_SYSTEM_ADMIN = 'NO_SYSTEM_ADMIN',
  NOT_EXISTS = 'NOT_EXISTS',
}

export interface AdminWithTypeDto {
  userType: SystemAdminUserType;
  admin?: AdminDto;
}

export interface AdminDeleteRequest {
  mail: string;
}

export enum MigrationJobStatus {
  InProgress = 'in-progress',
  Success = 'success',
  PartialSuccess = 'partial-success',
  Failed = 'failed',
}

export interface ListEvMigrationJobArgsDto {
  chargeBoxIds?: string[];
  status?: MigrationJobStatus[];
  /**
   * @default 0
   * @example 0
   */
  skip?: number;
  /**
   * @default 100
   * @example 50
   */
  limit?: number;
  sortBy?: 'jobId' | 'createdAt' | 'createdBy' | 'status' | 'finishedAt' | 'lastUpdatedAt';
  sortDesc?: boolean;
}

export enum MigrationLogEntryStatus {
  NotStarted = 'not-started',
  InProgress = 'in-progress',
  Success = 'success',
  Failed = 'failed',
}

export interface EvMigrationLogEntryDTO {
  status: MigrationLogEntryStatus;
  chargeBoxId: string;
  newChargeBoxId: string;
  steps: string[];
  failureReason?: string;
}

export interface EvMigrationJobDto {
  status: MigrationJobStatus;
  jobId: string;
  createdAt: string;
  createdBy: string;
  finishedAt?: string;
  lastUpdatedAt: string;
  entries: EvMigrationLogEntryDTO[];
}

export interface ListEvMigrationJobResultDto {
  total: number;
  limit: number;
  skip: number;
  jobs: EvMigrationJobDto[];
}

export interface EvMigrationJobRequestDto {
  chargeBoxIds: string[];
}

export interface OcppMessageRaw {
  operation: string;
  message: string;
}

export interface OcppMessageResponse {
  status: string;
}

export enum InteractionType {
  AdminClientCreate = 'admin-client-create',
  AdminClientDelete = 'admin-client-delete',
  AdminClientEdit = 'admin-client-edit',
  AdminClientSelectedRolesChange = 'admin-client-selected-roles-change',
  AdminClientRoleCreate = 'admin-client-role-create',
  AdminClientImportResources = 'admin-client-import-resources',
  AdminRoleCreate = 'admin-role-create',
  AdminRoleDelete = 'admin-role-delete',
  AdminRoleEdit = 'admin-role-edit',
  AdminRolePermissionsChange = 'admin-role-permissions-change',
  AdminUserCreate = 'admin-user-create',
  AdminUserDelete = 'admin-user-delete',
  AdminUserEdit = 'admin-user-edit',
  AdminUserSelectedRolesChange = 'admin-user-selected-roles-change',
  OrgUserCreate = 'org-user-create',
  OrgRoleAdd = 'org-role-add',
  OrgRoleChange = 'org-role-change',
  OrgRoleDelete = 'org-role-delete',
  OrgUserDelete = 'org-user-delete',
  OrgUserEdit = 'org-user-edit',
  ChargeCreateTicket = 'charge-create-ticket',
  ChargeRenameStation = 'charge-rename-station',
  ChargeRenameChargepoint = 'charge-rename-chargepoint',
  ChargeRemoteChangeAvailability = 'charge-remote-change-availability',
  ChargeRemoteReset = 'charge-remote-reset',
  ChargeRemoteStartSession = 'charge-remote-start-session',
  ChargeRemoteStopSession = 'charge-remote-stop-session',
  ChargeRemoteReserve = 'charge-remote-reserve',
  ChargeRemoteReserveCancel = 'charge-remote-reserve-cancel',
  ChargeRemoteChangePointAvailability = 'charge-remote-change-point-availability',
  ChargeRemoteUnlock = 'charge-remote-unlock',
  ClusterRemoteChangeAvailability = 'cluster-remote-change-availability',
  ClusterRemoteReset = 'cluster-remote-reset',
  ClusterRemoteStartSession = 'cluster-remote-start-session',
  ClusterRemoteStopSession = 'cluster-remote-stop-session',
  ClusterRemoteReserve = 'cluster-remote-reserve',
  ClusterRemoteReserveCancel = 'cluster-remote-reserve-cancel',
  ClusterRemoteChangePointAvailability = 'cluster-remote-change-point-availability',
  ClusterRemoteUnlock = 'cluster-remote-unlock',
  BadgeLabelChange = 'badge-label-change',
  BadgeActivationChange = 'badge-activation-change',
  BadgeCommentChange = 'badge-comment-change',
}

export enum InteractionLogsSortableColumn {
  InteractionType = 'interactionType',
  UserMail = 'userMail',
  Client = 'client',
  CreatedAt = 'createdAt',
}

export interface SearchInteractionsLogsDto {
  interactionType?: InteractionType;
  sortBy?: InteractionLogsSortableColumn;
  sortDesc?: boolean;
  /**
   * @min 1
   * @max 100
   */
  limit: number;
  /** @min 0 */
  skip: number;
  search?: string;
  userMail?: string;
  affiliate?: string;
  client?: string;
  fullClient?: string;
  from?: string;
  to?: string;
  page?: string;
  action?: string;
}

export enum InteractionPage {
  Admin = 'admin',
  Charge = 'charge',
  Org = 'org',
  Cluster = 'cluster',
  Badge = 'badge',
}

export enum InteractionsLogsStatus {
  Success = 'success',
  Failed = 'failed',
  Partial = 'partial',
  Unknown = 'unknown',
}

export interface InteractionLogDto {
  interactionType: InteractionType;
  page: InteractionPage;
  status?: InteractionsLogsStatus;
  userMail: string;
  client: string;
  data: object;
  searchHint?: string;
  createdAt?: string;
  _id?: string;
}

export interface InteractionLogsSearchResponse {
  intercationLog: InteractionLogDto[];
  total: number;
  limit: number;
  skip: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'include',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    const expandedValue = value.map((v: string) => encodeURIComponent(v)).join(',');
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${expandedValue}`;
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key))).join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) => (input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(key, property instanceof Blob ? property : typeof property === 'object' && property !== null ? JSON.stringify(property) : `${property}`);
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({ body, secure, path, type, query, format, baseUrl, cancelToken, ...params }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams = ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    let responseFormat = format || requestParams.format;
    if (cancelToken) {
      this.abortRequest(cancelToken);
    }
    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      if (response && response.headers && response.headers.has('content-type')) {
        const contentType = response.headers.get('content-type') || '';
        if (contentType.indexOf(ContentType.Json) >= 0) {
          responseFormat = 'json';
        } else if (contentType.indexOf('application') >= 0) {
          responseFormat = 'blob';
        }
      }

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              if (r.error && !(r.error as any).statusCode) {
                (r.error as any).statusCode = r.status;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              if (r.error && !(r.error as any).statusCode) {
                (r.error as any).statusCode = r.status;
              }
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title backend undefined undefined
 * @version local-build
 * @contact
 *
 * The backend API description
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  healthcheck = {
    /**
     * @description Healthcheck of the CSP API
     *
     * @tags healthcheck
     * @name Healthcheck
     * @request GET:/healthcheck
     * @secure
     */
    healthcheck: (params: RequestParams = {}) =>
      this.request<HealtcheckResponseDto, ApiError>({
        path: `/healthcheck`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags auth
     * @name LoginWithCognito
     * @request GET:/auth/cognito/login
     * @secure
     */
    loginWithCognito: (params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/auth/cognito/login`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name LoginCognitoReturn
     * @request GET:/auth/cognito/login/return
     * @secure
     */
    loginCognitoReturn: (params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/auth/cognito/login/return`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name LogoutWithCognito
     * @request GET:/auth/cognito/logout
     * @secure
     */
    logoutWithCognito: (params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/auth/cognito/logout`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name Logout
     * @request GET:/auth/cognito/logout/return
     * @secure
     */
    logout: (params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/auth/cognito/logout/return`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  profile = {
    /**
     * No description
     *
     * @tags user
     * @name GetProfile
     * @request GET:/profile/profile
     * @secure
     */
    getProfile: (params: RequestParams = {}) =>
      this.request<UserProfileDto, ApiError>({
        path: `/profile/profile`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetTranslationEtag
     * @request GET:/profile/translations/{lang}/etag
     * @secure
     */
    getTranslationEtag: (lang: string, params: RequestParams = {}) =>
      this.request<UiTranslationEtagResponse, ApiError>({
        path: `/profile/translations/${lang}/etag`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetTranslations
     * @request GET:/profile/translations/{lang}
     * @secure
     */
    getTranslations: (lang: string, params: RequestParams = {}) =>
      this.request<UiTranslationResponse, ApiError>({
        path: `/profile/translations/${lang}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name AccessibleClientNodes
     * @request POST:/profile/client-nodes
     * @secure
     */
    accessibleClientNodes: (data: ListAccessibleClientNodesRequest, params: RequestParams = {}) =>
      this.request<SharedClientDto[], ApiError>({
        path: `/profile/client-nodes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name AccessibleAffiliates
     * @request POST:/profile/affiliates
     * @secure
     */
    accessibleAffiliates: (data: ListAccessibleClientNodesRequest, params: RequestParams = {}) =>
      this.request<SharedAffiliateDto[], ApiError>({
        path: `/profile/affiliates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ChangeLanguage
     * @request PUT:/profile/preferences/language/{languageCode}
     * @secure
     */
    changeLanguage: (languageCode: string, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/profile/preferences/language/${languageCode}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetPreferencesForClient
     * @request PUT:/profile/preferences/client/{clientCode}
     * @secure
     */
    getPreferencesForClient: (clientCode: string, params: RequestParams = {}) =>
      this.request<UserClientPreferencesDto, ApiError>({
        path: `/profile/preferences/client/${clientCode}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetPreferences
     * @request GET:/profile/preferences
     * @secure
     */
    getPreferences: (params: RequestParams = {}) =>
      this.request<UserPreferencesDto, ApiError>({
        path: `/profile/preferences`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetNodeResourcesCounts
     * @request POST:/profile/node-resources
     * @secure
     */
    getNodeResourcesCounts: (data: NodeResourcesRequestDto, params: RequestParams = {}) =>
      this.request<NodeResourcesResponseDto, ApiError>({
        path: `/profile/node-resources`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags users
     * @name List
     * @request POST:/users/search
     * @secure
     */
    list: (data: UsersListRequest, params: RequestParams = {}) =>
      this.request<UsersListResponse, ApiError>({
        path: `/users/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name ListRoles
     * @request GET:/users/{affiliateCode}/root-roles
     * @secure
     */
    listRoles: (affiliateCode: string, params: RequestParams = {}) =>
      this.request<UserRoleOptionDto[], ApiError>({
        path: `/users/${affiliateCode}/root-roles`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name GetUser
     * @request GET:/users/{affiliateCode}/{mail}
     * @secure
     */
    getUser: (affiliateCode: string, mail: string, params: RequestParams = {}) =>
      this.request<RootUserDto, ApiError>({
        path: `/users/${affiliateCode}/${mail}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name GetMultiAffiliateUserIfExists
     * @request GET:/users/{affiliateCode}/multi-affiliate-user/{mail}
     * @secure
     */
    getMultiAffiliateUserIfExists: (affiliateCode: string, mail: string, params: RequestParams = {}) =>
      this.request<UserWithTypeResponse, ApiError>({
        path: `/users/${affiliateCode}/multi-affiliate-user/${mail}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name DeleteUser
     * @request DELETE:/users/{affiliateCode}/multi-affiliate-user/{mail}
     * @secure
     */
    deleteUser: (affiliateCode: string, mail: string, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/users/${affiliateCode}/multi-affiliate-user/${mail}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name AddRootUser
     * @request POST:/users/root-user
     * @secure
     */
    addRootUser: (data: RootUserDto, params: RequestParams = {}) =>
      this.request<RootUserDto, ApiError>({
        path: `/users/root-user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UpdateRootUser
     * @request PUT:/users/root-user
     * @secure
     */
    updateRootUser: (data: RootUserDto, params: RequestParams = {}) =>
      this.request<RootUserDto, ApiError>({
        path: `/users/root-user`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name GetPermissionsOfRole
     * @request GET:/users/permissions/{affiliateCode}/{roleId}
     * @secure
     */
    getPermissionsOfRole: (affiliateCode: string, roleId: string, params: RequestParams = {}) =>
      this.request<PermissionsOfRoleResponse, ApiError>({
        path: `/users/permissions/${affiliateCode}/${roleId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name Export
     * @request POST:/users/export
     * @secure
     */
    export: (data: UsersListRequest, params: RequestParams = {}) =>
      this.request<File, ApiError>({
        path: `/users/export`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  clusterList = {
    /**
     * No description
     *
     * @tags cluster
     * @name GetClusterList
     * @request POST:/cluster-list/search
     * @secure
     */
    getClusterList: (data: ListClusterArgsDto, params: RequestParams = {}) =>
      this.request<ClusterListResultDto, ApiError>({
        path: `/cluster-list/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  clusterDetail = {
    /**
     * No description
     *
     * @tags cluster
     * @name GetSummary
     * @request POST:/cluster-detail/summary
     * @secure
     */
    getSummary: (data: ClusterSummaryRequestDto, params: RequestParams = {}) =>
      this.request<ClusterSummaryResponseDto, ApiError>({
        path: `/cluster-detail/summary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name GetStationGroupExtensions
     * @request POST:/cluster-detail/group-extensions
     * @secure
     */
    getStationGroupExtensions: (data: GetStationGroupExtensionsArgsDto, params: RequestParams = {}) =>
      this.request<GetStationGroupExtensionsResultDto, ApiError>({
        path: `/cluster-detail/group-extensions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name ListClusterStations
     * @request POST:/cluster-detail/list-cluster-stations
     * @secure
     */
    listClusterStations: (data: ListClusterStationsArgsDto, params: RequestParams = {}) =>
      this.request<ClusterStationListResultDto, ApiError>({
        path: `/cluster-detail/list-cluster-stations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name ListClusterChargepoints
     * @request POST:/cluster-detail/list-cluster-chargepoints
     * @secure
     */
    listClusterChargepoints: (data: ListClusterChargePointsArgsDto, params: RequestParams = {}) =>
      this.request<ClusterChargePointsListResultDto, ApiError>({
        path: `/cluster-detail/list-cluster-chargepoints`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name GetClusterDetails
     * @request POST:/cluster-detail/details
     * @secure
     */
    getClusterDetails: (data: ClusterDetailsArgsDto, params: RequestParams = {}) =>
      this.request<ClusterDetailResultDto, ApiError>({
        path: `/cluster-detail/details`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name GetChargepointsOfStation
     * @request POST:/cluster-detail/chargepoints-of-station
     * @secure
     */
    getChargepointsOfStation: (data: ChargepointsOfStationsArgsDto, params: RequestParams = {}) =>
      this.request<ChargePointsOfStationResultDto, ApiError>({
        path: `/cluster-detail/chargepoints-of-station`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name GetCards
     * @request GET:/cluster-detail/search-cards/{client}
     * @secure
     */
    getCards: (
      client: string,
      query: {
        search: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CardSearchListResultDto, ApiError>({
        path: `/cluster-detail/search-cards/${client}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name RemoteChangeStationAvailability
     * @request PUT:/cluster-detail/remote/change-availability
     * @secure
     */
    remoteChangeStationAvailability: (data: RemoteChangeStationAvailabilityRequest, params: RequestParams = {}) =>
      this.request<ChangeStationAvailabilityResponse, ApiError>({
        path: `/cluster-detail/remote/change-availability`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name ResetStation
     * @request PUT:/cluster-detail/remote/reset/{evseId}
     * @secure
     */
    resetStation: (evseId: string, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/cluster-detail/remote/reset/${evseId}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name RemoteStartSession
     * @request PUT:/cluster-detail/remote/start-session
     * @secure
     */
    remoteStartSession: (data: RemoteStartSessionRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/cluster-detail/remote/start-session`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name RemoteStopSession
     * @request PUT:/cluster-detail/remote/stop-session
     * @secure
     */
    remoteStopSession: (data: RemoteStopSessionRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/cluster-detail/remote/stop-session`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name RemoteReserve
     * @request PUT:/cluster-detail/remote/reserve
     * @secure
     */
    remoteReserve: (data: RemoteReserveRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/cluster-detail/remote/reserve`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name RemoteReserveCancel
     * @request PUT:/cluster-detail/remote/reserve-cancel
     * @secure
     */
    remoteReserveCancel: (data: RemoteCancelReserveRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/cluster-detail/remote/reserve-cancel`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name RemoteUnlock
     * @request PUT:/cluster-detail/remote/unlock
     * @secure
     */
    remoteUnlock: (data: RemoteUnlockRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/cluster-detail/remote/unlock`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cluster
     * @name RemoteChangeChargePointAvailability
     * @request PUT:/cluster-detail/remote/change-charge-point-availability
     * @secure
     */
    remoteChangeChargePointAvailability: (data: RemoteChangeChargePointAvailabilityRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/cluster-detail/remote/change-charge-point-availability`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  lmChargingSessions = {
    /**
     * No description
     *
     * @tags lm-charging-sessions
     * @name List
     * @request POST:/lm-charging-sessions/search
     * @secure
     */
    list: (data: ListChargingSessionsArgsDto, params: RequestParams = {}) =>
      this.request<LmChargingSessionListResultDto, ApiError>({
        path: `/lm-charging-sessions/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags lm-charging-sessions
     * @name Sums
     * @request POST:/lm-charging-sessions/sums
     * @secure
     */
    sums: (data: ListChargingSessionsArgsDto, params: RequestParams = {}) =>
      this.request<LmChargingSessionSumsResultDto, ApiError>({
        path: `/lm-charging-sessions/sums`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags lm-charging-sessions
     * @name Export
     * @request POST:/lm-charging-sessions/export
     * @secure
     */
    export: (data: ExportChargingSessionArgsDto, params: RequestParams = {}) =>
      this.request<File, ApiError>({
        path: `/lm-charging-sessions/export`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags lm-charging-sessions
     * @name EnergyConsumption
     * @request POST:/lm-charging-sessions/consumption
     * @secure
     */
    energyConsumption: (data: GetSessionsEnergyConsumptionArgsDto, params: RequestParams = {}) =>
      this.request<GetSessionsEnergyConsumptionResultDto, ApiError>({
        path: `/lm-charging-sessions/consumption`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags lm-charging-sessions
     * @name SearchFilters
     * @request GET:/lm-charging-sessions/search-filters
     * @secure
     */
    searchFilters: (
      query: {
        q: string;
        clientCode: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ChargingSessionsEntityFilterDto[], ApiError>({
        path: `/lm-charging-sessions/search-filters`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  fmChargingSessions = {
    /**
     * No description
     *
     * @tags fm-charging-sessions
     * @name List
     * @request POST:/fm-charging-sessions/search
     * @secure
     */
    list: (data: ListChargingSessionsArgsDto, params: RequestParams = {}) =>
      this.request<FmChargingSessionListResultDto, ApiError>({
        path: `/fm-charging-sessions/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags fm-charging-sessions
     * @name Sums
     * @request POST:/fm-charging-sessions/sums
     * @secure
     */
    sums: (data: ListChargingSessionsArgsDto, params: RequestParams = {}) =>
      this.request<FmChargingSessionSumsResultDto, ApiError>({
        path: `/fm-charging-sessions/sums`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags fm-charging-sessions
     * @name Export
     * @request POST:/fm-charging-sessions/export
     * @secure
     */
    export: (data: ExportChargingSessionArgsDto, params: RequestParams = {}) =>
      this.request<File, ApiError>({
        path: `/fm-charging-sessions/export`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags fm-charging-sessions
     * @name EnergyConsumption
     * @request POST:/fm-charging-sessions/consumption
     * @secure
     */
    energyConsumption: (data: GetSessionsEnergyConsumptionArgsDto, params: RequestParams = {}) =>
      this.request<GetSessionsEnergyConsumptionResultDto, ApiError>({
        path: `/fm-charging-sessions/consumption`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags fm-charging-sessions
     * @name SearchFilters
     * @request GET:/fm-charging-sessions/search-filters
     * @secure
     */
    searchFilters: (
      query: {
        q: string;
        clientCode: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ChargingSessionsEntityFilterDto[], ApiError>({
        path: `/fm-charging-sessions/search-filters`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  lmfmChargingSessions = {
    /**
     * No description
     *
     * @tags lmfm-charging-sessions
     * @name List
     * @request POST:/lmfm-charging-sessions/search
     * @secure
     */
    list: (data: ListChargingSessionsArgsDto, params: RequestParams = {}) =>
      this.request<LmFmChargingSessionListResultDto, ApiError>({
        path: `/lmfm-charging-sessions/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags lmfm-charging-sessions
     * @name Sums
     * @request POST:/lmfm-charging-sessions/sums
     * @secure
     */
    sums: (data: ListChargingSessionsArgsDto, params: RequestParams = {}) =>
      this.request<LmFmChargingSessionSumsResultDto, ApiError>({
        path: `/lmfm-charging-sessions/sums`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags lmfm-charging-sessions
     * @name Export
     * @request POST:/lmfm-charging-sessions/export
     * @secure
     */
    export: (data: ExportChargingSessionArgsDto, params: RequestParams = {}) =>
      this.request<File, ApiError>({
        path: `/lmfm-charging-sessions/export`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags lmfm-charging-sessions
     * @name EnergyConsumption
     * @request POST:/lmfm-charging-sessions/consumption
     * @secure
     */
    energyConsumption: (data: GetSessionsEnergyConsumptionArgsDto, params: RequestParams = {}) =>
      this.request<GetSessionsEnergyConsumptionResultDto, ApiError>({
        path: `/lmfm-charging-sessions/consumption`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags lmfm-charging-sessions
     * @name SearchFilters
     * @request GET:/lmfm-charging-sessions/search-filters
     * @secure
     */
    searchFilters: (
      query: {
        q: string;
        clientCode: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ChargingSessionsEntityFilterDto[], ApiError>({
        path: `/lmfm-charging-sessions/search-filters`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  chargingCards = {
    /**
     * No description
     *
     * @tags charging-cards
     * @name EnergyConsumption
     * @request POST:/charging-cards/consumption
     * @secure
     */
    energyConsumption: (data: GetChargingCardsEnergyConsumptionArgsDto, params: RequestParams = {}) =>
      this.request<ChargingCardsEnergyConsumptionResultDto, ApiError>({
        path: `/charging-cards/consumption`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags charging-cards
     * @name ChangeLabel
     * @request POST:/charging-cards/change/label
     * @secure
     */
    changeLabel: (data: ChargingCardChangeLabel, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/charging-cards/change/label`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags charging-cards
     * @name ChangeActivation
     * @request POST:/charging-cards/change/activation
     * @secure
     */
    changeActivation: (data: ChargingCardChangeActivation, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/charging-cards/change/activation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags charging-cards
     * @name ChangeComment
     * @request POST:/charging-cards/change/comment
     * @secure
     */
    changeComment: (data: ChargingCardChangeComment, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/charging-cards/change/comment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags charging-cards
     * @name List
     * @request POST:/charging-cards/search
     * @secure
     */
    list: (data: ListChargingCardsArgsDto, params: RequestParams = {}) =>
      this.request<ChargingCardListResultDto, ApiError>({
        path: `/charging-cards/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags charging-cards
     * @name SearchFilters
     * @request GET:/charging-cards/search-filters
     * @secure
     */
    searchFilters: (
      query: {
        q: string;
        clientCode: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ChargingCardsEntityFilterDto[], ApiError>({
        path: `/charging-cards/search-filters`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  station = {
    /**
     * No description
     *
     * @tags station, chargepoint
     * @name SetCustomNameForStation
     * @request PUT:/station/station/set-custom-name
     * @secure
     */
    setCustomNameForStation: (data: StationsCustomNameRequestBody, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/station/station/set-custom-name`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name SetCustomNameForChargepoint
     * @request PUT:/station/chargepoint/set-custom-name
     * @secure
     */
    setCustomNameForChargepoint: (data: ChargePointCustomNameRequestBody, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/station/chargepoint/set-custom-name`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name ListStations
     * @request POST:/station/search-stations
     * @secure
     */
    listStations: (data: ListStationsArgsDto, params: RequestParams = {}) =>
      this.request<StationListResultDto, ApiError>({
        path: `/station/search-stations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name ListChargePoints
     * @request POST:/station/search-charge-points
     * @secure
     */
    listChargePoints: (data: ListChargePointsArgsDto, params: RequestParams = {}) =>
      this.request<ChargePointsListResultDto, ApiError>({
        path: `/station/search-charge-points`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name GetSummary
     * @request POST:/station/summary
     * @secure
     */
    getSummary: (data: StationListSummaryRequest, params: RequestParams = {}) =>
      this.request<StationListSummaryResponse, ApiError>({
        path: `/station/summary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name RemoteReserveCancel
     * @request PUT:/station/remote/reserve-cancel
     * @secure
     */
    remoteReserveCancel: (data: RemoteCancelReserveRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/station/remote/reserve-cancel`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name RemoteReserve
     * @request PUT:/station/remote/reserve
     * @secure
     */
    remoteReserve: (data: RemoteReserveRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/station/remote/reserve`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name RemoteUnlock
     * @request PUT:/station/remote/unlock
     * @secure
     */
    remoteUnlock: (data: RemoteUnlockRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/station/remote/unlock`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name GetCards
     * @request GET:/station/search-cards/{client}
     * @secure
     */
    getCards: (
      client: string,
      query: {
        search: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CardSearchListResultDto, ApiError>({
        path: `/station/search-cards/${client}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name GetStationGroupExtensions
     * @request POST:/station/station/group-extensions
     * @secure
     */
    getStationGroupExtensions: (data: GetStationGroupExtensionsArgsDto, params: RequestParams = {}) =>
      this.request<GetStationGroupExtensionsResultDto, ApiError>({
        path: `/station/station/group-extensions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name GetChargepointsOfStation
     * @request POST:/station/chargepoints-of-station
     * @secure
     */
    getChargepointsOfStation: (data: ChargepointsOfStationsArgsDto, params: RequestParams = {}) =>
      this.request<ChargePointsOfStationResultDto, ApiError>({
        path: `/station/chargepoints-of-station`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name ResetStation
     * @request PUT:/station/remote/reset/{evseId}
     * @secure
     */
    resetStation: (evseId: string, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/station/remote/reset/${evseId}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name RemoteChangeStationAvailability
     * @request PUT:/station/remote/change-availability
     * @secure
     */
    remoteChangeStationAvailability: (data: RemoteChangeStationAvailabilityRequest, params: RequestParams = {}) =>
      this.request<ChangeStationAvailabilityResponse, ApiError>({
        path: `/station/remote/change-availability`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name RemoteChangeChargePointAvailability
     * @request PUT:/station/remote/change-charge-point-availability
     * @secure
     */
    remoteChangeChargePointAvailability: (data: RemoteChangeChargePointAvailabilityRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/station/remote/change-charge-point-availability`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name RemoteStartSession
     * @request PUT:/station/remote/start-session
     * @secure
     */
    remoteStartSession: (data: RemoteStartSessionRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/station/remote/start-session`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags station, chargepoint
     * @name RemoteStopSession
     * @request PUT:/station/remote/stop-session
     * @secure
     */
    remoteStopSession: (data: RemoteStopSessionRequest, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/station/remote/stop-session`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  stationsSummary = {
    /**
     * No description
     *
     * @tags stations-summary
     * @name GetSummary
     * @request POST:/stations-summary
     * @secure
     */
    getSummary: (data: StationSummaryPageRequestDto, params: RequestParams = {}) =>
      this.request<StationSummaryPageResponseDto, ApiError>({
        path: `/stations-summary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  stationsEnergy = {
    /**
     * No description
     *
     * @tags stations-energy
     * @name GetEnergy
     * @request POST:/stations-energy
     * @secure
     */
    getEnergy: (data: StationEnergyPageRequestDto, params: RequestParams = {}) =>
      this.request<StationEnergyPageResponseDto, ApiError>({
        path: `/stations-energy`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  devTools = {
    /**
     * No description
     *
     * @tags dev-tools
     * @name AssignStationToClient
     * @request POST:/dev-tools/assign-station
     * @secure
     */
    assignStationToClient: (data: AssignStationToClient, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/dev-tools/assign-station`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name CreateYoutrackIssue
     * @request POST:/dev-tools/youtrack/create-ticket
     * @secure
     */
    createYoutrackIssue: (data: CreateYoutrackIssueRequest, params: RequestParams = {}) =>
      this.request<CreateYoutrackIssueResponse, ApiError>({
        path: `/dev-tools/youtrack/create-ticket`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name ReflectEvpResources
     * @request POST:/dev-tools/evp/reflect/resources
     * @secure
     */
    reflectEvpResources: (params: RequestParams = {}) =>
      this.request<ReflectEvpClientResponseDto[], ApiError>({
        path: `/dev-tools/evp/reflect/resources`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name MoveStationsFromAreaToAnother
     * @request POST:/dev-tools/move-stations-to-area
     * @secure
     */
    moveStationsFromAreaToAnother: (data: MoveStationsFromAreaToAnother, params: RequestParams = {}) =>
      this.request<number, ApiError>({
        path: `/dev-tools/move-stations-to-area`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name RemoveInvalidPermissionAssociations
     * @request DELETE:/dev-tools/invalid-role-permission
     * @secure
     */
    removeInvalidPermissionAssociations: (params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/dev-tools/invalid-role-permission`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name GetInvalidPermissionAssociations
     * @request GET:/dev-tools/invalid-role-permission
     * @secure
     */
    getInvalidPermissionAssociations: (params: RequestParams = {}) =>
      this.request<InvalidRoleAssignmentResponse, ApiError>({
        path: `/dev-tools/invalid-role-permission`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name GetClientHierarchues
     * @request GET:/dev-tools/client-hierarchies
     * @secure
     */
    getClientHierarchues: (params: RequestParams = {}) =>
      this.request<NodeAssignmentResponse, ApiError>({
        path: `/dev-tools/client-hierarchies`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name ListVersions
     * @request GET:/dev-tools/versions
     * @secure
     */
    listVersions: (params: RequestParams = {}) =>
      this.request<MsvcInfoResponseDto, ApiError>({
        path: `/dev-tools/versions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name GetTotalQueueLength
     * @request GET:/dev-tools/queue-total-size
     * @secure
     */
    getTotalQueueLength: (params: RequestParams = {}) =>
      this.request<QueueTotalSizesResponse, ApiError>({
        path: `/dev-tools/queue-total-size`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name SeedRma
     * @request POST:/dev-tools/seed/rma
     * @secure
     */
    seedRma: (params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/dev-tools/seed/rma`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name SeedHtbDataService
     * @request POST:/dev-tools/seed/htb-data-service
     * @secure
     */
    seedHtbDataService: (params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/dev-tools/seed/htb-data-service`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name StoreTranslations
     * @request POST:/dev-tools/translations/{lang}
     * @secure
     */
    storeTranslations: (lang: string, data: TranslationLanguagePayload, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/dev-tools/translations/${lang}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name GetTranslations
     * @request GET:/dev-tools/translations/{lang}
     * @secure
     */
    getTranslations: (lang: string, params: RequestParams = {}) =>
      this.request<TranslationNamespaceDto[], ApiError>({
        path: `/dev-tools/translations/${lang}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name GetStationConnectorStats
     * @request GET:/dev-tools/cp-conn-stats/{chargeBoxId}
     * @secure
     */
    getStationConnectorStats: (chargeBoxId: string, params: RequestParams = {}) =>
      this.request<object, ApiError>({
        path: `/dev-tools/cp-conn-stats/${chargeBoxId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name ListDeploymentProjects
     * @request GET:/dev-tools/deployment/projects
     * @secure
     */
    listDeploymentProjects: (params: RequestParams = {}) =>
      this.request<GitlabProjectListItem[], ApiError>({
        path: `/dev-tools/deployment/projects`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-tools
     * @name ListDeploymentProjectDetail
     * @request GET:/dev-tools/deployment/projects/{projectId}
     * @secure
     */
    listDeploymentProjectDetail: (projectId: number, params: RequestParams = {}) =>
      this.request<GitlabProjectItemDetails, ApiError>({
        path: `/dev-tools/deployment/projects/${projectId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  client = {
    /**
     * No description
     *
     * @tags client
     * @name CreateHiearchyPreview
     * @request POST:/client/hierarchy-preview
     * @secure
     */
    createHiearchyPreview: (data: ClientHierarchyPreviewRequest, params: RequestParams = {}) =>
      this.request<ClientHierarchyPreviewResponse, ApiError>({
        path: `/client/hierarchy-preview`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name CpmsIdValidate
     * @request GET:/client/cpmsId-validate/{affiliateCode}
     * @secure
     */
    cpmsIdValidate: (
      affiliateCode: string,
      query: {
        cpmsId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ClientCpmsIdCheckResult, ApiError>({
        path: `/client/cpmsId-validate/${affiliateCode}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name CodeExists
     * @request GET:/client/code-exists/{affiliateCode}
     * @secure
     */
    codeExists: (
      affiliateCode: string,
      query: {
        code: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ApiError>({
        path: `/client/code-exists/${affiliateCode}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name TitleExists
     * @request GET:/client/title-exists/{affiliateCode}
     * @secure
     */
    titleExists: (
      affiliateCode: string,
      query: {
        title: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ApiError>({
        path: `/client/title-exists/${affiliateCode}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name Get
     * @request GET:/client/{affiliateCode}/{code}
     * @secure
     */
    get: (affiliateCode: string, code: string, params: RequestParams = {}) =>
      this.request<ClientDto, ApiError>({
        path: `/client/${affiliateCode}/${code}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name Delete
     * @request DELETE:/client/{affiliateCode}/{code}
     * @secure
     */
    delete: (affiliateCode: string, code: string, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/client/${affiliateCode}/${code}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name List
     * @request GET:/client
     * @secure
     */
    list: (
      query: {
        limit: number;
        skip: number;
        search?: string;
        affiliateCode: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ClientListResponse, ApiError>({
        path: `/client`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name Add
     * @request POST:/client
     * @secure
     */
    add: (data: ClientDto, params: RequestParams = {}) =>
      this.request<ClientDto, ApiError>({
        path: `/client`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name Update
     * @request PUT:/client
     * @secure
     */
    update: (data: ClientDto, params: RequestParams = {}) =>
      this.request<ClientDto, ApiError>({
        path: `/client`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name ListRoles
     * @request POST:/client/list-roles
     * @secure
     */
    listRoles: (data: ListClientRoleOptionsRequestDto, params: RequestParams = {}) =>
      this.request<ClientRoleOptionDto[], ApiError>({
        path: `/client/list-roles`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name ImportClient
     * @request POST:/client/import-client
     * @secure
     */
    importClient: (data: ClientDto, params: RequestParams = {}) =>
      this.request<ClientDto, ApiError>({
        path: `/client/import-client`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name ImportClientResources
     * @request POST:/client/import-resources/{affiliateCode}/{code}
     * @secure
     */
    importClientResources: (affiliateCode: string, code: string, params: RequestParams = {}) =>
      this.request<ImportClientResourcesResponse, ApiError>({
        path: `/client/import-resources/${affiliateCode}/${code}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name GetPermissionsOfRole
     * @request GET:/client/permissions/{affiliateCode}/{roleId}
     * @secure
     */
    getPermissionsOfRole: (affiliateCode: string, roleId: string, params: RequestParams = {}) =>
      this.request<PermissionsOfRoleResponse, ApiError>({
        path: `/client/permissions/${affiliateCode}/${roleId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  role = {
    /**
     * No description
     *
     * @tags role
     * @name NameExists
     * @request GET:/role/name-exists/{affiliateCode}
     * @secure
     */
    nameExists: (
      affiliateCode: string,
      query: {
        name: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ApiError>({
        path: `/role/name-exists/${affiliateCode}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags role
     * @name IsRoleAssignedToCurrentUser
     * @request GET:/role/is-role-assiged-to-current-user/{affiliateCode}
     * @secure
     */
    isRoleAssignedToCurrentUser: (
      affiliateCode: string,
      query: {
        roleId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ApiError>({
        path: `/role/is-role-assiged-to-current-user/${affiliateCode}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags role
     * @name GetAssociatedUsers
     * @request GET:/role/associated-clients/{affiliateCode}
     * @secure
     */
    getAssociatedUsers: (
      affiliateCode: string,
      query: {
        roleId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AssociatedUsersResponseDto, ApiError>({
        path: `/role/associated-clients/${affiliateCode}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags role
     * @name GetAllowedPermissions
     * @request GET:/role/allowed-permissions/{affiliateCode}
     * @secure
     */
    getAllowedPermissions: (affiliateCode: string, params: RequestParams = {}) =>
      this.request<AllowedPermissionsResponseDto, ApiError>({
        path: `/role/allowed-permissions/${affiliateCode}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags role
     * @name Get
     * @request GET:/role/{affiliateCode}/{id}
     * @secure
     */
    get: (affiliateCode: string, id: string, params: RequestParams = {}) =>
      this.request<RoleDto, ApiError>({
        path: `/role/${affiliateCode}/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags role
     * @name GetPermissionsOfRole
     * @request GET:/role/permissions/{affiliateCode}/{roleId}
     * @secure
     */
    getPermissionsOfRole: (affiliateCode: string, roleId: string, params: RequestParams = {}) =>
      this.request<PermissionsOfRoleResponse, ApiError>({
        path: `/role/permissions/${affiliateCode}/${roleId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags role
     * @name Search
     * @request POST:/role/search
     * @secure
     */
    search: (data: ListRolesRequestDto, params: RequestParams = {}) =>
      this.request<ListRolesResponseDto, ApiError>({
        path: `/role/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags role
     * @name Add
     * @request POST:/role
     * @secure
     */
    add: (data: RoleDto, params: RequestParams = {}) =>
      this.request<RoleDto, ApiError>({
        path: `/role`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags role
     * @name Update
     * @request PUT:/role/{id}
     * @secure
     */
    update: (id: string, data: RoleDto, params: RequestParams = {}) =>
      this.request<RoleDto, ApiError>({
        path: `/role/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags role
     * @name DeleteRole
     * @request DELETE:/role/role
     * @secure
     */
    deleteRole: (data: DeleteRoleRequestDto, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/role/role`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  orgUsers = {
    /**
     * No description
     *
     * @tags org-users
     * @name Search
     * @request POST:/org-users/search
     * @secure
     */
    search: (data: OrgUsersListRequest, params: RequestParams = {}) =>
      this.request<OrgUsersListResponse, ApiError>({
        path: `/org-users/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name MailExists
     * @request GET:/org-users/{clientCode}/mail-exists
     * @secure
     */
    mailExists: (
      clientCode: string,
      query: {
        mail: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ApiError>({
        path: `/org-users/${clientCode}/mail-exists`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name GetClientContext
     * @request GET:/org-users/{clientCode}/context
     * @secure
     */
    getClientContext: (clientCode: string, params: RequestParams = {}) =>
      this.request<SharedClientContext, ApiError>({
        path: `/org-users/${clientCode}/context`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name GetPermissionsOfRole
     * @request GET:/org-users/permissions/{clientCode}/{roleId}
     * @secure
     */
    getPermissionsOfRole: (clientCode: string, roleId: string, params: RequestParams = {}) =>
      this.request<PermissionsOfRoleResponse, ApiError>({
        path: `/org-users/permissions/${clientCode}/${roleId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name GetUser
     * @request GET:/org-users/{clientCode}/{mail}
     * @secure
     */
    getUser: (clientCode: string, mail: string, params: RequestParams = {}) =>
      this.request<OrgUserResponse, ApiError>({
        path: `/org-users/${clientCode}/${mail}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name GetUserWithType
     * @request GET:/org-users/{clientCode}/user-with-type/{mail}
     * @secure
     */
    getUserWithType: (clientCode: string, mail: string, params: RequestParams = {}) =>
      this.request<OrgUserWithTypeResponse, ApiError>({
        path: `/org-users/${clientCode}/user-with-type/${mail}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name CreateUser
     * @request POST:/org-users/user
     * @secure
     */
    createUser: (data: CreateOrgUserDto, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/org-users/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name DeleteUser
     * @request DELETE:/org-users/user
     * @secure
     */
    deleteUser: (data: DeleteOrgUsersDto, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/org-users/user`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name AddClientToUser
     * @request POST:/org-users/user/client
     * @secure
     */
    addClientToUser: (data: CreateOrgUserDto, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/org-users/user/client`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name UpdateUserAccount
     * @request PUT:/org-users/user/account
     * @secure
     */
    updateUserAccount: (data: UpdateOrgUserAccountDto, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/org-users/user/account`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name AddUserRoles
     * @request POST:/org-users/user/role
     * @secure
     */
    addUserRoles: (data: AddOrgUserRolesDto, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/org-users/user/role`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name UpdateUserRole
     * @request PUT:/org-users/user/role
     * @secure
     */
    updateUserRole: (data: UpdateOrgUserRoleDto, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/org-users/user/role`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name DeleteUserRole
     * @request DELETE:/org-users/user/role
     * @secure
     */
    deleteUserRole: (data: DeleteOrgUserRoleDto, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/org-users/user/role`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-users
     * @name CheckIfOnlyRole
     * @request POST:/org-users/user/role/isOnly
     * @secure
     */
    checkIfOnlyRole: (data: DeleteOrgUserRoleDto, params: RequestParams = {}) =>
      this.request<boolean, ApiError>({
        path: `/org-users/user/role/isOnly`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  orgStructure = {
    /**
     * No description
     *
     * @tags org-structure
     * @name GetStructure
     * @request GET:/org-structure/{clientCode}
     * @secure
     */
    getStructure: (clientCode: string, params: RequestParams = {}) =>
      this.request<OrgStructureResponse, ApiError>({
        path: `/org-structure/${clientCode}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-structure
     * @name GetNodes
     * @request GET:/org-structure/{clientCode}/node
     * @secure
     */
    getNodes: (clientCode: string, params: RequestParams = {}) =>
      this.request<OrganisationViewDto, ApiError>({
        path: `/org-structure/${clientCode}/node`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-structure
     * @name GetClientStructure
     * @request GET:/org-structure/{clientCode}/client-structure
     * @secure
     */
    getClientStructure: (clientCode: string, params: RequestParams = {}) =>
      this.request<ClientStructureDto, ApiError>({
        path: `/org-structure/${clientCode}/client-structure`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-structure
     * @name CpmsIdExists
     * @request GET:/org-structure/{clientCode}/cpms-id-exists
     * @secure
     */
    cpmsIdExists: (
      clientCode: string,
      query: {
        cpmsId: string;
        nodeCodeToExclude: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ApiError>({
        path: `/org-structure/${clientCode}/cpms-id-exists`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-structure
     * @name GetNodeUsersCount
     * @request GET:/org-structure/{clientCode}/{nodeCode}/users
     * @secure
     */
    getNodeUsersCount: (clientCode: string, nodeCode: string, params: RequestParams = {}) =>
      this.request<AssignedUsersDTO, ApiError>({
        path: `/org-structure/${clientCode}/${nodeCode}/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-structure
     * @name CreateNode
     * @request POST:/org-structure/node
     * @secure
     */
    createNode: (data: CreateNodeDTO, params: RequestParams = {}) =>
      this.request<ClientStructureDto, ApiError>({
        path: `/org-structure/node`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-structure
     * @name UpdateNode
     * @request PUT:/org-structure/node
     * @secure
     */
    updateNode: (data: UpdateNodeDTO, params: RequestParams = {}) =>
      this.request<ClientStructureDto, ApiError>({
        path: `/org-structure/node`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-structure
     * @name DeleteNode
     * @request DELETE:/org-structure/node
     * @secure
     */
    deleteNode: (data: DeleteNodeDTO, params: RequestParams = {}) =>
      this.request<ClientStructureDto, ApiError>({
        path: `/org-structure/node`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  orgStations = {
    /**
     * No description
     *
     * @tags org-stations
     * @name List
     * @request POST:/org-stations/list
     * @secure
     */
    list: (data: OrgStationsListRequest, params: RequestParams = {}) =>
      this.request<OrgStationListResultDto, ApiError>({
        path: `/org-stations/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-stations
     * @name AssignStationsToNode
     * @request POST:/org-stations/assign-stations-to-node
     * @secure
     */
    assignStationsToNode: (data: OrgStationsAssignStationToClientDto, params: RequestParams = {}) =>
      this.request<OrganisationViewDto, ApiError>({
        path: `/org-stations/assign-stations-to-node`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags org-stations
     * @name UnassignStationsFromNode
     * @request POST:/org-stations/unassign-stations-from-node
     * @secure
     */
    unassignStationsFromNode: (data: OrgStationsUnassignStationsFromNodeDto, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/org-stations/unassign-stations-from-node`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  statusLog = {
    /**
     * No description
     *
     * @tags status-log
     * @name GetStatusLogList
     * @request POST:/status-log/search
     * @secure
     */
    getStatusLogList: (data: StatusLogRequest, params: RequestParams = {}) =>
      this.request<StatusLogResult, ApiError>({
        path: `/status-log/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags status-log
     * @name SearchFilters
     * @request GET:/status-log/search-filters
     * @secure
     */
    searchFilters: (
      query: {
        q: string;
        clientCode: string;
        hierarchyNodeCodes: string[];
      },
      params: RequestParams = {}
    ) =>
      this.request<StatusLogEntityFilterDto[], ApiError>({
        path: `/status-log/search-filters`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  affiliate = {
    /**
     * No description
     *
     * @tags affiliate
     * @name Search
     * @request POST:/affiliate/search
     * @secure
     */
    search: (data: AffiliateListRequest, params: RequestParams = {}) =>
      this.request<AffiliateListResponse, ApiError>({
        path: `/affiliate/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags affiliate
     * @name Create
     * @request POST:/affiliate
     * @secure
     */
    create: (data: AffiliateDto, params: RequestParams = {}) =>
      this.request<AffiliateDto, ApiError>({
        path: `/affiliate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags affiliate
     * @name Update
     * @request PUT:/affiliate
     * @secure
     */
    update: (data: AffiliateDto, params: RequestParams = {}) =>
      this.request<AffiliateDto, ApiError>({
        path: `/affiliate`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags affiliate
     * @name Delete
     * @request DELETE:/affiliate/{code}
     * @secure
     */
    delete: (code: string, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/affiliate/${code}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags affiliate
     * @name Get
     * @request GET:/affiliate/{code}
     * @secure
     */
    get: (code: string, params: RequestParams = {}) =>
      this.request<AffiliateDto, ApiError>({
        path: `/affiliate/${code}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags affiliate
     * @name CodeExists
     * @request GET:/affiliate/code-exists
     * @secure
     */
    codeExists: (
      query: {
        code: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ApiError>({
        path: `/affiliate/code-exists`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags affiliate
     * @name TitleExists
     * @request GET:/affiliate/title-exists
     * @secure
     */
    titleExists: (
      query: {
        title: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ApiError>({
        path: `/affiliate/title-exists`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags affiliate
     * @name CpmsExists
     * @request GET:/affiliate/cpms-exists
     * @secure
     */
    cpmsExists: (
      query: {
        cpmsId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ApiError>({
        path: `/affiliate/cpms-exists`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags affiliate
     * @name CpmsExistsInEvp
     * @request GET:/affiliate/cpms-exists-in-evp
     * @secure
     */
    cpmsExistsInEvp: (
      query: {
        cpmsId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ApiError>({
        path: `/affiliate/cpms-exists-in-evp`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  systemAdmins = {
    /**
     * No description
     *
     * @tags system-admins
     * @name Search
     * @request POST:/system-admins/search
     * @secure
     */
    search: (data: AdminsListRequest, params: RequestParams = {}) =>
      this.request<AdminsListResponse, ApiError>({
        path: `/system-admins/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags system-admins
     * @name MailExists
     * @request GET:/system-admins/mail-exists
     * @secure
     */
    mailExists: (
      query: {
        mail: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, ApiError>({
        path: `/system-admins/mail-exists`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags system-admins
     * @name GetUser
     * @request GET:/system-admins/{mail}
     * @secure
     */
    getUser: (mail: string, params: RequestParams = {}) =>
      this.request<AdminDto, ApiError>({
        path: `/system-admins/${mail}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags system-admins
     * @name GetUserWithType
     * @request GET:/system-admins/user-with-type/{mail}
     * @secure
     */
    getUserWithType: (mail: string, params: RequestParams = {}) =>
      this.request<AdminWithTypeDto, ApiError>({
        path: `/system-admins/user-with-type/${mail}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags system-admins
     * @name AddUser
     * @request POST:/system-admins
     * @secure
     */
    addUser: (data: AdminDto, params: RequestParams = {}) =>
      this.request<AdminDto, ApiError>({
        path: `/system-admins`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags system-admins
     * @name UpdateUser
     * @request PUT:/system-admins
     * @secure
     */
    updateUser: (data: AdminDto, params: RequestParams = {}) =>
      this.request<AdminDto, ApiError>({
        path: `/system-admins`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags system-admins
     * @name DeleteUser
     * @request DELETE:/system-admins
     * @secure
     */
    deleteUser: (data: AdminDeleteRequest, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/system-admins`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags system-admins
     * @name MakeToAdmin
     * @request PUT:/system-admins/make-to-admin/{mail}
     * @secure
     */
    makeToAdmin: (mail: string, params: RequestParams = {}) =>
      this.request<void, ApiError>({
        path: `/system-admins/make-to-admin/${mail}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),
  };
  evMigration = {
    /**
     * No description
     *
     * @tags ev-migration
     * @name SearchJobs
     * @request POST:/ev-migration/search
     * @secure
     */
    searchJobs: (data: ListEvMigrationJobArgsDto, params: RequestParams = {}) =>
      this.request<ListEvMigrationJobResultDto, ApiError>({
        path: `/ev-migration/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ev-migration
     * @name SearchStation
     * @request GET:/ev-migration/search-stations
     * @secure
     */
    searchStation: (
      query: {
        q: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string[], ApiError>({
        path: `/ev-migration/search-stations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ev-migration
     * @name GetJob
     * @request GET:/ev-migration/{jobId}
     * @secure
     */
    getJob: (jobId: string, params: RequestParams = {}) =>
      this.request<EvMigrationJobDto, ApiError>({
        path: `/ev-migration/${jobId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ev-migration
     * @name CreateJob
     * @request POST:/ev-migration
     * @secure
     */
    createJob: (data: EvMigrationJobRequestDto, params: RequestParams = {}) =>
      this.request<EvMigrationJobDto, ApiError>({
        path: `/ev-migration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  operationsStations = {
    /**
     * No description
     *
     * @tags operations-stations
     * @name SendOcppMessage
     * @request POST:/operations-stations/ocpp-message/send/{chargeBoxId}
     * @secure
     */
    sendOcppMessage: (chargeBoxId: string, data: OcppMessageRaw, params: RequestParams = {}) =>
      this.request<OcppMessageResponse, ApiError>({
        path: `/operations-stations/ocpp-message/send/${chargeBoxId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  interactionsLogs = {
    /**
     * No description
     *
     * @tags interactions-logs
     * @name GetInteractionsLogs
     * @request POST:/interactions-logs/search
     * @secure
     */
    getInteractionsLogs: (data: SearchInteractionsLogsDto, params: RequestParams = {}) =>
      this.request<InteractionLogsSearchResponse, ApiError>({
        path: `/interactions-logs/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}

import useBetterTranslate from '../../../utils/translation-utils';
import { SimpleSuggestion } from '../../tag-suggestion/tag-suggestion';
import Box from '../../utils';

export function StationSuggestion(props: { label: string; subLabel?: string; testid?: string }) {
  const { _t } = useBetterTranslate('suggestions');

  return (
    <Box kind={'vflex'} gap='xxs' testId={props.testid || 'suggestion'}>
      <Box fw='700'>{props.label}</Box>
      <Box fg='secondary-teal'>{_t('Station')}</Box>
    </Box>
  );
}

export function ChargeBoxSuggestion(props: { label: string; subLabel?: string; testid?: string }) {
  const { _t } = useBetterTranslate('suggestions');

  return (
    <Box kind={'vflex'} gap='xxs' testId={props.testid || 'suggestion'}>
      <Box fw='700'>{props.label}</Box>
      <Box fg='blue-blue-jeans'>{_t('Charge Point')}</Box>
    </Box>
  );
}

export function CardSuggestion(props: { label: string; subLabel?: string; testid?: string }) {
  const { _t } = useBetterTranslate('suggestions');

  return (
    <Box kind={'vflex'} gap='xxs' testId={props.testid || 'suggestion'}>
      <Box fw='700'>{props.label}</Box>
      <Box fg='red-light-salmon'>{_t('Badge')}</Box>
    </Box>
  );
}

export function CostCenterSuggestion(props: { label: string; subLabel?: string; testid?: string }) {
  const { _t } = useBetterTranslate('suggestions');

  return (
    <Box kind={'vflex'} gap='xxs' testId={props.testid || 'suggestion'}>
      <Box fw='700'>{props.label}</Box>
      <Box fg='blue-blue-jeans'>{_t('Cost Center')}</Box>
    </Box>
  );
}

export function SmartSuggest(props: { label: string; subLabel?: string; type: string; testid?: string }) {
  if (props.type === 'station') return <StationSuggestion {...props} />;
  else if (props.type === 'chargepoint') return <ChargeBoxSuggestion {...props} />;
  else if (props.type === 'card') return <CardSuggestion {...props} />;
  else if (props.type === 'cardLbl') return <CardSuggestion {...props} />;
  else if (props.type === 'cardCR') return <CardSuggestion {...props} />;
  else if (props.type === 'cardCMT') return <CostCenterSuggestion {...props} />;
  else return <SimpleSuggestion {...props} subLabel={props.type} />;
}

import * as _ from 'lodash';
import { ReactNode, useCallback } from 'react';
import { rateTypeTooltip } from '../../../components/tag';
import { RateServiceType, TimeGroupResolution } from '../../../services/api-client/csp-api';
import { useAuth } from '../../../utils/AuthProvider';
import { formateByResolution } from '../../../utils/date';
import { formatKWhEnergy, formatPercentage } from '../../../utils/format';
import { formatter } from '../../../utils/localized-types';
import useBetterTranslate from '../../../utils/translation-utils';
import ChartLegendTable, { ChartLegendCustomItem, ChartLegendItem } from '../../charg-legend-table/chart-legend-table';
import Pill from '../../pill';
import { ProgressBar, ProgressBarStack } from '../../progress';
import StackedBarChart, { StackedBarChartSeries, StackedBarChartTooltipData } from '../../stacked-bar-chart/stacked-bar-chart';
import Box, { Divider } from '../../utils';
import { rateTypeColor, rateTypeTranslation } from '../domain-mappings';
import styles from './common-charts.module.scss';

export interface EnergyStackBarChartProps {
  work?: number;
  employee?: number;
  public?: number;
  unknown?: number;
  home?: number;
}

export function EnergyStackBarChart(props: EnergyStackBarChartProps) {
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode;

  return (
    <ProgressBarStack className={styles.totalSumBar}>
      {props.work !== undefined && (
        <ProgressBar value={props.work} apperance='dataviz-green-light-green'>
          {formatPercentage(props.work, languageCode, 0, 0)}
        </ProgressBar>
      )}
      {props.employee !== undefined && (
        <ProgressBar value={props.employee} apperance='secondary-teal'>
          {formatPercentage(props.employee, languageCode, 0, 0)}
        </ProgressBar>
      )}
      {props.home !== undefined && (
        <ProgressBar value={props.home} apperance='dataviz-blue-pale-cyan'>
          {formatPercentage(props.home, languageCode, 0, 0)}
        </ProgressBar>
      )}
      {props.public !== undefined && (
        <ProgressBar value={props.public} apperance='dataviz-blue-medium-persian'>
          {formatPercentage(props.public, languageCode, 0, 0)}
        </ProgressBar>
      )}
      {props.unknown !== undefined && (
        <ProgressBar value={props.unknown} apperance='functional-disabled-200'>
          {formatPercentage(props.unknown, languageCode, 0, 0)}
        </ProgressBar>
      )}
    </ProgressBarStack>
  );
}

export interface EnergyHistoryStackBarChartProps {
  resolution: TimeGroupResolution;
  xAxisLbl?: string;
  work?: { y: number; xKey: string }[];
  employee?: { y: number; xKey: string }[];
  public?: { y: number; xKey: string }[];
  unknown?: { y: number; xKey: string }[];
  home?: { y: number; xKey: string }[];
}
export function EnergyHistoryStackBarChart(props: EnergyHistoryStackBarChartProps) {
  const { _t } = useBetterTranslate('common-charts');
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode;
  const resolution = props.resolution;

  const items: StackedBarChartSeries[] = [];
  if (props.work) {
    items.push({ name: RateServiceType.WORK, apperance: 'dataviz-green-light-green', data: props.work });
  }
  if (props.employee) {
    items.push({ name: RateServiceType.EMPLOYEE, apperance: 'secondary-teal', data: props.employee });
  }
  if (props.home) {
    items.push({ name: RateServiceType.HOME, apperance: 'dataviz-blue-pale-cyan', data: props.home });
  }
  if (props.public) {
    items.push({ name: RateServiceType.PUBLIC, apperance: 'dataviz-blue-medium-persian', data: props.public });
  }
  if (props.unknown) {
    items.push({ name: RateServiceType.UNDEFINED, apperance: 'functional-disabled-200', data: props.unknown });
  }

  const totalTxt = _t('Gesamt');
  const createTooltip = useCallback(
    (arg: StackedBarChartTooltipData) => {
      const points = arg.points.map((p, idx) => (
        <Box kind='hflex' key={idx} gap='s' align='center'>
          <Pill apperance={rateTypeColor(p.name as RateServiceType) as any} />
          <Box flexGrow='1'>{rateTypeTranslation(p.name as RateServiceType)}</Box>
          <Box>{formatKWhEnergy(Math.round(p.value), languageCode)}</Box>
        </Box>
      ));

      const total = _.sumBy(arg.points, (p) => p.value);
      return (
        <Box kind='vflex' gap='s'>
          <Box kind='hflex' gap='s' align='center'>
            <Pill apperance={'atenso-blue'} />
            <Box flexGrow='1'>{totalTxt}</Box>
            <Box>{formatKWhEnergy(Math.round(total), languageCode)}</Box>
          </Box>
          {points}
          <Box>{formateByResolution(arg.xKey, languageCode || 'en', resolution)}</Box>
        </Box>
      );
    },
    [languageCode, resolution, totalTxt]
  );

  return (
    <Box kind='vflex' flexGrow='1' gap='xs'>
      <StackedBarChart items={items} formatYLbl={(val) => formatKWhEnergy(Math.round(val / 1000), languageCode)} noXlbls={true} onTooltip={createTooltip} />
      {props.xAxisLbl && (
        <Box kind='hflex' justify='flex-end' fs={'xs'} className={styles.barChartXLabel}>
          {props.xAxisLbl}
        </Box>
      )}
    </Box>
  );
}

export interface EnergyTotalsListProps {
  customNodeTop?: ReactNode;
  total?: { value: number; onClick?: () => void };
  work?: { percentage: number; value: number; onClick?: () => void };
  employee?: { percentage: number; value: number; onClick?: () => void };
  home?: { percentage: number; value: number; onClick?: () => void };
  public?: { percentage: number; value: number; onClick?: () => void };
  unknown?: { percentage: number; value: number; onClick?: () => void };
}
export function EnergyTotalsList(props: EnergyTotalsListProps) {
  const { _t } = useBetterTranslate('common-charts');
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode;
  const items: (ChartLegendItem | ChartLegendCustomItem)[] = [];

  if (props.customNodeTop) {
    items.push({ node: props.customNodeTop });
  }
  if (props.total) {
    items.push({
      title: _t('Total'),
      dataCy: 'total',
      value: `${formatKWhEnergy(props.total.value, languageCode)}`,
      pillApperance: 'atenso-blue',
      className: styles.gesamt,
      onClick: props.total.onClick,
    });
    items.push({ node: <Divider kind='h' /> });
  }

  if (props.work) {
    items.push({
      title: _t('Work'),
      dataCy: 'work',
      value: `${formatKWhEnergy(props.work.value, languageCode, 0, 0)} (${formatPercentage(props.work.percentage, languageCode, 0, 0)})`,
      pillApperance: rateTypeColor(RateServiceType.WORK),
      onClick: props.work?.onClick,
      tooltip: rateTypeTooltip(RateServiceType.WORK),
    });
  }
  if (props.employee) {
    items.push({
      title: _t('Employee'),
      dataCy: 'employee',
      value: `${formatKWhEnergy(props.employee.value, languageCode)} (${formatPercentage(props.employee.percentage, languageCode, 0, 0)})`,
      pillApperance: rateTypeColor(RateServiceType.EMPLOYEE),
      onClick: props.employee.onClick,
      tooltip: rateTypeTooltip(RateServiceType.EMPLOYEE),
    });
  }

  if (props.home) {
    items.push({
      title: _t('Home'),
      dataCy: 'home',
      value: `${formatKWhEnergy(props.home.value, languageCode)} (${formatPercentage(props.home.percentage, languageCode, 0, 0)})`,
      pillApperance: rateTypeColor(RateServiceType.HOME),
      onClick: props.home.onClick,
      tooltip: rateTypeTooltip(RateServiceType.HOME),
    });
  }

  if (props.public) {
    items.push({
      title: _t('Public'),
      dataCy: 'public',
      value: `${formatKWhEnergy(props.public.value, languageCode)} (${formatPercentage(props.public.percentage, languageCode, 0, 0)})`,
      pillApperance: rateTypeColor(RateServiceType.PUBLIC),
      onClick: props.public.onClick,
      tooltip: rateTypeTooltip(RateServiceType.PUBLIC),
    });
  }
  if (props.unknown) {
    items.push({
      title: _t('Unknown'),
      dataCy: 'unbekannt',
      value: `${formatKWhEnergy(props.unknown.value, languageCode)} (${formatPercentage(props.unknown.percentage, languageCode, 0, 0)})`,
      pillApperance: rateTypeColor(RateServiceType.UNDEFINED),
      onClick: props.unknown.onClick,
      tooltip: rateTypeTooltip(RateServiceType.UNDEFINED),
    });
  }

  return <ChartLegendTable headline={<>{_t('Charged Energy')}</>} items={items} />;
}

export interface SessionsTotalsListProps {
  customNodeTop?: ReactNode;
  total?: { value: number; onClick?: () => void };
  work?: { value: number; onClick?: () => void };
  employee?: { value: number; onClick?: () => void };
  home?: { value: number; onClick?: () => void };
  public?: { value: number; onClick?: () => void };
  unknown?: { value: number; onClick?: () => void };
}
export function SessionsTotalsList(props: SessionsTotalsListProps) {
  const { _t } = useBetterTranslate('common-charts');
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode;
  const items: (ChartLegendItem | ChartLegendCustomItem)[] = [];
  if (props.customNodeTop) {
    items.push({ node: props.customNodeTop });
  }
  if (props.total) {
    items.push({
      title: _t('Total'),
      dataCy: 'total',
      // dataCyValue: 'total',
      value: `${formatter.formatNumber(props.total.value, languageCode)}`,
      pillApperance: 'atenso-blue',
      className: styles.gesamt,
      onClick: props.total.onClick,
    });
    items.push({ node: <Divider kind='h' /> });
  }

  if (props.work) {
    items.push({
      title: _t('Work'),
      dataCy: 'work',
      // dataCyValue: 'work',
      value: `${formatter.formatNumber(props.work.value, languageCode)}`,
      pillApperance: rateTypeColor(RateServiceType.WORK),
      onClick: props.work?.onClick,
      tooltip: rateTypeTooltip(RateServiceType.WORK),
    });
  }
  if (props.employee) {
    items.push({
      title: _t('Employee'),
      dataCy: 'employee',
      // dataCyValue: 'employee',
      value: `${formatter.formatNumber(props.employee.value, languageCode)}`,
      pillApperance: rateTypeColor(RateServiceType.EMPLOYEE),
      onClick: props.employee.onClick,
      tooltip: rateTypeTooltip(RateServiceType.EMPLOYEE),
    });
  }
  if (props.home) {
    items.push({
      title: _t('Home'),
      dataCy: 'home',
      // dataCyValue: 'home',
      value: `${formatter.formatNumber(props.home.value, languageCode)}`,
      pillApperance: rateTypeColor(RateServiceType.HOME),
      onClick: props.home.onClick,
      tooltip: rateTypeTooltip(RateServiceType.HOME),
    });
  }

  if (props.public) {
    items.push({
      title: _t('Public'),
      dataCy: 'public',
      // dataCyValue: 'public',
      value: `${formatter.formatNumber(props.public.value, languageCode)}`,
      pillApperance: rateTypeColor(RateServiceType.PUBLIC),
      onClick: props.public.onClick,
      tooltip: rateTypeTooltip(RateServiceType.PUBLIC),
    });
  }
  if (props.unknown) {
    items.push({
      title: _t('Unbekannt'),
      dataCy: 'unknown',
      // dataCyValue: 'unknown',
      value: `${formatter.formatNumber(props.unknown.value, languageCode)}`,
      pillApperance: rateTypeColor(RateServiceType.UNDEFINED),
      onClick: props.unknown.onClick,
      tooltip: rateTypeTooltip(RateServiceType.UNDEFINED),
    });
  }

  return <ChartLegendTable headline={<>{_t('Charging Sessions')}</>} items={items} />;
}
